import { serviceSlice } from "../../app/serviceSlice";
import { dashboardService } from "./nebula.port";

export const extendedApiSlice = serviceSlice.injectEndpoints({
    endpoints: (builder) => ({
        getEnrolledPatients: builder.query<{ value: number; goal: number }, string>({
            query: (trialId) => () => dashboardService.getEnrolledPatients(trialId),
        }),
        getEnrolledSites: builder.query<{ value: number; goal: number }, string>({
            query: (trialId) => () => dashboardService.getEnrolledSites(trialId),
        }),
        getEnrolledPatientsVisits: builder.query<{ value: number; goal: number }, string>({
            query: (trialId) => () => dashboardService.getEnrolledPatientsVisits(trialId),
        }),
    }),
});

export const { useGetEnrolledPatientsQuery, useGetEnrolledSitesQuery, useGetEnrolledPatientsVisitsQuery } =
    extendedApiSlice;
