import { IMedia, IMediaOctFile } from "../features/studies/studiesSlice";
import { API_MEDIA_BASE_PATH } from "../lib/nebulaClient";

const hideSources = false;

export const mimeTypeToExtension: { [key: string]: string } = {
    "image/gif": "gif",
    "image/png": "png",
    "image/jpeg": "jpg",
    "image/tiff": "tif",
    "video/mp4": "mp4",
    "application/vnd.voxeleron-caxd": "caxd",
    "text/csv": "csv",
    "application/dicom": "dcm",
    "data/oct-image": "docMedia",
    "application/vnd.voxeleron-oct-zeiss": "ex.dcm",
    "application/vnd.voxeleron-oct-heidelberg": "e2e",
    "application/vnd.voxeleron-oct-topcon": "fda",
    "application/vnd.voxeleron-ixd": "ixd",
    "application/vnd.voxeleron-jxd": "jxd",
    "application/json": "json",
    "application/vnd.voxeleron-oct-generic": "lst",
    "application/vnd.voxeleron-oct-optoview": "foct",
    "text/plain": "txt",
    "application/msword": "doc",
    "application/mspowerpoint": "ppt",
    "application/pdf": "pdf",
    "application/msexcel": "xls",
    "application/xml": "xml",
    "application/vnd.voxeleron-oct-maybe-optoview": "",
    "application/octet-stream": "?",
};

export const regroupImages = (images: IMediaOctFile[]) => {
    const result: IMediaOctFile[] = [];
    // pick up the OCTs first
    images.forEach((i) => {
        if (i.Device !== "browser") result.push(i);
    });
    images.forEach((i) => {
        if (i.Device === "browser") result.push(i);
    });
    return result;
};

export const figureComponentOld = (mediaItem: IMediaOctFile) => {
    switch (figureFlavor(mediaItem)) {
        case "attachment":
        case "csv":
            return "attachment";
        case "photo":
        case "oct-photo":
            return "img";
        case "mp4":
        case "oct-mp4":
            return "video";
        case "pdf":
            return "iframe";
        default:
            return null;
    }
};

export const figureComponent = (mediaItem: IMedia) => {
    switch (figureFlavor(mediaItem)) {
        case "attachment":
        case "csv":
            return "attachment";
        case "photo":
        case "oct-photo":
            return "img";
        case "mp4":
        case "oct-mp4":
            return "video";
        case "pdf":
            return "iframe";
        default:
            return null;
    }
};

export const figureSource = (mediaItem: any, token: string | undefined) => {
    switch (figureFlavor(mediaItem)) {
        case "attachment":
            return "/assets/images/Attachment.png";
        case "csv":
            return "/assets/images/csv.png";
        case "mp4":
        case "oct-mp4":
            return `${API_MEDIA_BASE_PATH}/t/${token}/media/stream/mp4/${mediaItem.uuid}`;
        case "photo":
        case "oct-photo":
            return `${API_MEDIA_BASE_PATH}/t/${token}/media/stream/jpeg/${mediaItem.uuid}`;
        case "pdf":
            return `${API_MEDIA_BASE_PATH}/t/${token}/media/stream/pdf/${mediaItem.uuid}`;
        default:
            return null;
    }
};

export const figureFlavorOld = (mediaItem?: IMediaOctFile) => {
    if (!mediaItem) return;
    let flavor: Flavors;
    switch (mediaItem.mimeType.label) {
        case "application/vnd.ms-excel":
            flavor = "csv";
            break;
        case "application/csv":
        case "text/csv":
            flavor = "csv";
            break;
        case "application/pdf":
            flavor = "pdf";
            break;
        case "text/plain":
            flavor = "text";
            break;
        case "image/jpeg":
        case "image/jpg":
        case "image/png":
        case "image/tiff":
        case "image/tif":
            flavor = "photo";
            break;
        case "data/oct-image":
        case "application/vnd.voxeleron-oct-heidelberg":
        case "application/vnd.voxeleron-oct-zeiss":
        case "application/vnd.voxeleron-oct-topcon":
        case "application/vnd.voxeleron-oct-generic":
        case "application/vnd.voxeleron-oct-optoview":
        case "application/vnd.voxeleron-oct-maybe-optoview":
        case "application/octet-stream":
            const thumbFile = mediaItem.ThumbNailFileName ?? "";
            flavor = thumbFile.indexOf(".mp4") > 0 ? "oct-mp4" : "oct-photo";
            break;
        case "video/mp4":
            flavor = "mp4";
            break;
        default:
            flavor = "attachment";
            const mimeLc = mediaItem.mimeType.label.toLowerCase();
            const hasIt = sourceList.find((x) => mimeLc.indexOf(x) >= 0);
            if (hasIt && hideSources) {
                flavor = "hide";
            }
            break;
    }
    return flavor;
};

export const figureFlavor = (mediaItem?: any) => {
    if (!mediaItem) return;
    let flavor: Flavors;

    switch (mediaItem.mimeType?.label || mediaItem.mimeType) {
        // @ts-ignore
        case "application/vnd.ms-excel":
            flavor = "csv";
            break;
        // @ts-ignore
        case "application/csv":
        // @ts-ignore
        case "text/csv":
            flavor = "csv";
            break;
        // @ts-ignore
        case "application/pdf":
            flavor = "pdf";
            break;
        // @ts-ignore
        case "text/plain":
            flavor = "text";
            break;
        // @ts-ignore
        case "image/jpeg":
        // @ts-ignore
        case "image/jpg":
        // @ts-ignore
        case "image/png":
        // @ts-ignore
        case "image/tiff":
        // @ts-ignore
        case "image/tif":
            flavor = "photo";
            break;
        // @ts-ignore
        case "data/oct-image":
        // @ts-ignore
        case "application/vnd.voxeleron-oct-heidelberg":
        // @ts-ignore
        case "application/vnd.voxeleron-oct-zeiss":
        // @ts-ignore
        case "application/vnd.voxeleron-oct-topcon":
        // @ts-ignore
        case "application/vnd.voxeleron-oct-generic":
        // @ts-ignore
        case "application/vnd.voxeleron-oct-optoview":
        // @ts-ignore
        case "application/vnd.voxeleron-oct-maybe-optoview":
        // @ts-ignore
        case "application/octet-stream":
            const thumbFile = (mediaItem.mediaUrl || mediaItem.ThumbNailFileName) ?? "";
            flavor = thumbFile.indexOf(".mp4") > 0 ? "oct-mp4" : "oct-photo";
            break;
        case "video/mp4":
            flavor = "mp4";
            break;
        default:
            flavor = "attachment";
            const mimeLc = mediaItem.mimeType.toLowerCase();
            const hasIt = sourceList.find((x) => mimeLc.indexOf(x) >= 0);
            if (hasIt && hideSources) {
                flavor = "hide";
            }
            break;
    }
    return flavor;
};

export const figureCardTitle = (mediaItem?: IMedia) => {
    if (!mediaItem) return;
    let cardTitle = "";
    switch (figureFlavor(mediaItem)) {
        case "hide":
            return null; // show nothing
        case "mp4":
            cardTitle = "Video";
            break;
        case "oct-photo":
        case "oct-mp4":
            cardTitle = `OCT - ${mediaItem.dicomData.numberOfFrames} Slices`;
            break;
        case "pdf":
            cardTitle = "PDF";
            break;
        case "photo":
            cardTitle = "PHOTO";
            break;
        case "csv":
            cardTitle = "REPORT";
            break;
        default:
            cardTitle = "Attachment: " + simplifyMime(mediaItem.mimeType);
    }
    return cardTitle;
};

export const simplifyMime = (mime: string): string => {
    let typeMaps: { [mime: string]: string } = {
        "application/json": "JSON",
        "application/msword": "MS Word",
        "application/text": "Plain Text",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document": "MS Word",
        "application/vnd.ms-excel": "MS Excel",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": "MS Excel",
        "application/vnd.ms-powerpoint": "MS PowerPoint",
        "application/vnd.openxmlformats-officedocument.presentationml.presentation": "MS PowerPoint",
    };
    if (typeMaps[mime]) return typeMaps[mime];
    else return mime;
};

export type Flavors =
    | "attachment"
    | "csv"
    | "hide"
    | "mp4"
    | "oct-mp4"
    | "oct-photo"
    | "photo"
    | "pdf"
    | "file"
    | "oct"
    | "text";

export const getMimeTypePrettyName = (mimeType: string): string => {
    const namesMap: Record<string, string> = {
        "image/gif": "GIF Image",
        "image/png": "PNG Image",
        "image/jpeg": "JPG Image",
        "image/tiff": "TIFF image",
        "video/mp4": "MP4 Video",
        "application/vnd.voxeleron-caxd": "Change Analysis File",
        "text/csv": "CSV File",
        "application/dicom": "DCM Image",
        "data/oct-image": "OCT Image File",
        "application/vnd.voxeleron-oct-zeiss": "OCT Image File",
        "application/vnd.voxeleron-oct-heidelberg": "OCT Image File",
        "application/vnd.voxeleron-oct-topcon": "OCT Image File",
        "application/vnd.voxeleron-ixd": "OCT Analysis File",
        "application/vnd.voxeleron-jxd": "2D Analysis File",
        "application/json": "JSON FILE",
        "application/vnd.voxeleron-oct-generic": "OCT image file",
        "text/plain": "TXT File",
        "application/msword": "Word Document",
        "application/mspowerpoint": "PowerPoint",
        "application/pdf": "PDF File",
        "application/msexcel": "Excel Spreadsheet",
        "application/xml": "XML File",
        "application/vnd.voxeleron-oct-maybe-optoview": "OCT Image File",
        "application/octet-stream": "OCT Image File",
    };

    return namesMap[mimeType] ?? mimeType;
};

export const getMediaLabel = (media: IMedia): string => {
    if (media.dicomData.performedProcedureStepDescription) return media.dicomData.performedProcedureStepDescription;

    return getMimeTypePrettyName(media.mimeType);
};

const sourceList = ["json", "dcm", "dicom", "e2e", "fda", "oct"];
