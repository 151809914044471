import { nebulaClient, nebulaClient2, nebulaUploadClient } from "../../lib/nebulaClient";
import { UploadService } from "./nebula.service";
import { IField, SessionUuid } from "./uploadSlice";

export interface DerivedFileDTO {
    fileName: string;
    patientId: string;
    laterality: string;
    encounterDate: string;
    mediaUuid: string;
    comment?: string;
    mimeType: string;
    category: string;
    sessionUuid: string;
    status: string;
    isDeleted: boolean;
    statusCode: number;
}

export interface GroupDTO {
    depth: number;
    isActive: boolean;
    label: string;
    name: string;
    parent: string;
    selected: boolean;
    kind: string;
    selectedInherited: boolean;
    uuid: string;
}

export interface TrialDTO {
    uuid: string;
    name: string;
    author: string;
    createdOn: string;
    updatedOn: string;
    workflowActive: boolean;
    showTags: boolean;
}

export type VisitIdentifierDTO = Record<string, string>;

export interface FileExtensionDTO {
    label: string;
    prettyLabel: string;
}

export interface UploadConfigDTO {
    folderConventionRegEx: string[];
    validExampleFolders: string[];
    validExtensions: string[];
    uploadSizeLimitMb: number;
}

export interface IVisitIdentifierDTO {
    uuid: string;
    visitName: string;
    screening: Boolean;
    active: Boolean;
    fields: IField[];
}

export interface RawFileDTO {
    data: {
        mediaUuid: string;
        results: string[];
        sessionUuid: string;
    };
    message: string;
    status: number;
}

export interface OrionSessionDTO {
    id: string;
}

export const uploadService: UploadService = {
    checkFileValidity: async (filePath: string) => {
        try {
            const response = await nebulaClient.post<{ data: [string]; message: string; status: number }>(
                `/media/validate-paths`,
                { paths: [filePath] }
            );

            if (response?.data?.status !== 200) {
                const message = response?.data?.data[0];
                return { error: { message, kind: "ApiError", statusCode: 400 } };
            }

            return { data: { message: "File path is valid" } };
        } catch (e: any) {
            const message = e?.response?.data?.data[0];
            return { error: { message, kind: "ApiError", statusCode: 400 } };
        }
    },

    getTrials: async () => {
        const response = await nebulaClient2.get<TrialDTO[]>("/trial");

        return response;
    },
    getUploadConfig: async (trialId: string) => {
        const response = await nebulaClient2.get<UploadConfigDTO>(`/trial/${trialId}/uploadConfig`);

        return response;
    },
    getTrialVisits: async (trialId: string) => {
        const response = await nebulaClient2.get<IVisitIdentifierDTO[]>(`/trial/${trialId}/visit`);

        return response;
    },
    getUploadTrack: async (uploadSessionId: string) => {
        const response = await nebulaClient.get<DerivedFileDTO[]>(`/study/upload/status/${uploadSessionId}`);

        return response;
    },
    getValidFileExtensions: async () => {
        const response = await nebulaClient.get<FileExtensionDTO[]>("/media/valid-extensions");

        return response;
    },
    getSessionStatus: async (sessionUuids: SessionUuid[]) => {
        const sessionsStatus = await Promise.all(
            sessionUuids.map((sessionUuid) => nebulaClient.get(`/study/upload/status/${sessionUuid}`))
        );

        const errorOccurred = sessionsStatus.find((response) => response.error);

        if (errorOccurred) {
            return errorOccurred;
        }

        return { data: sessionsStatus.map((a) => a.data).flat() };
    },
    commitFiles: async (derivedFiles: DerivedFileDTO[]) => {
        const response = await nebulaClient.post<DerivedFileDTO[]>(`/study/upload/status`, derivedFiles);

        return response;
    },
    updateCurrentGroup: async (derivedFiles: DerivedFileDTO[]) => {
        const response = await nebulaClient.post<any>(`/org/assign/my/active`, derivedFiles);

        return response;
    },
    uploadFile: async (rawFile: FormData, signal: AbortSignal, onProgress: (progressEvent: any) => void) => {
        const response: any = await nebulaUploadClient.post<RawFileDTO>("/study/raw/add", rawFile, {
            signal: signal,
            onUploadProgress: onProgress,
        });

        return { data: response?.data?.data, error: response.error };
    },
};
