import React, { useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Fade from "@mui/material/Fade";
import { BoxProps } from "@mui/system";

interface StyledBoxProps {
    showBorder: boolean;
}

const AnimatedBorderBox: React.FC<StyledBoxProps & BoxProps> = ({ showBorder, children }) => {
    const theme = useTheme();
    return (
        <Box sx={{ position: "relative" }}>
            <Fade appear={false} in={showBorder} timeout={2000}>
                <Box
                    sx={{
                        "content": '""',
                        "position": "absolute",
                        "top": 0,
                        "right": 0,
                        "bottom": 0,
                        "left": 0,
                        "zIndex": 100,
                        "boxShadow": "inset rgb(144 202 249) -7px 2px 32px 1px",
                        "border": `2px solid ${theme.palette.primary.main}`,
                        "pointerEvents": "none", // Ensure the pseudo-element doesn't interfere with interactions
                        "&::before": {
                            content: '""',
                            display: "none", // Hide any existing ::before pseudo-element
                        },
                    }}
                />
            </Fade>
            {children}
        </Box>
    );
};

export default AnimatedBorderBox;
