import { OrionService } from "./nebula.service";
import { nebulaOrionClient } from "../../lib/nebulaClient";
import { SessionUuid } from "../upload/uploadSlice";
import { OrionSessionDTO } from "../upload/nebula.port";

export const orionService: OrionService = {
    launchOrion: async ({ sessionId, studiesIds, attachmentIds }) => {
        const response = await nebulaOrionClient.post<string>("/launch", {
            sessionId,
            imageUUIDs: studiesIds,
            attachmentUUIDs: attachmentIds,
        });

        return response;
    },
    createOrionSession: async (orionSessionId: SessionUuid) => {
        const response: any = await nebulaOrionClient.post<OrionSessionDTO>("/session/fetch", {
            token: orionSessionId,
        });

        return response;
    },
};
