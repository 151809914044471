import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { clearState, logout } from "../auth/login/loginSlice";

export const generateRandomString = () => {
    let token = "";

    for (let i = 0; i < 128; i++) {
        let n = Math.floor(Math.random() * 10);
        token += n.toString(10);
    }

    return token;
};

export type OrionSession = string;

export interface IOrionSession {
    id: OrionSession;
}

export interface OrionState {
    sessionId: string;
}

const initialState: OrionState = {
    sessionId: generateRandomString(),
};

export const orionSlice = createSlice({
    name: "orion",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(clearState, (state) => {
            // Regenerate sessionId when clearing the state
            state.sessionId = generateRandomString();
        });
    },
});

export const selectOrionSessionId = (state: RootState) => state.orion.sessionId;

export default orionSlice.reducer;
