import { useCallback, useEffect, useState } from "react";

type UseResizeProps = {
    minWidth: number;
    inverse?: boolean;
};

type UseResizeReturn = {
    width: number;
    isResizing: boolean;
    enableResize: () => void;
};

const useResize = ({ minWidth, inverse = false }: UseResizeProps): UseResizeReturn => {
    const [isResizing, setIsResizing] = useState(false);
    const [width, setWidth] = useState(minWidth);

    const enableResize = useCallback(() => {
        // @ts-ignore
        document.body.style["user-select"] = "none";
        setIsResizing(true);
    }, [setIsResizing]);

    const disableResize = useCallback(() => {
        // @ts-ignore
        document.body.style["user-select"] = "auto";
        setIsResizing(false);
    }, [setIsResizing]);

    const resize = useCallback(
        (e: MouseEvent) => {
            if (isResizing) {
                const newWidth = inverse ? window.innerWidth - e.clientX : e.clientX;
                if (newWidth >= minWidth && newWidth < window.innerWidth) {
                    setWidth(newWidth);
                }
            }
        },
        [minWidth, isResizing, setWidth]
    );

    useEffect(() => {
        document.addEventListener("mousemove", resize);
        document.addEventListener("mouseup", disableResize);

        return () => {
            document.removeEventListener("mousemove", resize);
            document.removeEventListener("mouseup", disableResize);
        };
    }, [disableResize, resize]);

    return { width, enableResize, isResizing };
};

export default useResize;
