import React from "react";
import { Box } from "@mui/system";

interface ITabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
    style?: object;
    containerStyle?: object;
}

const TabPanel: React.FC<ITabPanelProps> = ({ children, style, value, index, containerStyle = {} }) => {
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            style={style}
        >
            {value === index && <Box sx={{ p: 3, height: "100%", ...containerStyle }}>{children}</Box>}
        </div>
    );
};

export default TabPanel;
