import { serviceSlice } from "../../app/serviceSlice";
import { workflowService } from "./nebula.port";
import { WorkflowHistoryItem, WorkflowState } from "./nebula.service";

export const extendedApiSlice = serviceSlice
    .enhanceEndpoints({ addTagTypes: ["WORKFLOW_STATE", "WORKFLOW_TRANSITIONS", "WORKFLOW_HISTORY", "PATIENTS"] })
    .injectEndpoints({
        endpoints: (builder) => ({
            getVisitState: builder.query<{ value: string; priority?: string }, { patientId: string; visitId: string }>({
                query:
                    ({ patientId, visitId }) =>
                    () =>
                        workflowService.getVisitState(patientId, visitId),
                providesTags: ["WORKFLOW_STATE"],
            }),
            getVisitPossibleTransitions: builder.query<
                { value: string; priority?: string }[],
                { patientId: string; visitId: string }
            >({
                query:
                    ({ patientId, visitId }) =>
                    () =>
                        workflowService.getVisitPossibleTransitions(patientId, visitId),
                providesTags: ["WORKFLOW_TRANSITIONS"],
            }),
            getSiteWorkflowHistory: builder.query<WorkflowHistoryItem[], { patientId: string; visitId: string }>({
                query:
                    ({ patientId, visitId }) =>
                    () =>
                        workflowService.getSiteWorkflowHistory(patientId, visitId),
                providesTags: ["WORKFLOW_HISTORY"],
            }),
            getWorkflowStates: builder.query<WorkflowState[], string>({
                query: (trialId) => () => workflowService.getWorkflowStates(trialId),
            }),
            transitionVisit: builder.mutation<
                void,
                {
                    patientId: string;
                    visitId: string;
                    transition: { value: string; priority?: string };
                    message: string;
                }
            >({
                query:
                    ({ patientId, visitId, transition, message }) =>
                    () =>
                        workflowService.transitionVisit(patientId, visitId, transition.value, message),
                invalidatesTags: ["WORKFLOW_HISTORY", "WORKFLOW_TRANSITIONS", "PATIENTS"],
            }),
        }),
    });

export const {
    useGetVisitStateQuery,
    useLazyGetVisitStateQuery,
    useGetVisitPossibleTransitionsQuery,
    useTransitionVisitMutation,
    useGetWorkflowStatesQuery,
    useGetSiteWorkflowHistoryQuery,
} = extendedApiSlice;
