import React, { useCallback, useMemo, useEffect } from "react";
import {
    DataGrid,
    GridCellParams,
    GridPreProcessEditCellProps,
    GridRenderCellParams,
    GridRenderEditCellParams,
    GridToolbar,
    GridValueFormatterParams,
} from "@mui/x-data-grid";
import { useGetUserDataQuery } from "../auth/dataAccess";
import { Box } from "@mui/system";
import { useGetTrialsQuery } from "../upload/dataAccess";
import { IPatient } from "../patients/patientsSlice";
import {
    CrfField,
    CrfFieldOptionDefaultType,
    CrfFieldType,
    CrfReading,
    CrfReadingValue,
    FieldOption,
    OptionType,
} from "./nebula.service";
import { EditableOptions } from "./components/EditableOptions";
import { TextAreaEditCell } from "./components/TextAreaEditCell";
import { ColumnHeader } from "./components/ColumnHeader";
import { GridCell } from "./components/GridCell";
import { useAppSelector } from "../../app/hooks";
import { v4 as uuidv4 } from "uuid";
import { getSelectedTrial } from "../auth/login/loginSlice";
import useRoles from "../permissions/useRoles";
import DisplayText from "../../components/DisplayText/DisplayText";

interface ICRFVisitTableProps {
    fields: CrfField[];
    data: CrfReading[];
    patient: IPatient;
    visit: {
        name: string;
        uuid: string;
    };
}

const CRFVisitTable: React.FC<ICRFVisitTableProps> = ({ fields, data, patient, visit }) => {
    const { userHasRole } = useRoles();
    const tableUniqueId = useMemo(() => `tableId-${uuidv4()}`, []);

    const parseRows = (crfData: CrfReading[]) => {
        return [...crfData]
            .sort((a, b) => a.user.email.localeCompare(b.user.email) || a.laterality.localeCompare(b.laterality))
            .map((crfReading) => {
                return {
                    id: `${crfReading.user.uuid}-${crfReading.laterality}-${visit.name}`,
                    trialName: selectedTrial!.name,
                    visitName: visit.name,
                    siteName: patient.site,
                    patientName: patient.patientId,
                    isEditable: crfReading.isEditable,
                    laterality: crfReading.laterality,
                    notes: crfReading.notes,
                    patientId: patient.uuid,
                    visitId: visit.uuid,
                    userEmail: crfReading.user.email,
                    // @ts-ignore
                    ...crfReading.values.reduce(
                        (acc: Record<string, any>, readingValue: CrfReadingValue) => ({
                            ...acc,
                            [readingValue.fieldName]: readingValue.fieldValue,
                        }),
                        {}
                    ),
                };
            });
    };
    const createColumns = (fields: CrfField[], data: CrfReading[]) => {
        const fixedColumns = [
            ...(!userHasRole("SPONSOR")
                ? [
                      {
                          field: "userEmail",
                          headerName: "User",
                          hideable: false,
                          width: 200,
                          renderCell: (params: GridRenderCellParams<string>) => <GridCell {...params} />,
                      },
                  ]
                : []),
            {
                field: "laterality",
                headerName: "Laterality",
                width: 80,
                hideable: false,
                valueFormatter: (params: GridValueFormatterParams<string>) => params.value.toUpperCase(),
                sortable: false,
            },
            {
                field: "notes",
                headerName: "Notes",
                width: 100,
                renderHeader: () => (
                    <DisplayText
                        style={{ flex: 1, overflow: "hidden", textOverflow: "ellipsis" }}
                        type="bodySmall"
                        text="Notes"
                    />
                ),
                editable: true,
                renderCell: (params: GridRenderCellParams<string>) => <GridCell {...params} />,
                renderEditCell: (props: GridRenderEditCellParams) => <TextAreaEditCell {...props} />,
                sortable: false,
            },
        ];

        const metadataColumns = [
            {
                field: "trialName",
                headerName: "Trial",
                renderCell: (params: GridRenderCellParams<string>) => <GridCell {...params} />,
                hide: true,
                hideable: false,
            },
            {
                field: "visitName",
                headerName: "Visit",
                renderCell: (params: GridRenderCellParams<string>) => <GridCell {...params} />,
                hide: true,
                hideable: false,
            },
            {
                field: "patientName",
                headerName: "Patient",
                renderCell: (params: GridRenderCellParams<string>) => <GridCell {...params} />,
                hide: true,
                hideable: false,
            },
            {
                field: "siteName",
                headerName: "Site",
                renderCell: (params: GridRenderCellParams<string>) => <GridCell {...params} />,
                hide: true,
                hideable: false,
            },
        ];

        const validateFixedOptions = (newValue: any, options: FieldOption[]) => {
            const fixedOptionsValues = options.filter(
                (option): option is CrfFieldOptionDefaultType => option.type !== OptionType.input
            );

            const optionValues = fixedOptionsValues.map((option) => option.value);

            return optionValues.includes(newValue);
        };

        const fieldColumns = fields.map((field) => {
            return {
                field: field.fieldName,
                headerName: field.fieldName,
                minWidth: 160,
                flex: 1,
                renderHeader: () => <ColumnHeader field={field} />,
                valueFormatter: (params: GridValueFormatterParams<string>) => {
                    if (field.fieldType !== CrfFieldType.list) return params.value;

                    const options = field.fieldOptions;

                    const selectableOptions = options.filter(
                        (option: FieldOption): option is CrfFieldOptionDefaultType => option.type !== OptionType.input
                    );

                    const selectedOption = selectableOptions.find((option) => option.value === params.value);

                    if (selectedOption) {
                        return selectedOption.label;
                    }

                    if (field.precision !== undefined) {
                        const isNumber = parseFloat(params.value);
                        return isNumber ? parseFloat(params.value).toFixed(field.precision) : params.value;
                    }

                    return params.value;
                },
                hide:
                    !field.isRequired &&
                    !data.some((reading) => reading.values.some((crfValue) => crfValue.fieldName === field.fieldName)),
                hideable: true,
                editable: true,
                preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
                    // Avoid showing error tooltip when user didn't type anything yet
                    if (params.props.value === "") return { ...params };

                    if (field.fieldType === CrfFieldType.list) {
                        if (validateFixedOptions(params.props.value, field.fieldOptions as FieldOption[]))
                            return { ...params };
                    }

                    const isPositiveFloat = Number(params.props.value) >= 0;
                    const error = isPositiveFloat === true ? null : "Value must be positive decimal.";

                    return { ...params.props, error };
                },
                renderEditCell: (props: GridRenderEditCellParams) => {
                    if (field.fieldType !== CrfFieldType.list) {
                        // TODO to be implemented
                        return <input></input>;
                    }

                    return (
                        <EditableOptions
                            {...props}
                            options={field.fieldOptions as FieldOption[]}
                            fieldDefinition={field}
                        />
                    );
                },
                renderCell: (params: GridRenderCellParams<string, any, string>) => (
                    <GridCell {...params} fieldDefinition={field} />
                ),
                sortable: false,
            };
        });

        return [...fixedColumns, ...fieldColumns, ...metadataColumns];
    };

    const { data: userData } = useGetUserDataQuery();

    const selectedTrial = useAppSelector(getSelectedTrial);
    const columns = useMemo(() => createColumns(fields, data), [fields]);
    const parsedRows = useMemo(() => {
        return parseRows(data);
    }, [data]);

    const getCellClassName = useCallback((params: GridCellParams) => {
        let className = "";
        if (userHasRole("SPONSOR")) {
            if (params.field === "laterality") {
                className += " firstCell";
            }
        } else {
            if (params.field === "userEmail") {
                className += " firstCell";
            }

            if (params.field === "laterality") {
                className += "secondCell";
            }
        }

        if (params.isEditable) {
            className += " editableCell";
        } else {
            className += " nonEditable";
        }

        return className;
    }, []);

    const isCellEditable = useCallback((params) => {
        return params.row.isEditable;
    }, []);

    const features = { newEditingApi: true };

    const gridComponents = {
        Toolbar: GridToolbar,
    };

    const getFileName = () => {
        const userId = userData!.userID;
        const visitNumber = visit.name;
        const date = new Date().toJSON().slice(0, 10);

        return `${selectedTrial!.name}_${userId}_${visitNumber}_${date}`;
    };

    const fileName = getFileName();

    const csvOptions = {
        fileName,
        allColumns: true,
    };

    const handleScrollHorizontal = () => {
        // @ts-ignore
        const currentScrollPos = document.querySelector(`#${tableUniqueId} .MuiDataGrid-virtualScroller`).scrollLeft;
        const columnsHeaders = document.querySelectorAll(
            `#${tableUniqueId} .MuiDataGrid-columnHeader:not([data-field="laterality"]):not([data-field="userEmail"])`
        );

        columnsHeaders.forEach((columnHeader: any) => {
            columnHeader.style.transform = `translate3d(-${currentScrollPos}px, 0px, 0px)`;
        });
    };

    useEffect(() => {
        const findVirtualScroller = () => {
            const virtualScrollerElement = document.querySelector(`#${tableUniqueId} .MuiDataGrid-virtualScroller`);
            if (!virtualScrollerElement) {
                setTimeout(findVirtualScroller, 100);
            } else {
                virtualScrollerElement.addEventListener("scroll", handleScrollHorizontal);
                return () => {
                    virtualScrollerElement.removeEventListener("scroll", handleScrollHorizontal);
                };
            }
        };
        findVirtualScroller();
    }, []);

    return (
        <Box
            sx={{
                "height": 250,
                "width": "100%",
                "& .nonEditable": {
                    backgroundColor: "#29323f",
                    color: "#9c9b9b",
                },
                "& .firstCell": {
                    position: "sticky",
                    left: 0,
                    zIndex: 2,
                    borderRight: userHasRole("SPONSOR") ? "2px solid #515151" : "none",
                },
                ...(!userHasRole("SPONSOR") && {
                    "& .secondCell": {
                        position: "sticky",
                        left: 200,
                        zIndex: 2,
                        borderRight: "2px solid #515151",
                    },
                }),
            }}
            id={tableUniqueId}
        >
            <DataGrid
                columns={columns}
                sx={{
                    "& .MuiDataGrid-cell--editing": {
                        position: "relative",
                        overflow: "visible !important",
                    },
                    "& .MuiDataGrid-columnHeaders": {
                        "& .MuiDataGrid-columnHeadersInner": {
                            "transform": "none !important",
                            "& > div:first-child": {
                                zIndex: 2,
                                backgroundColor: "#202735",
                            },
                            ...(!userHasRole("SPONSOR") && {
                                "& > div:nth-child(2)": {
                                    zIndex: 2,
                                    backgroundColor: "#202735",
                                },
                            }),
                        },
                    },
                }}
                disableVirtualization
                rows={parsedRows}
                componentsProps={{ toolbar: { csvOptions } }}
                components={gridComponents}
                getCellClassName={getCellClassName}
                isCellEditable={isCellEditable}
                experimentalFeatures={features}
                columnBuffer={columns.length}
                density="compact"
                disableColumnFilter
                disableColumnMenu
                disableDensitySelector
                disableSelectionOnClick
                hideFooter
            />
        </Box>
    );
};

export default CRFVisitTable;
