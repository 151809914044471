import { styled } from "@mui/system";
import { MenuItem, Select, SelectChangeEvent, SelectProps } from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import { updateDerivedFile } from "../../uploadSlice";

interface IEditableSelectProps {
    value: string;
    uuid: string;
    attributeName: string;
    editable?: boolean;
    options: {
        value: string;
        label: string;
    }[];
}

const CustomSelect = styled(Select)<SelectProps>(({ theme }) => ({
    minWidth: 120,
}));

const EditableSelect: React.FC<IEditableSelectProps> = ({ options, value, attributeName, editable = true, uuid }) => {
    const dispatch = useDispatch();

    const handleChange = (e: SelectChangeEvent<any>) => {
        dispatch(updateDerivedFile({ id: uuid, changes: { [attributeName]: e.target.value } }));
    };

    return (
        <CustomSelect autoWidth value={value} onChange={handleChange}>
            {options.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                    {option.label}
                </MenuItem>
            ))}
        </CustomSelect>
    );
};

export default EditableSelect;
