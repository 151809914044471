import React, { useEffect, useMemo } from "react";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import PatientsDashboard from "../patients/PatientsDashboard";
import Studies from "../studies/StudiesDashboard";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { deselectPatient, selectCurrentPatient, selectPatient } from "../patients/patientsSlice";
import { useLocation } from "react-router-dom";
import { Drawer } from "@mui/material";
import { Box } from "@mui/system";
import useResize from "../../hooks/useResize/useResize";
import NoMatch from "../../components/NoMatch/NoMatch";

const Layout: React.FC = () => {
    const currentPatient = useAppSelector(selectCurrentPatient);
    const dispatch = useAppDispatch();
    const theme = useTheme();
    const { width: adaptableWidth, enableResize, isResizing } = useResize({ minWidth: 700, inverse: true });
    const isLargeScreen = useMediaQuery(theme.breakpoints.up("lg"));

    const isStudiesDrawerOpen = Boolean(currentPatient);

    const location = useLocation();

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const selectedPatient = searchParams.get("selectedPatient");
        if (selectedPatient) {
            dispatch(selectPatient({ currentPatientId: selectedPatient }));
        } else {
            dispatch(deselectPatient());
        }
    }, [location.search]);

    const mainStyle = useMemo(() => {
        return {
            height: "100%",
            overflow: "auto",
            display: "flex",
            flexDirection: "column",
            flexGrow: 1,
            padding: 4,
            transition: "margin 0.2s ease",
            marginRight: {
                lg: -adaptableWidth,
            },
            ...(isStudiesDrawerOpen && {
                marginRight: 0,
                transition: "margin 0.2s ease-out",
            }),
        };
    }, [adaptableWidth, isStudiesDrawerOpen]);

    // If the patient ID in the URL is not found in the patient list, show a 404 page
    if (currentPatient === undefined) {
        return <NoMatch />;
    }

    return (
        <Box sx={{ display: "flex", flex: 1, overflow: "hidden" }}>
            <Box sx={mainStyle}>
                <PatientsDashboard />
            </Box>
            <Drawer
                anchor={isLargeScreen ? "right" : "bottom"}
                variant={isLargeScreen ? "persistent" : "temporary"}
                sx={{
                    "width": {
                        lg: adaptableWidth,
                    },
                    "flexShrink": {
                        lg: 0,
                    },
                    "pointerEvents": isStudiesDrawerOpen ? "initial" : "none",
                    "& .MuiDrawer-paper": {
                        lg: {
                            height: "calc(100% - 64px)",
                            top: "64px",
                            width: adaptableWidth,
                        },
                        xs: {
                            height: "100vh",
                        },
                    },
                    "& .MuiPaper-root": {
                        // height: "100%",
                        // lg: {
                        //     height: "100",
                        // },
                    },
                }}
                open={isStudiesDrawerOpen}
            >
                <Box
                    style={{
                        position: "absolute",
                        paddingRight: 32,
                        top: "0",
                        left: "-1px",
                        bottom: "0",
                        cursor: "ew-resize",
                    }}
                    onMouseDown={enableResize}
                >
                    <Box
                        sx={{
                            width: "3px",
                            height: "100%",
                            flex: 1,
                            backgroundColor: isResizing ? "#90caf9" : "transparent",
                            boxShadow: isResizing ? "rgb(144 202 249) -7px 2px 32px 1px" : "none",
                        }}
                    />
                </Box>

                {currentPatient && <Studies key={currentPatient?.uuid} />}
            </Drawer>
        </Box>
    );
};

export default Layout;
