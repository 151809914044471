import { Boundary } from "./Boundary";
import { Formatter } from "../utils/Formtter";

class XHairs {
    public x: number = 0;
    public y: number = 0;
    public vEnable = true;
    public hEnable = true;
    public hide = false;
}

export class OctImage {
    public _xHairs: XHairs = new XHairs();
    private _xHairsShow = true;

    constructor(
        public image: HTMLImageElement | null = null,
        public bounds: Boundary,
        public aspect: number = 1, // w / h
        public srcPixW: number = 0,
        public srcPixH: number = 0,
        public srcWDimension: number = 0, // actual length e.g., 6000 microns
        public srcHDimension: number = 0,
        public srcWUnit: string = "",
        public srcHUnit: string = "",
        public showXHairsH = true,
        public tickLength: number = 6,
        public labelColor: string = "#6699ff", // bluish color
        public labelTextSize = 9
    ) {
        this._xHairs.hEnable = showXHairsH;
    }

    public id = "";

    public aspectSet = (w: number, h: number): number => {
        if (!h || !w) {
            this.aspect = 1;
            return this.aspect;
        }
        this.aspect = w / h;
        return this.aspect;
    };

    public centerInBoundary = (b: Boundary): Boundary => {
        const bHCenter = (b.x + b.xRight) / 2;
        const bVCenter = (b.y + b.yBottom) / 2;
        const bs = this.bounds;
        const iHHalf = bs.w / 2;
        const iVHalf = bs.h / 2;
        bs.y = bVCenter - iVHalf;
        bs.x = bHCenter - iHHalf;
        bs.h = b.h;
        bs.w = b.w;
        return bs;
    };

    public centerHorizontal = (b: Boundary, totalWidth: number) => {
        const bs = this.bounds;
        const halfImagePos = b.w / 2 + b.x;
        const halfTotalPosition = totalWidth / 2;
        const offset = halfImagePos - halfTotalPosition;
        bs.x = b.x - offset;
    };

    public draw = (ctx: CanvasRenderingContext2D | null) => {
        const my = this;
        if (my.image && ctx) {
            const b = my.bounds;
            ctx.drawImage(my.image, 0, 0, my.srcPixW, my.srcPixH, b.x, b.y, b.w, b.h);
            if (my._xHairsShow) my._xHairsDraw(ctx);
        }
    };

    public drawScale = (
        ctx: CanvasRenderingContext2D,
        isVertical: boolean,
        actualMicronsLength: number,
        divisions: number
    ) => {
        const my = this;
        const b = this.bounds;
        const length = isVertical ? b.h : b.w;
        const micronsPerPix = actualMicronsLength / length;
        const increment = length / divisions;
        for (let i = 0; i <= divisions; i++) {
            let offset = i * increment;
            if (i === divisions) {
                offset = length;
            }
            let v = Math.round(offset * micronsPerPix);
            if (isVertical) {
                const label = Formatter.preBlank(v.toString(10), 4) + ` ${my.srcHUnit}`;
                my._drawVTick(ctx, label, b.x, b.y + offset);
            } else {
                const label = Formatter.preBlank(v.toString(10), 4) + ` ${my.srcWUnit}`;
                my._drawHTick(ctx, label, b.x + offset, b.yBottom);
            }
        }
    };

    public fitToHeight = (height: number) => {
        this.bounds.w = this.aspect * height;
        this.bounds.h = height;
    };

    public rawFit = (width: number, height: number) => {
        this.bounds.h = height;
        this.bounds.w = width;
    };

    public fitToWidth = (width: number) => {
        this.bounds.w = width;
        this.bounds.h = width / this.aspect;
    };

    public fit = (w: number, h: number) => {
        const w1 = this.aspect * h;
        // If new width exceeds the requested width then anchor to the requested width
        if (w1 > w) {
            this.fitToWidth(w);
        } else {
            this.fitToHeight(h);
        }
    };

    public offset = (right: number, top: number) => {
        this.bounds.x += right;
        this.bounds.y += top;
    };

    public xhairsClear = () => {
        this._xHairs.hide = true;
    };

    public xhairsNudgeX = (byX: number | null, byY: number | null) => {
        const b = this.bounds;
        const xh = this._xHairs;
        if (byX) {
            let x = xh.x + byX;
            x = x > b.xRight ? b.xRight : x;
            xh.x = x < b.x ? b.x : x;
        }
        return xh.x;
    };

    public xhairsNudgeY = (byY: number | null) => {
        const b = this.bounds;
        const xh = this._xHairs;
        if (byY) {
            let y = xh.y + byY;
            y = y > b.yBottom ? b.yBottom : y;
            xh.y = y < b.y ? b.y : y;
        }
        return xh.y;
    };

    public xhairsRawX = (x: number | null = null): number => {
        const my = this;
        const b = this.bounds;
        x = x ? x : my._xHairs.x;
        return Math.round(((x - b.x) * my.srcPixW) / b.w);
    };

    public xhairsRawY = (y: number | null = null): number => {
        const my = this;
        const b = this.bounds;
        y = y ? y : my._xHairs.y;

        return Math.round(((y - b.y) * my.srcPixH) / b.h);
    };

    public xhairsSet = (atX: number | null, atY: number | null) => {
        const b = this.bounds;
        const x = this._xHairs;
        const atX2 = atX && atX > 0 ? atX : b.x + b.w / 2;
        const atY2 = atY && atY > 0 ? atY : b.y + b.h / 2;
        if (b.isInside(atX2, atY2, 0)) {
            x.x = atX2;
            x.y = atY2;
            x.hide = false;
        }
    };

    public xhairsSetRel = (atX: number | null, atY: number | null) => {
        const b = this.bounds;
        const x = this._xHairs;
        const atX2 = atX !== null ? atX + b.x : x.x;
        const atY2 = atY !== null ? atY + b.y : x.y;
        this.xhairsSet(atX2, atY2);
    };

    public xhairsSetRaw = (rawX: number | null, rawY: number | null = null) => {
        const my = this;
        const b = this.bounds;
        let x = null;
        let y = null;
        if (rawX != null) {
            x = rawX ? (rawX * b.w) / my.srcPixW : 0;
        }
        if (rawY != null) {
            y = rawY ? (rawY * b.h) / my.srcPixH : 1;
        }
        my.xhairsSetRel(x, y);
    };

    public get xhairsShow() {
        return this._xHairsShow;
    }

    public set xhairsShow(flag: boolean) {
        this._xHairsShow = flag;
    }

    // Private Functions
    private _drawHTick = (ctx: CanvasRenderingContext2D, label: string, x: number, y: number) => {
        const my = this;
        ctx.textAlign = "center";
        ctx.textBaseline = "top";
        ctx.fillStyle = my.labelColor;
        ctx.font = `${my.labelTextSize}px Arial, Helvetica, sans-serif`;
        const mt = ctx.measureText(label);
        const w = mt.width;
        ctx.clearRect(x - w / 2, y + my.tickLength + 4, w, my.labelTextSize);
        ctx.fillText(label, x, y + my.tickLength + 4);
        ctx.beginPath();
        ctx.strokeStyle = my.labelColor;
        ctx.lineWidth = 2;
        ctx.moveTo(x, y);
        ctx.lineTo(x, y + my.tickLength);
        ctx.stroke();
        ctx.closePath();
    };

    private _drawVTick = (ctx: CanvasRenderingContext2D, label: string, x: number, y: number) => {
        const my = this;
        ctx.textAlign = "end";
        ctx.textBaseline = "middle";
        ctx.fillStyle = my.labelColor;
        ctx.font = `${my.labelTextSize}px Arial, Helvetica, sans-serif`;
        const mt = ctx.measureText(label);
        const w = mt.width + 12;
        const h = my.labelTextSize;
        ctx.clearRect(x - w, y - 0.5 * h, w, h);
        ctx.fillText(label, x - my.tickLength - 4, y);
        ctx.strokeStyle = my.labelColor;
        ctx.lineWidth = 2;
        ctx.beginPath();
        ctx.moveTo(x, y);
        ctx.lineTo(x - my.tickLength, y);
        ctx.stroke();
        ctx.closePath();
    };

    private _xHairsDraw = (ctx: CanvasRenderingContext2D) => {
        const my = this;
        if (my._xHairs.hide) return;
        const x = my._xHairs;
        const b = my.bounds;
        ctx.lineWidth = 1;
        ctx.fillStyle = "#c9cc06";
        ctx.shadowOffsetX = 1;
        ctx.shadowOffsetY = 1;
        ctx.shadowColor = "#000000";
        if (x.hEnable) {
            ctx.moveTo(b.x, x.y);
            ctx.lineTo(b.xRight, x.y);
            ctx.stroke();
        }
        if (x.vEnable) {
            ctx.moveTo(x.x, b.y);
            ctx.lineTo(x.x, b.yBottom);
            ctx.stroke();
        }
    };
}
