import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import React from "react";

interface WorkflowDropdownProps {
    visitState: any;
    visitPossibleTransitions: any;
    onChange: (e: any) => void;
}

const WorkflowDropdown: React.FC<WorkflowDropdownProps> = ({ visitState, visitPossibleTransitions, onChange }) => {
    const stateColor: Record<string, string> = {
        LOW: "info.main",
        MEDIUM: "warning.main",
        HIGH: "error.main",
        HIGHEST: "error.main",
        DONE: "success.main",
        DEFAULT: "info.main",
    };

    return (
        <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
            {!visitState && <InputLabel sx={{ top: -5 }}>Loading...</InputLabel>}
            <Select
                displayEmpty
                renderValue={(transition: any) => transition?.label}
                sx={{
                    "& .MuiSelect-select": {
                        padding: "4px 8px",
                        opacity: 1,
                        webkitTextFillColor: "inherit",
                    },
                    "& .Mui-disabled": {
                        opacity: 1,
                        cursor: "not-allowed",
                        webkitTextFillColor: "white",
                        textAlign: "center",
                        paddingRight: "13px !important",
                    },
                    "backgroundColor": visitState ? stateColor[visitState.priority || "DEFAULT"] : "inherit",
                }}
                disabled={!visitPossibleTransitions || !visitState || visitPossibleTransitions.length === 0}
                IconComponent={visitPossibleTransitions?.length === 0 ? () => null : undefined}
                onClick={(e: any) => e.stopPropagation()}
                value={visitState || ""}
                onChange={onChange}
            >
                {visitPossibleTransitions?.map((transition: any) => (
                    <MenuItem
                        key={transition.value}
                        sx={{ color: stateColor[transition.priority] || "info.main" }}
                        value={transition.value}
                    >
                        {transition.value}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export default React.memo(WorkflowDropdown);
