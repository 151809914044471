import { DashboardService } from "./nebula.service";
import { nebulaClient2 } from "../../lib/nebulaClient";

interface DashboardResponseDTO {
    value: number;
    goal: number;
}
export const dashboardService: DashboardService = {
    getEnrolledPatients: async (trialId: string) => {
        const response = await nebulaClient2.get<DashboardResponseDTO>(`report/${trialId}/enrolledPatients`);

        return response;
    },
    getEnrolledSites: async (trialId: string) => {
        const response = await nebulaClient2.get<DashboardResponseDTO>(`report/${trialId}/enrolledSites`);

        return response;
    },
    getEnrolledPatientsVisits: async (trialId: string) => {
        const response = await nebulaClient2.get<DashboardResponseDTO>(`report/${trialId}/enrolledPatientsVisits`);

        return response;
    },
};
