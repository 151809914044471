import React from "react";
import SearchBar from "./SearchBar";
import { FilterGroup, FilterOption } from "./useFilters";

interface SearchBarProps {
    activeFilters: Map<string, FilterGroup>;
    onQueryChange: (value: string) => void;
    onFilterChange: (filterKey: string, activeFilterOptions: FilterOption[]) => void;
    searchQueryValue: string;
}

const PatientSearch: React.FC<SearchBarProps> = ({
    onQueryChange,
    searchQueryValue,
    activeFilters,
    onFilterChange,
}) => {
    return (
        <SearchBar
            activeFilters={activeFilters}
            onFilterChange={onFilterChange}
            onChange={onQueryChange}
            value={searchQueryValue}
        />
    );
};

export default React.memo(PatientSearch);
