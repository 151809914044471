import { nebulaClient2 } from "../../lib/nebulaClient";
import { Alert, AlertService } from "./alert.service";

export const alertService: AlertService = {
    findActiveAlertsForUser: async () => {
        const response = await nebulaClient2.get<Alert[]>("/alerts");

        return response;
    },
};
