import React from "react";
import Tooltip from "../Tooltip/Tooltip";

interface IComingSoonFeatureProps {
    children: React.ReactElement;
}

const ComingSoonFeature: React.FC<IComingSoonFeatureProps> = ({ children }) => {
    return <Tooltip content="Feature coming soon!">{children}</Tooltip>;
};

export default ComingSoonFeature;
