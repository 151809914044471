import React from "react";
import { Popover } from "@mui/material";
import { Box } from "@mui/system";

interface IComingSoonFeatureProps {
    children: React.ReactElement;
    content: React.ReactElement | string;
    onMouseEnter?: () => void;
    onMouseLeave?: () => void;
    style?: object;
}

const Tooltip: React.FC<IComingSoonFeatureProps> = ({
    content,
    children,
    onMouseEnter = () => {},
    onMouseLeave = () => {},
    style = {},
}) => {
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

    const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
        onMouseEnter();
    };

    const handlePopoverClose = async () => {
        setAnchorEl(null);
        // Hack to avoid remove tooltip before executing onMouseLeave function
        await new Promise((resolve) => setTimeout(resolve, 100));
        await onMouseLeave();
    };

    return (
        <>
            <Box style={style} onMouseEnter={handlePopoverOpen} onMouseLeave={handlePopoverClose}>
                {children}
            </Box>
            <Popover
                disableRestoreFocus
                id="mouse-over-popover"
                sx={{
                    pointerEvents: "none",
                }}
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
                onClose={handlePopoverClose}
            >
                <Box sx={{ p: 8 }}>{content}</Box>
            </Popover>
        </>
    );
};

export default Tooltip;
