import { BaseQueryFn, createApi } from "@reduxjs/toolkit/dist/query/react";
import { logoutUser } from "../features/auth/login/loginSlice";
import { DataResponse } from "../shared/DataResponse";

type DataAccessMethod = (payload?: any) => Promise<DataResponse>;

const queryService: BaseQueryFn<DataAccessMethod, unknown, unknown> = async (dataAccessMethod, api) => {
    const { error, data } = await dataAccessMethod();

    if (error) {
        switch (error.kind) {
            case "ApiError":
                return { error: { status: error.statusCode, data: error.message } };
            case "UnexpectedError":
                return { error: { status: "UnexpectedError", data: error.message } };
            case "Unauthorized":
                api.dispatch(logoutUser());

                return {
                    error: { status: 401, data: null },
                };
            case "NotFound":
                return {
                    error: { status: 404, data: null },
                };
            default:
                return { error: { status: "UnhandledError" } };
        }
    }

    return { data };
};

// Define our single API slice object
export const serviceSlice = createApi({
    // The cache reducer expects to be added at `state.api` (already default - this is optional)
    reducerPath: "cacheApi",
    // All of our requests will have URLs starting with config.api_baseurl.baseUrl
    baseQuery: queryService,

    // The "endpoints" represent operations and requests for this server
    endpoints: (builder) => ({}),
});
