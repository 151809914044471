import { serviceSlice } from "../../app/serviceSlice";
import { patientService } from "./nebula.port";
import { IPatient, Tag } from "./patientsSlice";
import { RootState } from "../../app/store";

export const extendedApiSlice = serviceSlice.enhanceEndpoints({ addTagTypes: ["PATIENTS", "TAGS"] }).injectEndpoints({
    endpoints: (builder) => ({
        getPatients: builder.query<IPatient[], string>({
            query: (trialId) => () => patientService.getPatients(trialId),
            providesTags: ["PATIENTS"],
        }),
        getPatientById: builder.query<IPatient, string>({
            query: (patientId) => () => patientService.getPatientById(patientId),
            transformResponse: (responseData: { data: IPatient }) => {
                return responseData.data;
            },
        }),
        getTags: builder.query<Tag[], string>({
            query: (trialId) => () => patientService.getTags(trialId),
            providesTags: ["TAGS"],
        }),
        addTag: builder.mutation<void, { patientId: string; tag: Tag }>({
            query:
                ({ patientId, tag }) =>
                () =>
                    patientService.addTagToPatient(patientId, tag),
            async onQueryStarted({ patientId, tag }, { dispatch, queryFulfilled, getState }) {
                const state = getState() as RootState;

                const selectedTrial = state.userInfo.selectedTrialId;
                const patchResult = dispatch(
                    extendedApiSlice.util.updateQueryData("getPatients", selectedTrial, (draft) => {
                        draft.forEach((aPatient, i) => {
                            if (aPatient.uuid === patientId) {
                                draft[i].tags.push(tag);
                            }
                        });
                    })
                );
                try {
                    await queryFulfilled;
                } catch {
                    patchResult.undo();
                }
            },
        }),
        removeTag: builder.mutation<void, { patientId: string; tagId: string }>({
            query:
                ({ patientId, tagId }) =>
                () =>
                    patientService.removeTagFromPatient(patientId, tagId),
            async onQueryStarted({ patientId, tagId }, { dispatch, queryFulfilled, getState }) {
                const state = getState() as RootState;

                const selectedTrial = state.userInfo.selectedTrialId;
                const patchResult = dispatch(
                    extendedApiSlice.util.updateQueryData("getPatients", selectedTrial, (draft) => {
                        draft.forEach((aPatient, i) => {
                            if (aPatient.uuid === patientId) {
                                draft[i].tags = aPatient.tags.filter((tag) => tag.uuid !== tagId);
                            }
                        });
                    })
                );
                try {
                    await queryFulfilled;
                } catch {
                    patchResult.undo();
                }
            },
        }),
        editPatient: builder.mutation<void, Partial<IPatient>>({
            query: (patient) => () => patientService.editPatient(patient),
            async onQueryStarted(patient, { dispatch, queryFulfilled, getState }) {
                const state = getState() as RootState;

                const selectedTrial = state.userInfo.selectedTrialId;
                const patchResult = dispatch(
                    extendedApiSlice.util.updateQueryData("getPatients", selectedTrial, (draft) => {
                        draft.forEach((aPatient, i) => {
                            if (aPatient.uuid === patient.uuid) {
                                draft[i] = { ...aPatient, ...patient };
                            }
                        });
                    })
                );
                try {
                    await queryFulfilled;
                } catch {
                    patchResult.undo();
                }
            },
        }),
    }),
});

export const {
    useGetPatientsQuery,
    useGetPatientByIdQuery,
    useEditPatientMutation,
    useGetTagsQuery,
    useAddTagMutation,
    useRemoveTagMutation,
} = extendedApiSlice;
