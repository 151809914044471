import React from "react";
import { Typography, Box } from "@mui/material";

const NoMatch = () => {
    return (
        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%", height: "100%" }}>
            <Typography> Page not found</Typography>
        </Box>
    );
};

export default NoMatch;
