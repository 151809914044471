import React from "react";
import {
    CircularProgress,
    CircularProgressProps,
    Box,
    Card,
    Divider,
    LinearProgressProps,
    LinearProgress,
    Skeleton,
} from "@mui/material";
import DisplayText from "../../components/DisplayText/DisplayText";
import theme from "../../app/theme";
import Tooltip from "../../components/Tooltip/Tooltip";
import { useGetEnrolledPatientsVisitsQuery, useGetEnrolledSitesQuery, useGetEnrolledPatientsQuery } from "./dataAccess";
import { useGetUserDataQuery } from "../auth/dataAccess";
import { useUpdateCurrentGroupMutation } from "../upload/dataAccess";
import { useAppSelector } from "../../app/hooks";
import { getSelectedTrial } from "../auth/login/loginSlice";

const CircularProgressWithLabel = (
    props: CircularProgressProps & { value: number; title: string; subtitle: string; legend: string }
) => {
    return (
        <Box sx={{ position: "relative", display: "inline-flex" }}>
            <CircularProgress
                thickness={2}
                size={300}
                variant="determinate"
                sx={{
                    svg: {
                        circle: {
                            strokeLinecap: "round",
                        },
                    },
                }}
                {...props}
            />
            <CircularProgress
                thickness={2}
                variant="determinate"
                size={300}
                value={100}
                sx={{
                    position: "absolute",
                    zIndex: 1,
                    right: 0,
                    svg: {
                        color: "black",
                        opacity: 0.2,
                        circle: {
                            strokeDashoffset: "0px !important",
                        },
                    },
                }}
            />
            <Tooltip
                style={{ zIndex: 999 }}
                content={<DisplayText style={{ whiteSpace: "pre-line" }} type="bodySmall" text={props.legend} />}
            >
                <Box
                    sx={{
                        top: 0,
                        left: 0,
                        bottom: 0,
                        right: 0,
                        position: "absolute",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <DisplayText text={props.title} type="h5" />
                    <DisplayText text={props.subtitle} type="bodyMedium" variant="regular" />
                </Box>
            </Tooltip>
        </Box>
    );
};

// function CircularProgressWithLabel(
//     props: CircularProgressProps & { value: number; title: string; subtitle: string; legend: string }
// ) {
//     return (
//         <Box sx={{ position: "relative", display: "inline-flex" }}>
//             <CircularProgress thickness={2} variant="determinate" size={300} {...props} />
//             <Tooltip content={<DisplayText style={{ whiteSpace: "pre-line" }} type="bodySmall" text={props.legend} />}>
//                 <Box
//                     sx={{
//                         top: 0,
//                         left: 0,
//                         bottom: 0,
//                         right: 0,
//                         position: "absolute",
//                         display: "flex",
//                         flexDirection: "column",
//                         alignItems: "center",
//                         justifyContent: "center",
//                     }}
//                 >
//                     <DisplayText text={props.title} type="h5" />
//                     <DisplayText text={props.subtitle} type="bodyMedium" variant="regular" />
//                 </Box>
//             </Tooltip>
//         </Box>
//     );
// }

function LinearProgressWithLabel(
    props: LinearProgressProps & { value: number; total: number; title: string; isLoading: boolean }
) {
    const progress = props.value && props.total ? props.value / props.total : 0;

    return (
        <Box sx={{ display: "flex", alignItems: "center", gap: 10 }}>
            <Box sx={{ width: "100%", mr: 1 }}>
                <DisplayText type="bodyLarge" text={props.title} style={{ marginBottom: 12 }} />
                {props.isLoading ? (
                    <Skeleton variant="text" height={10} />
                ) : (
                    <LinearProgress variant="determinate" value={progress * 100} />
                )}
            </Box>
            <Box sx={{ minWidth: 35, display: "flex", gap: 4, position: "relative", top: 10 }}>
                {props.isLoading ? (
                    <Skeleton variant="text" />
                ) : (
                    <DisplayText
                        type="bodyMedium"
                        style={{ whiteSpace: "nowrap" }}
                        variant="semiBold"
                        text={`${props.value} / `}
                    />
                )}
                {props.isLoading ? (
                    <Skeleton variant="text" />
                ) : (
                    <DisplayText type="bodyMedium" text={`${props.total}`} style={{ color: "#d9d9d9" }} />
                )}
            </Box>
        </Box>
    );
}

interface IDashboardProps {}

const Dashboard: React.FC<IDashboardProps> = () => {
    const { data: userData, isFetching: userDataFetching } = useGetUserDataQuery();
    const [, { isLoading: isChangingGroup }] = useUpdateCurrentGroupMutation({ fixedCacheKey: "groupChange" });
    const selectedTrial = useAppSelector(getSelectedTrial);
    const trialId = selectedTrial!.uuid;

    const {
        data: enrolledPatients,
        error: enrolledPatientsError,
        isFetching: enrolledPatientsFetching,
    } = useGetEnrolledPatientsQuery(trialId);
    const {
        data: patientsVisits,
        error: patientsVisitsError,
        isFetching: patientsVisitsFetching,
    } = useGetEnrolledPatientsVisitsQuery(trialId);
    const {
        data: enrolledSites,
        error: enrolledSitesError,
        isFetching: enrolledSitesFetching,
    } = useGetEnrolledSitesQuery(trialId);

    return (
        <Box sx={{ padding: theme.spacing(20) }}>
            <DisplayText type="h3" text="Dashboard" />
            <Box sx={{ display: "flex", gap: 16, flexWrap: "wrap" }}>
                <Card sx={{ p: 20, flex: "1 0 450px" }}>
                    <DisplayText type="h5" text="Overview" />
                    <Divider />
                    <Box sx={{ display: "flex", flexDirection: "column", gap: 12, padding: 24 }}>
                        <DisplayText type="h6" text="Overall trial progress" style={{ textAlign: "center" }} />
                        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", mt: 16 }}>
                            {enrolledPatientsError ? (
                                <Box>Something wrong happened</Box>
                            ) : userDataFetching ||
                              isChangingGroup ||
                              enrolledPatientsFetching ||
                              !Boolean(patientsVisits) ? (
                                <Skeleton variant="circular" width={300} height={300} />
                            ) : (
                                <CircularProgressWithLabel
                                    legend={`Total: ${patientsVisits?.goal} visits
                            Completed: ${patientsVisits?.value} visits
                            Remaining: ${patientsVisits ? patientsVisits?.goal - patientsVisits?.value : 0} visits`}
                                    value={patientsVisits ? (patientsVisits?.value / patientsVisits?.goal) * 100 : 0}
                                    title={`${patientsVisits?.value} / ${patientsVisits?.goal}`}
                                    subtitle="patients visits"
                                />
                            )}
                        </Box>
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                gap: 12,
                                mt: 12,
                                flex: 1,
                                justifyContent: "space-between",
                            }}
                        >
                            <Box sx={{ display: "flex", flexDirection: "column", gap: 24 }}>
                                <Box sx={{ width: "100%" }}>
                                    {patientsVisitsError ? (
                                        <Box>Something wrong happened</Box>
                                    ) : (
                                        <LinearProgressWithLabel
                                            isLoading={
                                                userDataFetching ||
                                                isChangingGroup ||
                                                patientsVisitsFetching ||
                                                !Boolean(enrolledSites)
                                            }
                                            title="Enrolled patients"
                                            value={enrolledPatients?.value || 0}
                                            total={enrolledPatients?.goal || 0}
                                        />
                                    )}
                                </Box>
                                <Box sx={{ width: "100%" }}>
                                    {enrolledSitesError ? (
                                        <Box>Something wrong happened</Box>
                                    ) : (
                                        <LinearProgressWithLabel
                                            isLoading={
                                                userDataFetching ||
                                                isChangingGroup ||
                                                enrolledSitesFetching ||
                                                !Boolean(enrolledPatients)
                                            }
                                            title="Enrolled sites"
                                            total={enrolledSites?.goal || 0}
                                            value={enrolledSites?.value || 0}
                                        />
                                    )}
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Card>
            </Box>
        </Box>
    );
};

export default Dashboard;
