import React from "react";
import { Chip } from "@mui/material";

interface AnimatedChipsProps {
    keyString?: string;
    elements: any[];
}
const AnimatedChips: React.FC<AnimatedChipsProps> = ({ elements, keyString = "id" }) => {
    return (
        <>
            {elements.map((element) => (
                <Chip
                    // @ts-ignore
                    key={element[keyString]}
                    size="small"
                    label={element.label}
                    onMouseOver={(e: React.MouseEvent<HTMLDivElement>) => {
                        e.currentTarget.style.zIndex = "100";
                        e.currentTarget.style.maxWidth = "inherit";
                        e.currentTarget.style.width = "inherit";
                    }}
                    onMouseOut={(e: React.MouseEvent<HTMLDivElement>) => {
                        e.currentTarget.style.zIndex = "0";
                        e.currentTarget.style.maxWidth = "80px";
                        e.currentTarget.style.minWidth = "80px";
                        e.currentTarget.style.width = "80px";
                    }}
                    variant="outlined"
                    sx={{
                        maxWidth: "80px",
                        width: "80px",
                        cursor: "pointer",
                        backgroundColor: element.color,
                        color: "#FFFFFF",
                        marginRight: "-50px",
                        position: "relative",
                        transition: "z-index 0.1s ease, max-width 0.1s ease",
                    }}
                />
            ))}
        </>
    );
};

export default AnimatedChips;
