import React, { useEffect, useMemo, useState } from "react";
import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
} from "@mui/material";
import {
    useBulkUpdateMediaModalitiesMutation,
    useGetModalitiesQuery,
    useUpdateMediaModalityMutation,
} from "./dataAccess";
import DisplayText from "../../components/DisplayText/DisplayText";
import { Box } from "@mui/system";
import { useSnackbar } from "notistack";
import { useAppSelector } from "../../app/hooks";
import { getSelectedTrial } from "../auth/login/loginSlice";

interface IEditModalityProps {
    isOpen: boolean;
    onClose: () => void;
    currentModality?: string;
    patientId: string;
    mediaIds: string[];
    multiple?: boolean;
    selectStudies?: (mediaIds: string[]) => void;
}
const EditModality: React.FC<IEditModalityProps> = ({
    isOpen,
    onClose,
    currentModality,
    patientId,
    mediaIds,
    multiple = false,
    selectStudies = () => {},
}) => {
    const { enqueueSnackbar } = useSnackbar();
    const selectedTrial = useAppSelector(getSelectedTrial);
    const trialId = selectedTrial!.uuid;
    const { data: modalities } = useGetModalitiesQuery(trialId, {
        skip: !isOpen,
    });
    const [updateModality] = useUpdateMediaModalityMutation();
    const [bulkUpdateModalities] = useBulkUpdateMediaModalitiesMutation();
    const [selectedModality, setSelectedModality] = useState("");

    useEffect(() => {
        if (!currentModality) return;

        if (modalities && modalities.includes(currentModality)) return setSelectedModality(currentModality);
    }, [modalities]);

    const handleSaveModalityChange = async () => {
        const currentSelectedMediaIds = mediaIds;
        selectStudies([]);
        onClose();
        try {
            if (multiple) {
                const mediaModalitiesUpdates = mediaIds.map((mediaId) => ({
                    mediaId,
                    newModality: selectedModality,
                }));

                await bulkUpdateModalities({ patientId, mediaModalitiesUpdates }).unwrap();
            } else {
                await updateModality({ patientId, mediaId: mediaIds[0], modality: selectedModality }).unwrap();
            }
        } catch (e) {
            selectStudies(currentSelectedMediaIds);
            enqueueSnackbar("Error updating modality", { variant: "error" });
        }
    };

    const handleChangeModality = (event: SelectChangeEvent) => {
        const newModality = event.target.value as string;
        setSelectedModality(newModality);
    };

    if (!isOpen) return null;

    const title = multiple ? "Edit modalities" : "Edit modality";

    return (
        <Dialog maxWidth="sm" fullWidth open={true} onClose={onClose}>
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", p: 24, pb: 0 }}>
                <DisplayText text={title} type="h5" />
            </Box>
            <DialogContent>
                {!modalities ? (
                    <Box sx={{ display: "flex", justifyContent: "center", alignContent: "center" }}>
                        <CircularProgress />
                    </Box>
                ) : (
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Select modality</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={selectedModality}
                            label="Select Modality"
                            onChange={handleChangeModality}
                        >
                            {modalities.map((modality: string) => (
                                <MenuItem value={modality}>{modality}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Close</Button>
                <Button
                    variant="contained"
                    disabled={Boolean(!selectedModality || selectedModality === currentModality)}
                    onClick={handleSaveModalityChange}
                >
                    Accept
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default React.memo(EditModality);
