import { nebulaClient2 } from "../../lib/nebulaClient";
import { WorkflowService, WorkflowHistoryItem, WorkflowState } from "./nebula.service";

export const workflowService: WorkflowService = {
    getVisitState: async (patientId: string, visitId: string) => {
        const response = await nebulaClient2.get<string>(`/workflow/patient/${patientId}/visit/${visitId}/state`);

        return response;
    },
    getVisitPossibleTransitions: async (patientId: string, visitId: string) => {
        const response = await nebulaClient2.get<string[]>(
            `/workflow/patient/${patientId}/visit/${visitId}/possible-transitions`
        );

        return response;
    },
    getSiteWorkflowHistory: async (patientId: string, visitId: string) => {
        const response = await nebulaClient2.get<WorkflowHistoryItem[]>(
            `/workflow/patient/${patientId}/visit/${visitId}/history`
        );

        return response;
    },
    transitionVisit: async (patientId: string, visitId: string, transition: string, message?: string) => {
        const response = await nebulaClient2.put<void>(`/workflow/patient/${patientId}/visit/${visitId}/transition`, {
            transition,
            message,
        });

        return response;
    },
    getWorkflowStates: async (trialId: string) => {
        const response = await nebulaClient2.get<WorkflowState[]>(`/workflow/trial/${trialId}/states`);

        return response;
    },
};
