import React from "react";
import { IEncounterComment } from "./studiesSlice";
import useRoles from "../permissions/useRoles";
import CommentsDialog from "../../components/CommentsDialog/CommentsDialog";

interface EncounterCommentsDialogProps {
    onClose: () => void;
    comments: IEncounterComment[];
    onAddComment: (comment: string) => Promise<any>;
}

// @ts-ignore
const EncounterCommentsDialog: React.FC<EncounterCommentsDialogProps> = ({ onClose, comments, onAddComment }) => {
    const { userHasRole } = useRoles();

    const handleAddComment = async (newComment: string) => {
        onAddComment(newComment);
    };

    return (
        <CommentsDialog
            onClose={onClose}
            canAddComments={
                userHasRole("SITE_USER") ||
                userHasRole("PROJECT_MANAGER") ||
                userHasRole("READER") ||
                userHasRole("SUPER_READER")
            }
            comments={comments}
            onAdd={handleAddComment}
        />
    );
};

export default React.memo(EncounterCommentsDialog);
