import React, { useEffect } from "react";
import ViewSDKClient from "../../lib/pdf_client";
import { nanoid } from "@reduxjs/toolkit";

interface ISizedContainerProps {
    pdfUrl: string;
    fileName?: string;
}

const SizedContainer: React.FC<ISizedContainerProps> = ({ pdfUrl, fileName }) => {
    const uniqueId = nanoid();

    useEffect(() => {
        const viewSDKClient = new ViewSDKClient();
        viewSDKClient.ready().then(() => {
            /* Invoke file preview */
            viewSDKClient.previewFile(
                `pdf-div-${uniqueId}`,
                {
                    showAnnotationTools: false,
                    showLeftHandPanel: false,
                    showPrintPDF: false,
                    showDownloadPDF: false,
                    embedMode: "SIZED_CONTAINER",
                },
                pdfUrl,
                fileName
            );
        });
    }, []);

    return <div id={`pdf-div-${uniqueId}`} className="sized-container-div" />;
};

export default SizedContainer;
