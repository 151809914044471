import {
    Accordion,
    AccordionSummary,
    Typography,
    Avatar,
    TableCell,
    TableRow,
    Table,
    TableBody,
    TableContainer,
    TableCellProps,
} from "@mui/material";
import { ArrowDropDown } from "@mui/icons-material";
import { useAppSelector } from "../../app/hooks";
import { Formatter } from "../../utils/Formtter";
import { selectStudies } from "../studies/studiesSlice";
import { useGetPatientStudiesByEncounterQuery, useGetPatientVisitsQuery } from "../studies/dataAccess";
import { styled, TypographyProps } from "@mui/system";
import theme from "../../app/theme";
import { IPatient } from "../patients/patientsSlice";
import { getCommaSeparatedPatientName } from "../patients/utils";

const CustomCell = styled(TableCell)<TableCellProps>(({ theme }) => ({
    borderBottom: "none",
    fontSize: theme.spacing(12),
    paddingTop: "0",
    paddingBottom: "0",
}));

const SecondaryText = styled(Typography)<TypographyProps>(({ theme }) => ({
    // color: theme.palette.text.secondary
    color: "#5b7481",
}));

interface IVisitHeaderProps {
    patient: IPatient;
}

const VisitHeader: React.FC<IVisitHeaderProps> = ({ patient }) => {
    const studiesState = useAppSelector(selectStudies);

    const {
        data,
        // @ts-ignore
    } = useGetPatientStudiesByEncounterQuery(patient.uuid);

    return (
        <>
            <Accordion
                sx={{
                    "backgroundColor": "#0e1118",
                    "&:first-of-type": {
                        borderTopLeftRadius: "0px",
                        borderTopRightRadius: "0px",
                    },
                }}
            >
                <AccordionSummary expandIcon={<ArrowDropDown />} aria-controls="panel1a-content" id="panel1a-header">
                    <TableContainer>
                        <Table>
                            <TableBody>
                                <TableRow>
                                    <CustomCell>
                                        <Avatar
                                            aria-label="label"
                                            sx={{
                                                backgroundColor: theme.palette.success.main,
                                                height: theme.spacing(20),
                                                width: theme.spacing(20),
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    fontSize: theme.spacing(10),
                                                    fontWeight: 1000,
                                                }}
                                            >
                                                OK
                                            </Typography>
                                        </Avatar>
                                    </CustomCell>
                                    <CustomCell>
                                        <Typography>{patient.patientId}</Typography>
                                    </CustomCell>
                                    <CustomCell>
                                        <Typography>
                                            {getCommaSeparatedPatientName(patient.firstName, patient.lastName)}
                                        </Typography>
                                    </CustomCell>
                                    <CustomCell>
                                        <SecondaryText>{patient.gender}</SecondaryText>
                                    </CustomCell>
                                    <CustomCell>
                                        <SecondaryText>
                                            {patient.dateOfBirth} ({Formatter.calculateAge(patient.dateOfBirth)})
                                        </SecondaryText>
                                    </CustomCell>
                                    <CustomCell>
                                        <SecondaryText>{data?.studies?.length} visits</SecondaryText>
                                    </CustomCell>
                                    <CustomCell>
                                        <SecondaryText>
                                            {studiesState.currentStudy?.imagingProcedure.reason
                                                ? studiesState.currentStudy?.imagingProcedure.reason
                                                : "No reason"}{" "}
                                        </SecondaryText>
                                    </CustomCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </AccordionSummary>
            </Accordion>
        </>
    );
};

export default VisitHeader;
