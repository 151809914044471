import React, { useEffect } from "react";
import { Box } from "@mui/system";
import { useDispatch, useSelector } from "react-redux";
import {
    IDerivedFile,
    selectDerivedFilesGroupedByPatient,
    updateUploadState,
    UploadStep,
    selectDerivedFiles,
    selectSessionUuids,
    selectDerivedFilesById,
} from "../../uploadSlice";
import { useGetSessionStatusQuery, useCommitFilesMutation } from "../../dataAccess";
import { Button, Checkbox, CircularProgress, Typography } from "@mui/material";
import EditableDate from "./EditableDate";
import EditableInput from "./EditableInput";
import EditableSelect from "./EditableSelect";
import EditableComment from "./EditableComment";
import useMultipleSelect, { SelectionState } from "../../../../hooks/useMutipleSelect/useMultipleSelect";
import EditablePatient from "./EditablePatient";
import { useAppSelector } from "../../../../app/hooks";

const FileViewer = () => {
    const dispatch = useDispatch();
    const derivedFilesGroupedByPatient = useSelector(selectDerivedFilesGroupedByPatient);
    const derivedFiles = useSelector(selectDerivedFiles);
    const sessionsUuids = useSelector(selectSessionUuids);

    const { isLoading: sessionStatusIsLoading, error: sessionStatusError } = useGetSessionStatusQuery(sessionsUuids, {
        refetchOnMountOrArgChange: true,
    });

    const [commitFiles, { isLoading: isCommittingFiles, isSuccess: isCommittingFilesSuccess }] =
        useCommitFilesMutation();

    const {
        selectionState,
        handleSelect,
        handleSelectAll,
        clearSelect,
        selectedItems: selectedDerivedFiles,
    } = useMultipleSelect<IDerivedFile>({
        items: derivedFiles,
        getItemId: (item) => item.mediaUuid,
    });

    useEffect(() => {
        if (isCommittingFilesSuccess) {
            clearSelect();
        }
    }, [isCommittingFilesSuccess]);

    const filesToCommit = useAppSelector(selectDerivedFilesById(Array.from(selectedDerivedFiles)));

    const handleBackClick = () => dispatch(updateUploadState(UploadStep.FILE_UPLOAD));

    const handleCheckAll = (e: React.ChangeEvent<HTMLInputElement>) => {
        const isChecked = e.target.checked;
        handleSelectAll(isChecked);
    };

    const handleCommitFiles = () => {
        commitFiles(filesToCommit as IDerivedFile[]);
    };

    const renderMetadataRow = (derivedFile: IDerivedFile) => {
        const handleCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
            return handleSelect(derivedFile.mediaUuid, e.target.checked);
        };

        return (
            <Box key={derivedFile.mediaUuid} sx={{ display: "contents", alignItems: "center", py: 8 }}>
                <EditableInput value={derivedFile.fileName} uuid={derivedFile.mediaUuid} attributeName="fileName" />
                <EditablePatient value={derivedFile.patientId} uuid={derivedFile.mediaUuid} />
                <EditableSelect
                    value={derivedFile.laterality}
                    uuid={derivedFile.mediaUuid}
                    attributeName="laterality"
                    options={[
                        {
                            value: "",
                            label: "No laterality",
                        },
                        {
                            value: "OD",
                            label: "OD",
                        },
                        {
                            value: "OS",
                            label: "OS",
                        },
                        {
                            value: "OD,OS",
                            label: "OD,OS",
                        },
                    ]}
                />
                <EditableDate
                    value={derivedFile.encounterDate}
                    attributeName="encounterDate"
                    uuid={derivedFile.mediaUuid}
                />
                <Box sx={{ justifySelf: "center" }}>
                    <EditableComment
                        uuid={derivedFile.mediaUuid}
                        comment={derivedFile.comment}
                        fileName={derivedFile.fileName}
                    />
                </Box>
                <Checkbox onChange={handleCheck} checked={selectedDerivedFiles.has(derivedFile.mediaUuid)} />
            </Box>
        );
    };

    const renderDerivedFile = (fileEntryMetadata: IDerivedFile[], i: number, arr: any[]) =>
        fileEntryMetadata.map(renderMetadataRow);

    const Header = (
        <Box sx={{ display: "contents", borderBottom: "1px solid #335462" }}>
            <Typography variant="h6">File Name</Typography>
            <Typography variant="h6">PID</Typography>
            <Typography variant="h6">Laterality</Typography>
            <Typography variant="h6">Date</Typography>
            <Box sx={{ justifySelf: "center" }}>
                <Typography variant="h6">Message</Typography>
            </Box>
            <Checkbox
                onChange={handleCheckAll}
                indeterminate={selectionState === SelectionState.SOME}
                checked={selectionState === SelectionState.ALL}
            />
        </Box>
    );

    return (
        <Box
            sx={{
                height: 600,
                padding: "20px",
                borderWidth: 2,
                borderRadius: 2,
                borderColor: "#335462",
                borderStyle: "solid",
                display: "flex",

                flexDirection: "column",
                justifyContent: "space-between",
            }}
        >
            {sessionStatusIsLoading || sessionStatusError ? (
                <Box sx={{ height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    {sessionStatusIsLoading ? <CircularProgress /> : <Typography>Something went wrong</Typography>}
                </Box>
            ) : (
                <Box
                    sx={{
                        overflow: "auto",
                        flex: 1,
                        display: "grid",
                        gridTemplateColumns:
                            "minmax(0, 1.3fr) minmax(0, 1.5fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 0.6fr) 0.3fr",
                        alignItems: "center",
                        alignContent: "start",
                        gap: "10px 6%",
                        flexDirection: "column",
                    }}
                >
                    {Header}
                    {Object.values(derivedFilesGroupedByPatient).map(renderDerivedFile)}
                </Box>
            )}

            <Box
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-end",
                    width: "100%",
                    mx: -4,
                    pt: 16,
                    mt: 16,
                    borderTop: "1px solid #335462",
                }}
            >
                <Box
                    sx={{
                        mx: 4,
                    }}
                >
                    <Button onClick={handleBackClick} component="button" variant="outlined">
                        Back
                    </Button>
                </Box>
                <Box
                    sx={{
                        mx: 4,
                    }}
                >
                    <Button
                        disabled={selectedDerivedFiles.size === 0 || isCommittingFiles}
                        onClick={handleCommitFiles}
                        component="button"
                        variant="contained"
                    >
                        {isCommittingFiles ? "Committing..." : "Commit"}
                    </Button>
                </Box>
            </Box>
        </Box>
    );
};

export default FileViewer;
