import app_config from "../config.json";

class ViewSDKClient {
    adobeDCView: any;
    readyPromise: Promise<void> = new Promise((resolve) => {
        if (window.AdobeDC) {
            resolve();
        } else {
            /* Wait for Adobe Document Services PDF Embed API to be ready */
            document.addEventListener("adobe_dc_view_sdk.ready", () => {
                resolve();
            });
        }
    });

    ready() {
        return this.readyPromise;
    }

    previewFile(divId: string, viewerConfig: any, pdfUrl: string, fileName: string = "PDF File") {
        const config: any = {
            /* Pass your registered client id */
            clientId: app_config.adobePDFClientId,
            downloadWithCredentials: true,
        };
        if (divId) {
            /* Optional only for Light Box embed mode */
            /* Pass the div id in which PDF should be rendered */
            config.divId = divId;
        }
        /* Initialize the AdobeDC View object */
        this.adobeDCView = new window.AdobeDC.View(config);

        /* Invoke the file preview API on Adobe DC View object */
        const previewFilePromise = this.adobeDCView.previewFile(
            {
                /* Pass information on how to access the file */
                content: {
                    /* Location of file where it is hosted */
                    location: {
                        url: pdfUrl,
                        /*
                    If the file URL requires some additional headers, then it can be passed as follows:-
                    headers: [
                        {
                            key: "<HEADER_KEY>",
                            value: "<HEADER_VALUE>",
                        }
                    ]
                    */
                    },
                },
                /* Pass meta data of file */
                metaData: {
                    /* file name */
                    fileName,
                    /* file ID */
                    id: "6d07d124-ac85-43b3-a867-36930f502ac6",
                },
            },
            viewerConfig
        );

        return previewFilePromise;
    }

    previewFileUsingFilePromise(divId: string, filePromise: Promise<string | ArrayBuffer>, fileName: any) {
        /* Initialize the AdobeDC View object */
        this.adobeDCView = new window.AdobeDC.View({
            /* Pass your registered client id */
            clientId: app_config.adobePDFClientId,
            /* Pass the div id in which PDF should be rendered */
            divId,
        });

        /* Invoke the file preview API on Adobe DC View object */
        this.adobeDCView.previewFile(
            {
                /* Pass information on how to access the file */
                content: {
                    /* pass file promise which resolve to arrayBuffer */
                    promise: filePromise,
                },
                /* Pass meta data of file */
                metaData: {
                    /* file name */
                    fileName: fileName,
                },
            },
            {}
        );
    }

    registerEventsHandler() {
        /* Register the callback to receive the events */
        this.adobeDCView.registerCallback(
            /* Type of call back */
            window.AdobeDC.View.Enum.CallbackType.EVENT_LISTENER,
            /* call back function */
            (event: any) => {
                // eslint-disable-next-line no-console
                console.log(event);
            },
            /* options to control the callback execution */
            {
                /* Enable PDF analytics events on user interaction. */
                enablePDFAnalytics: true,
            }
        );
    }
}

export default ViewSDKClient;
