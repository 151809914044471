import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useMemo } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { getSelectedTrial, selectTrial } from "../auth/login/loginSlice";
import { ITrial } from "../upload/uploadSlice";
import { useGetTrialsQuery, useUpdateCurrentGroupMutation } from "../upload/dataAccess";
import { useSnackbar } from "notistack";
import useQueryParams from "../../hooks/useQueryParams/useQueryParams";

const TrialDropdown = () => {
    const dispatch = useAppDispatch();
    const { removeQueryParam } = useQueryParams();

    const { enqueueSnackbar } = useSnackbar();
    const [updateCurrentGroup] = useUpdateCurrentGroupMutation({
        fixedCacheKey: "groupChange",
    });

    const { data: trials } = useGetTrialsQuery();
    const selectedTrial = useAppSelector(getSelectedTrial);

    const handleChangeGroup = async (e: any) => {
        const currentSelectedTrial = selectedTrial!.uuid;
        const selectedGroup = e.target.value;
        try {
            dispatch(selectTrial(selectedGroup));
            removeQueryParam("selectedPatient", true);
            await updateCurrentGroup({ uuid: selectedGroup }).unwrap();
        } catch (e) {
            dispatch(selectTrial(currentSelectedTrial));
            enqueueSnackbar("Something wrong happened.", {
                variant: "error",
            });
        }
    };

    const renderTrialItem = (trial: ITrial) => (
        <MenuItem key={trial.uuid} value={trial.uuid}>
            {trial.name}
        </MenuItem>
    );

    const trialItems = useMemo(() => {
        if (!trials) return;

        return trials.map(renderTrialItem);
    }, [trials]);

    return (
        <FormControl size="small" sx={{ m: 1, minWidth: 250 }}>
            <InputLabel>{!trials ? "Loading..." : "Trials"}</InputLabel>
            <Select
                autoWidth={false}
                label="Loading..."
                value={selectedTrial?.uuid || ""}
                disabled={!selectedTrial}
                onChange={handleChangeGroup}
            >
                {trialItems}
            </Select>
        </FormControl>
    );
};

export default TrialDropdown;
