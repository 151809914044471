import { Box } from "@mui/material";
import { API_MEDIA_BASE_PATH } from "../../lib/nebulaClient";
import SizedContainerPDF from "../../components/PDF/SizedConteiner";
import React, { memo } from "react";

interface IiframeMediaViewerProps {
    url: string;
}
const PdfMediaViewer: React.FC<IiframeMediaViewerProps> = ({ url }) => {
    return (
        <Box>
            <SizedContainerPDF pdfUrl={url} />
        </Box>
    );
};

export default memo(PdfMediaViewer);
