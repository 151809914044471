import { Box } from "@mui/system";
import DisplayText from "../../../components/DisplayText/DisplayText";
import React from "react";

interface ILateralityPillProps {
    laterality: string;
}
const LateralityPill: React.FC<ILateralityPillProps> = ({ laterality }) => (
    <Box sx={{ py: 2, px: 6, backgroundColor: "#736F4F", borderRadius: 2 }}>
        <DisplayText type="bodyMedium" variant="bold" text={laterality} />
    </Box>
);

export default LateralityPill;
