import React, { useState } from "react";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { Box, Breadcrumbs, CircularProgress, Fab, Grid, Link, Typography } from "@mui/material";
import { AddCircle as AddCircleIcon } from "@mui/icons-material";
import MediaViewer from "./MediaViewer";
import VisitHeader from "./VisitHeader";
import AddStudyDialog from "./AddStudyDialog";
import { IPatient } from "../patients/patientsSlice";
import { IEncounter, IStudy, UserCRF } from "../studies/studiesSlice";
import { useGetPatientStudiesByEncounterQuery, useGetPatientVisitsQuery } from "../studies/dataAccess";
import { useGetPatientByIdQuery } from "../patients/dataAccess";
import { getMediaViewerIdsFromUri, getQueryParamFromStudyId } from "./utils";
import * as queryString from "query-string";
import CRFDrawer from "./CRFDrawer";
import { getCommaSeparatedPatientName } from "../patients/utils";

const VisitsAnalyzer = () => {
    const { patientId } = useParams<{ patientId: string }>();
    const location = useLocation();
    const history = useHistory();
    const [addStudyDialogOpen, setAddStudyDialogOpen] = useState(false);

    const selectedMediaViewerIds = getMediaViewerIdsFromUri(location.search) as string[];
    const [selectedEncounter, setSelectedEncounter] = useState<IEncounter>();

    const { isLoading: studyLoading, data: studiesByEncounter } = useGetPatientStudiesByEncounterQuery({ patientId });

    const { data: patientData } = useGetPatientByIdQuery(patientId);

    const patient = patientData;

    const handleOpenAddStudyDialog = () => {
        setAddStudyDialogOpen(true);
    };

    const handleCloseAddStudyDialog = () => {
        setAddStudyDialogOpen(false);
    };

    const handleAddStudy = (newStudyId: string) => {
        const studiesIds = getMediaViewerIdsFromUri(location.search) as string[];

        const studyIdAsQueryParam = getQueryParamFromStudyId(newStudyId);

        history.replace({
            pathname: location.pathname,
            search: queryString.stringify({ st: [...studiesIds, studyIdAsQueryParam] }),
        });
        handleCloseAddStudyDialog();
    };

    const handleCloseDrawer = () => setSelectedEncounter(undefined);

    const handleOpenDrawer = (selectedEncounter: IEncounter) => {
        setSelectedEncounter(selectedEncounter);
    };

    if (studyLoading || !patient)
        return (
            <Box sx={{ flex: 1, display: "flex", justifyContent: "center", alignItems: "center" }}>
                <CircularProgress />
            </Box>
        );

    return (
        <Box sx={{ flex: 1, display: "flex", flexDirection: "column" }}>
            <VisitHeader patient={patient as IPatient} />
            <Box
                sx={{
                    padding: 20,
                    display: "flex",
                    flexDirection: "column",
                    flex: 1,
                    overflow: "auto",
                }}
            >
                <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", my: 8 }}>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link underline="hover" color="inherit" href="/patients">
                            Patients
                        </Link>
                        <Typography color="text.primary">
                            {getCommaSeparatedPatientName(patient!.firstName, patient!.lastName)}
                        </Typography>
                    </Breadcrumbs>
                    <Fab
                        sx={{ backgroundColor: "white" }}
                        variant="extended"
                        size="small"
                        color="primary"
                        aria-label="add"
                        onClick={handleOpenAddStudyDialog}
                    >
                        <AddCircleIcon sx={{ mr: 8 }} />
                        Add Study
                    </Fab>
                </Box>
                {selectedMediaViewerIds.length === 0 ? (
                    <Box sx={{ display: "flex", flex: 1, justifyContent: "center", alignItems: "center" }}>
                        Select a study
                    </Box>
                ) : (
                    <Grid container spacing={16}>
                        {selectedMediaViewerIds.map((mediaViewerId: string) => {
                            const key = mediaViewerId.split(":")[0];
                            return (
                                <Grid
                                    sx={{
                                        my: 8,
                                    }}
                                    item
                                    xl={6}
                                    xs={12}
                                    key={key}
                                >
                                    <MediaViewer onOpenCRF={handleOpenDrawer} mediaViewerId={mediaViewerId} />
                                </Grid>
                            );
                        })}
                    </Grid>
                )}
            </Box>
            <CRFDrawer
                selectedEncounterId={selectedEncounter?.visitId}
                isOpen={Boolean(selectedEncounter)}
                title={`CRF for: ${selectedEncounter?.visitName}`}
                onClose={handleCloseDrawer}
                patient={patientData}
            />
            <AddStudyDialog
                onClose={handleCloseAddStudyDialog}
                onAddStudy={handleAddStudy}
                open={addStudyDialogOpen}
                encounters={studiesByEncounter}
            />
        </Box>
    );
};

export default VisitsAnalyzer;
