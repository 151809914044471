import * as React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import { IEncounter, IMedia, IMediaOctFile, IStudy } from "../studies/studiesSlice";
import { Divider } from "@mui/material";
import DisplayText from "../../components/DisplayText/DisplayText";
import { Box } from "@mui/system";
import { getMimeTypePrettyName } from "../../utils/MediaUtils";

interface IAddStudyDialogProps {
    onClose: () => void;
    onAddStudy: (studyId: string) => void;
    open: boolean;
    encounters: IEncounter[];
}

const AddStudyDialog: React.FC<IAddStudyDialogProps> = ({ onClose, open, encounters, onAddStudy }) => {
    const handleClose = () => {
        onClose();
    };

    const handleListItemClick = (value: string) => {
        onAddStudy(value);
    };

    const renderStudy = (media: IMedia) => (
        <ListItem sx={{ pl: 20 }} button onClick={() => handleListItemClick(media.uuid)} key={media.uuid}>
            <ListItemText
                primary={`${getMimeTypePrettyName(media.mimeType)} ${media.laterality ? `- ${media.laterality}` : ""}`}
                secondary={`Slices: ${media.dicomData.numberOfFrames}`}
            />
        </ListItem>
    );

    const renderVisit = (encounter: IEncounter) => (
        <Box key={encounter.uuid}>
            <Divider />
            <li style={{ paddingLeft: 25 }} key={encounter.uuid}>
                <ul>
                    <DisplayText
                        text={`Date: ${new Date(encounter.encounterDate).toLocaleDateString()}`}
                        type="bodyMedium"
                        variant="semiBold"
                        style={{ marginTop: 8 }}
                    />

                    {encounter.studies.map((study) =>
                        study.series.map((serie) => serie.medias.map((media) => renderStudy(media)))
                    )}
                </ul>
            </li>
        </Box>
    );

    return (
        <Dialog fullWidth maxWidth="sm" scroll="paper" onClose={handleClose} open={open}>
            <DialogTitle>Add Study</DialogTitle>
            <List subheader={<li />}>{encounters.map(renderVisit)}</List>
        </Dialog>
    );
};

export default AddStudyDialog;
