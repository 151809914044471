import React, { useState } from "react";
import { Box, Button, Dialog, DialogTitle, IconButton, List, ListItem, TextField } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Divider from "@mui/material/Divider";
import DisplayText from "../DisplayText/DisplayText";
import { useSnackbar } from "notistack";

interface CommentsDialogProps {
    onClose: () => void;
    comments: any[];
    canAddComments: boolean;
    onAdd?: (newComment: string) => Promise<void>;
    isLoading?: boolean;
    disablePortal?: boolean;
}

const CommentsDialog: React.FC<CommentsDialogProps> = ({
    onClose,
    comments,
    canAddComments,
    onAdd,
    isLoading = false,
    disablePortal = false,
}) => {
    const { enqueueSnackbar } = useSnackbar();
    const [newComment, setNewComment] = useState("");

    const handleAddComment = async (e: any) => {
        if (!onAdd) return;

        e.preventDefault();
        const currentComment = newComment.trim();
        try {
            setNewComment("");
            await onAdd(newComment);
        } catch (e) {
            setNewComment(currentComment);
            enqueueSnackbar("Something went wrong.", { variant: "error" });
        }
    };

    return (
        <Dialog maxWidth="xl" open={true} onClose={onClose} disablePortal={disablePortal}>
            <DialogTitle>Notes</DialogTitle>
            <IconButton
                aria-label="close"
                onClick={onClose}
                sx={{
                    position: "absolute",
                    right: 8,
                    top: 8,
                }}
            >
                <CloseIcon />
            </IconButton>
            <Divider />
            <Box sx={{ display: "flex", flexDirection: "column", overflow: "hidden", minWidth: 500 }}>
                <Box sx={{ flex: 1, minHeight: 200, overflow: "auto" }}>
                    {comments.length === 0 ? (
                        <Box sx={{ position: "relative", top: "75px" }}>
                            <DisplayText
                                type="bodyMedium"
                                text="No notes yet"
                                style={{ margin: "12px 0", textAlign: "center" }}
                            />
                        </Box>
                    ) : (
                        <List sx={{ overflow: "auto", flex: 0 }}>
                            {comments.map((comment, index) => (
                                <React.Fragment key={index}>
                                    <ListItem
                                        sx={{ marginBottom: 2, borderRadius: "5px", padding: "5px", p: "8px 24px" }}
                                    >
                                        <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                                            <Box>
                                                <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
                                                    <DisplayText
                                                        type="bodyXSmall"
                                                        text="posted by"
                                                        style={{ color: "lightgray" }}
                                                    />
                                                    <DisplayText
                                                        type={"bodyXSmall"}
                                                        text={comment.userEmail || "anonymous"}
                                                        style={{ color: "orange" }}
                                                    />
                                                </Box>
                                                <DisplayText
                                                    style={{ whiteSpace: "pre-line" }}
                                                    text={comment.comment}
                                                    type="bodyMedium"
                                                />
                                            </Box>
                                            <DisplayText
                                                text={new Date(comment.createdAt).toLocaleString()}
                                                type="bodyXSmall"
                                                style={{ color: "lightgray", whiteSpace: "nowrap" }}
                                            />
                                        </Box>
                                    </ListItem>
                                    {index !== comments.length - 1 && <Divider />}
                                </React.Fragment>
                            ))}
                        </List>
                    )}
                </Box>
                {canAddComments && (
                    <>
                        <Divider />
                        <Box
                            sx={{
                                maxHeight: "210px",
                                padding: "20px 24px",
                                display: "flex",
                                flexDirection: "column",
                            }}
                        >
                            <form onSubmit={handleAddComment}>
                                <TextField
                                    autoComplete="off"
                                    value={newComment}
                                    onChange={(e) => setNewComment(e.target.value)}
                                    variant="outlined"
                                    multiline
                                    maxRows={3}
                                    placeholder="Add a new note"
                                    fullWidth
                                    margin="normal"
                                />
                                <Button
                                    type="submit"
                                    variant="contained"
                                    disabled={!newComment}
                                    color="primary"
                                    size="large"
                                    style={{ width: 125 }}
                                >
                                    Add note
                                </Button>
                            </form>
                        </Box>
                    </>
                )}
            </Box>
        </Dialog>
    );
};

export default CommentsDialog;
