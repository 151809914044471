// format date in 28-Jun-2023 format
export const prettyDate = (dateString: string): string => {
    const date = new Date(dateString);

    const options = { day: "2-digit", month: "short", year: "numeric", timeZone: "UTC" } as const;

    return date.toLocaleDateString("en-GB", options);
};

// formate date in 28-Jun-2023, 12:00 AM format
export const prettyDateWithTime = (dateString: string): string => {
    const date = new Date(dateString);

    const dateOptions = { day: "2-digit", month: "short", year: "numeric" } as const;
    const timeOptions = { hour: "2-digit", minute: "2-digit", hour12: true } as const;

    const dateStringOutput = date.toLocaleDateString("en-GB", dateOptions);
    const timeStringOutput = date.toLocaleTimeString("en-GB", timeOptions);

    return `${dateStringOutput}, ${timeStringOutput}`;
};

// format date in 2023-06-28 format
export const shortPrettyDate = (dateString: string): string => {
    const date = new Date(dateString);

    // Check if the date is invalid
    if (isNaN(date.getTime())) {
        // Handle the invalid date however you prefer
        return "-";
    }

    // Directly format to ISO string and slice to get YYYY-MM-DD
    return date.toISOString().slice(0, 10);
};

// format date in "Mon Nov 13 20:01:12 UTC 2023" format
export const longPrettyDate = (dateInput: Date): string => {
    const options: Intl.DateTimeFormatOptions = {
        weekday: "short",
        year: "numeric",
        month: "short",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
        timeZoneName: "short",
        hour12: false,
        timeZone: "UTC",
    };

    const formatter = new Intl.DateTimeFormat("en-US", options);
    return formatter.format(dateInput).replace("GMT", "UTC");
};
