import { serviceSlice } from "../../app/serviceSlice";
import { Attachment, ICRFData, IEncounterComment, IStudy } from "./studiesSlice";
import { studyService } from "./nebula.port";
import { RootState } from "../../app/store";

export const extendedApiSlice = serviceSlice
    .enhanceEndpoints({ addTagTypes: ["VISITS", "ANALYSIS_INFO"] })
    .injectEndpoints({
        endpoints: (builder) => ({
            getPatientVisits: builder.query<{ studies: IStudy[]; crfs: ICRFData }, string>({
                query: (patientId) => () => studyService.getPatientStudies(patientId),
                transformResponse: (responseData: { studies: IStudy[]; crfs: ICRFData }) => {
                    // order studies by date
                    const orderedByDateStudies = responseData.studies.sort((a, b) => {
                        const aDate = new Date(a.imagingProcedure.orderDate);
                        const bDate = new Date(b.imagingProcedure.orderDate);
                        return bDate.getTime() - aDate.getTime();
                    });

                    return {
                        studies: orderedByDateStudies,
                        crfs: responseData.crfs,
                    };
                },
                providesTags: ["VISITS"],
            }),
            getPatientStudiesByEncounter: builder.query<any, { patientId: string; showHidden?: boolean }>({
                query:
                    ({ patientId, showHidden }: { patientId: string; showHidden?: boolean }) =>
                    () =>
                        studyService.getPatientStudiesV2(patientId, showHidden),
                providesTags: ["VISITS"],
            }),
            getMediaToken: builder.mutation<string, string | void>({
                query: (expiration) => () => {
                    if (expiration) return studyService.getMediaToken(expiration);

                    return studyService.getMediaToken();
                },
                transformResponse(rawResult: { token: string }) {
                    return rawResult.token;
                },
            }),
            deleteStudy: builder.mutation<void, { studyId: string; patientId: string }>({
                query:
                    ({ studyId }) =>
                    () =>
                        studyService.deleteStudy(studyId),
                async onQueryStarted({ studyId, patientId }, { dispatch, queryFulfilled, getState }) {
                    const state = getState() as RootState;
                    const showHidden = state.studies.showHidden;

                    const patchResult = dispatch(
                        extendedApiSlice.util.updateQueryData(
                            "getPatientStudiesByEncounter",
                            { patientId, showHidden },
                            (draft) => {
                                // filter the media inside encounter -> studies -> series -> medias with uuid === studyId
                                draft.forEach((encounter: any, i: number) => {
                                    encounter.studies.forEach((study: any) =>
                                        study.series.forEach((serie: any) => {
                                            if (showHidden) {
                                                serie.medias.forEach((media: any) => {
                                                    if (media.uuid === studyId) {
                                                        media.hidden = true;
                                                    }
                                                });
                                            } else {
                                                serie.medias = serie.medias.filter(
                                                    (media: any) => media.uuid !== studyId
                                                );
                                            }
                                        })
                                    );

                                    const hasMedia = encounter.studies.some((study: any) =>
                                        study.series.some((serie: any) => serie.medias.length > 0)
                                    );

                                    if (!hasMedia) {
                                        draft.splice(i, 1);
                                    }
                                });
                            }
                        )
                    );
                    try {
                        await queryFulfilled;
                    } catch {
                        patchResult.undo();
                    }
                },
            }),
            attachFile: builder.mutation<void, FormData>({
                query: (newFile) => () => studyService.attachFile(newFile),
                invalidatesTags: ["VISITS"],
            }),
            downloadStudy: builder.mutation<{ fileBlob: Blob; contentType: string }, string>({
                query: (studyUrl) => () => studyService.downloadStudy(studyUrl),
            }),
            addStudyComment: builder.mutation<unknown, { note: string; mediaId: string; patientId: string }>({
                query:
                    ({ patientId, note, mediaId }) =>
                    () =>
                        studyService.addStudyComment(patientId, mediaId, note),
                // Update cached visits without hitting the server (optimistic updates)
                async onQueryStarted({ note, mediaId, patientId }, { dispatch, queryFulfilled, getState }) {
                    const state = getState() as RootState;
                    const showHidden = state.studies.showHidden;

                    const patchResult = dispatch(
                        extendedApiSlice.util.updateQueryData(
                            "getPatientStudiesByEncounter",
                            { patientId, showHidden },
                            (draft) => {
                                // @ts-ignore
                                const currentUser = serviceSlice.endpoints.getUserData.select()(state).data;

                                const newComment = {
                                    uuid: Math.random().toString(),
                                    userEmail: currentUser.email,
                                    createdAt: new Date().toISOString(),
                                    updatedAt: new Date().toISOString(),
                                    comment: note,
                                };

                                draft.forEach((encounter: any) =>
                                    encounter.studies.forEach((study: any) =>
                                        study.series.forEach((serie: any) =>
                                            serie.medias.forEach((media: any) => {
                                                if (media.uuid === mediaId) {
                                                    media.comments.push(newComment);
                                                }
                                            })
                                        )
                                    )
                                );
                            }
                        )
                    );
                    try {
                        await queryFulfilled;
                    } catch {
                        patchResult.undo();
                    }
                },
            }),
            getAnalysisInfo: builder.query<Attachment[], { patientId: string; mediaId: string }>({
                query:
                    ({ patientId, mediaId }) =>
                    () =>
                        studyService.getAnalysisInfo(patientId, mediaId),
                keepUnusedDataFor: 10,
            }),
            addEncounterComment: builder.mutation<
                IEncounterComment,
                { patientId: string; encounterId: string; comment: string }
            >({
                query:
                    ({ patientId, encounterId, comment }) =>
                    () => {
                        return studyService.addEncounterComment(patientId, encounterId, comment);
                    },
                async onQueryStarted({ patientId, encounterId, comment }, { dispatch, queryFulfilled, getState }) {
                    const state = getState() as RootState;
                    const showHidden = state.studies.showHidden;
                    // @ts-ignore
                    const currentUser = serviceSlice.endpoints.getUserData.select()(state).data;

                    const patchResult = dispatch(
                        extendedApiSlice.util.updateQueryData(
                            "getPatientStudiesByEncounter",
                            { patientId, showHidden },
                            (draft) => {
                                const newComment = {
                                    uuid: Math.random().toString(),
                                    patientId,
                                    encounterId,
                                    userEmail: currentUser.email,
                                    createdAt: new Date().toISOString(),
                                    updatedAt: new Date().toISOString(),
                                    comment,
                                    version: 1,
                                };

                                draft.forEach((encounter: any) => {
                                    if (encounter.uuid === encounterId) {
                                        encounter.comments.push(newComment);
                                    }
                                });
                            }
                        )
                    );
                    try {
                        await queryFulfilled;
                    } catch {
                        patchResult.undo();
                    }
                },
            }),
            deleteVisit: builder.mutation<void, { patientId: string; visitId: string }>({
                query:
                    ({ patientId, visitId }) =>
                    () =>
                        studyService.deleteVisit(patientId, visitId),
                // @ts-ignore
                invalidatesTags: ["PATIENTS"],
                async onQueryStarted({ patientId, visitId }, { dispatch, queryFulfilled, getState }) {
                    const state = getState() as RootState;
                    const showHidden = state.studies.showHidden;

                    const patchResult = dispatch(
                        extendedApiSlice.util.updateQueryData(
                            "getPatientStudiesByEncounter",
                            { patientId, showHidden },
                            (draft) => {
                                draft.filter((encounter: any, i: number) => {
                                    if (encounter.visitId === visitId) {
                                        draft.splice(i, 1);
                                    }
                                });
                            }
                        )
                    );
                    try {
                        await queryFulfilled;
                    } catch {
                        patchResult.undo();
                    }
                },
            }),
        }),
    });

export const {
    useGetPatientVisitsQuery,
    useGetPatientStudiesByEncounterQuery,
    useGetMediaTokenMutation,
    useDeleteStudyMutation,
    useAttachFileMutation,
    useDownloadStudyMutation,
    useAddStudyCommentMutation,
    useGetAnalysisInfoQuery,
    useAddEncounterCommentMutation,
    useDeleteVisitMutation,
} = extendedApiSlice;
