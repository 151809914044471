export class Boundary {
    public xRight: number = 0;
    public yBottom: number = 0;

    constructor(public x: number, public y: number, public w: number, public h: number) {
        const my = this;
        my.xRight = x + w;
        my.yBottom = y + h;
    }

    public isInside = (x: number, y: number, leeway: number = 0) => {
        const my = this;
        // console.log("[isInside] - x y -> " + x + " " + y);
        x = x > 0 ? Math.round(x) : 1;
        y = y > 0 ? Math.round(y) : 1;
        // console.log("[isInside] - after round x y -> " + x + " " + y);
        my.y = Math.round(my.y);
        my.x = Math.round(my.x);
        my.xRight = Math.round(my.x + my.w);
        my.yBottom = Math.round(my.y + my.h);
        // console.debug(`$(${Math.round(x)}, ${Math.round(y)}) of (${my.x}~${my.xRight}, ${my.y}~${my.yBottom})`);
        return x >= my.x - leeway && x <= my.xRight + leeway && y >= my.y - leeway && y <= my.yBottom + leeway;
    };

    public copy = (src: Boundary) => {
        const my = this;
        my.x = src.x;
        my.y = src.y;
        my.w = src.w;
        my.h = src.h;
        my.xRight = src.x + src.w;
        my.yBottom = src.y + src.h;
    };
}
