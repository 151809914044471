import { createEntityAdapter, createSlice, EntityState, PayloadAction } from "@reduxjs/toolkit";
import { IEncounter, IStudy } from "../studies/studiesSlice";
import { EncounterDto } from "../studies/nebula.port";

export class IAnalysisFovea {
    public foveaX: number = 0;
    public foveaY: number = 0;
}

export interface IEnFaceInfo {
    uuid: string;
    EnFaceWidth: number;
    EnFaceHeight: number;
    EnFaceWidthSizeInMicrons: number;
    EnFaceHeightSizeInMicrons: number;
}

export class IAnalysis {
    public kind: string = "";
    public date: string = "";
    public user: string = "";
    public data: any;
}

export interface IMediaInfo {
    uuid: string;
    createdOn: string;
    modifiedOn: string;
    endedOnOpt: string;
    isActive: boolean;
    label: string;
    metaOpt: any;
    schema: string;
    mimeType: string;
    mediaLocationUri: string;
    mediaStatus: string;
    ScanDateAndTime: string;
    ScanPattern: string;
    Laterality: string;
    Manufacturer: string;
    Device: string;
    FileName: string;
    ImageQuality: number;
    RasterAngleInDeg: number;
    FirstBscanStartXInMm: number;
    FirstBscanStartYInMm: number;
    LastBscanStartXInMm: number;
    LastBscanStartYInMm: number;
    BscanWidthDim: number;
    BscanHeightDim: number;
    NumSlices: number;
    BscanWidthSizeInMicrons: number;
    BscanHeightSizeInMicrons: number;
    NumSlicesSizeInMicrons: number;
    UniqueId: string;
    SiteId: string;
    SeriesId: number;
    Comment: string;
}

export interface IMediaViewerCard {
    uuid: string;
    studyId: string;
}

export interface ViewerState {
    status: string;
    errorMessage: string;
    enFaceInfo: IEnFaceInfo[];
    mediaInfo: IMediaInfo[];
    analysis: IAnalysis[];
    analysisFovea?: IAnalysisFovea;
    mediaViewerCards: EntityState<IMediaViewerCard>;
}

const mediaViewerCards = createEntityAdapter<IMediaViewerCard>({
    selectId: (visit) => visit.uuid,
});

export const initialState: ViewerState = {
    status: "idle",
    errorMessage: "",
    analysis: [],
    enFaceInfo: [],
    mediaInfo: [],
    mediaViewerCards: mediaViewerCards.getInitialState(),
};

export const viewerSlice = createSlice({
    name: "viewer",
    initialState,
    reducers: {
        selectAnalysisFovea: (state, action: PayloadAction<any>) => {
            state.analysisFovea = action.payload.aFovea;
        },
        resetState: (state) => {
            state.enFaceInfo = [];
            state.analysis = [];
            state.mediaInfo = [];
        },
    },
});

export class IApiResult<T> {
    constructor(public uuid: string = "", public status: number = 500, public message: string = "", public data: T) {}
}

export const getMediaEncounter = (encounters: IEncounter[], mediaId: string) => {
    // find encounter based on mediaId inside studies -> series => medias
    return encounters.find((encounter) => {
        return encounter.studies.some((study) => {
            return study.series.some((series) => {
                return series.medias.some((media) => {
                    return media.uuid === mediaId;
                });
            });
        });
    });
};

export const selectMediaById = (encounters: IEncounter[], mediaId: string) => {
    // find media in encounters -> studies -> series -> medias and return that media
    return encounters
        .map((encounter) => encounter.studies)
        .flat()
        .map((study) => study.series)
        .flat()
        .map((series) => series.medias)
        .flat()
        .find((media) => media.uuid === mediaId);
};

export const { selectAnalysisFovea, resetState } = viewerSlice.actions;

export default viewerSlice.reducer;
