import React, { useEffect, useRef, useState, memo, useMemo } from "react";
import Swipe from "react-easy-swipe";
import klass from "./cssClasses";
import "./styles/main.scss";
import "./carousel.scss";
import { figureComponent, figureFlavor } from "../../utils/MediaUtils";
import { IEncounter, IMedia } from "../studies/studiesSlice";
import { Description as DescriptionIcon } from "@mui/icons-material";
import { Avatar, Box, Typography } from "@mui/material";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import DisplayText from "../../components/DisplayText/DisplayText";

interface CarouselProps {
    encounter: IEncounter;
    selectedStudyId: string;
    onClick: (studyId: string) => void;
}

const Carousel: React.FC<CarouselProps> = ({ encounter, selectedStudyId, onClick }) => {
    const medias = useMemo(() => {
        // return medias from encounter -> studies -> series -> medias
        const medias = encounter.studies.flatMap((study) => study.series?.flatMap((series) => series.medias));

        return medias.sort((a: any, b: any) => {
            const aFlavor = figureFlavor(a);
            const bFlavor = figureFlavor(b);

            // Compare by flavor.
            const flavorComparison = aFlavor!.localeCompare(bFlavor!);

            // If flavors are the same, compare by laterality.
            if (flavorComparison === 0) {
                return a.laterality.localeCompare(b.laterality);
            }

            return flavorComparison;
        });
    }, [encounter]);

    const [selectedItem, setSelectedItem] = useState<number>(0);
    const [firstItem, setFirstItem] = useState<number>(0);
    const [itemSize, setItemSize] = useState<number>(0);
    const [visibleItems, setVisibleItems] = useState<number>(0);
    const [lastPosition, setLastPosition] = useState<number>(0);
    const [swiping, setSwiping] = useState<boolean>(false);

    const itemsWrapperRef = useRef<HTMLDivElement>();
    const itemsListRef = useRef<HTMLUListElement>();
    const thumbsRef = useRef<HTMLLIElement[]>([]);

    const outerWidth = (el: HTMLLIElement) => {
        let width = el.offsetWidth;
        const style = getComputedStyle(el);
        width += parseInt(style.marginLeft) + parseInt(style.marginRight);
        return width;
    };

    const handleOnPressKey = ({ key }: { key: string }) => {
        if (key === "ArrowRight") {
            if (selectedItem !== medias.length - 1) {
                onClick(medias[selectedItem + 1].uuid);
            }
        }
        if (key === "ArrowLeft") {
            if (selectedItem !== 0) {
                onClick(medias[selectedItem - 1].uuid);
            }
        }
    };
    useEffect(() => {
        const total = medias ? medias.length : 0;
        const wrapperSize = itemsWrapperRef.current ? itemsWrapperRef.current.clientWidth : 0;
        const showArrows = visibleItems < total;
        const itemSelect = medias ? medias.findIndex((media) => media.uuid === selectedStudyId) : 0;
        setSelectedItem(itemSelect);
        setItemSize(outerWidth(thumbsRef.current[0]));
        setLastPosition(showArrows ? total - visibleItems : 0);
        if (itemSize) setVisibleItems(Math.floor(wrapperSize / itemSize));
    }, [itemSize, selectedItem, encounter, visibleItems, selectedStudyId]);

    useEffect(() => {
        let first = selectedItem;

        if (selectedItem >= lastPosition) {
            first = lastPosition;
        }

        if (selectedItem < firstItem + visibleItems) {
            first = firstItem;
        }

        setFirstItem(first);
    }, [firstItem, lastPosition, selectedItem, visibleItems]);

    useEffect(() => {
        window.addEventListener("keydown", handleOnPressKey);
        return () => {
            window.removeEventListener("keydown", handleOnPressKey);
        };
    }, [selectedItem]);

    const setItemsWrapperRef = (node: HTMLDivElement) => {
        itemsWrapperRef.current = node;
    };

    const setItemsListRef = (node: HTMLUListElement) => {
        itemsListRef.current = node;
    };

    const slideRight = (positions?: number) => {
        moveTo(firstItem - (typeof positions === "number" ? positions : 1));
    };

    const slideLeft = (positions?: number) => {
        moveTo(firstItem + (typeof positions === "number" ? positions : 1));
    };

    const moveTo = (position: number) => {
        // position can't be lower than 0
        position = position < 0 ? 0 : position;
        // position can't be higher than last postion
        position = position >= lastPosition ? lastPosition : position;
        setFirstItem(position);
    };

    const setThumbsRef = (node: HTMLLIElement) => {
        thumbsRef.current.push(node);
    };

    const handleClickItem = (media: IMedia) => {
        onClick(media.uuid);
    };

    const onSwipeMove = (delta: { x: number; y: number }) => {
        let deltaX = delta.x;
        if (!itemSize || !itemsWrapperRef.current || !visibleItems) {
            return false;
        }
        const leftBoundary = 0;
        const childrenLength = medias?.length || 0;

        const currentPosition = -(firstItem * 100) / visibleItems;
        const lastLeftItem = Math.max(childrenLength - visibleItems, 0);
        const lastLeftBoundary = (-lastLeftItem * 100) / visibleItems;

        // prevent user from swiping left out of boundaries
        if (currentPosition === leftBoundary && deltaX > 0) {
            deltaX = 0;
        }

        // prevent user from swiping right out of boundaries
        if (currentPosition === lastLeftBoundary && deltaX < 0) {
            deltaX = 0;
        }

        const wrapperSize = itemsWrapperRef.current?.clientWidth || 0;
        const position = currentPosition + 100 / (wrapperSize / deltaX);
        // if 3d isn't available we will use left to move
        if (itemsListRef.current) {
            ["WebkitTransform", "MozTransform", "MsTransform", "OTransform", "transform", "msTransform"].forEach(
                (prop) => {
                    itemsListRef.current!.style[prop as any] = CSSTranslate(position, "%", "horizontal");
                }
            );
        }

        return true;
    };

    const onSwipeStart = () => {
        setSwiping(true);
    };

    const onSwipeEnd = () => {
        setSwiping(false);
    };

    const CSSTranslate = (position: number, metric: "px" | "%", axis: "horizontal" | "vertical") => {
        const positionPercent = position === 0 ? position : position + metric;
        const positionCss = axis === "horizontal" ? [positionPercent, 0, 0] : [0, positionPercent, 0];
        const transitionProp = "translate3d";
        const translatedPosition = "(" + positionCss.join(",") + ")";
        return transitionProp + translatedPosition;
    };

    const hasPrev = firstItem > 0;
    const hasNext = firstItem < lastPosition;
    const currentPosition = -firstItem * (itemSize || 0);
    const transformProp = CSSTranslate(currentPosition, "px", "horizontal");
    const transitionTime = 350 + "ms";
    const itemListStyles = {
        WebkitTransform: transformProp,
        MozTransform: transformProp,
        MsTransform: transformProp,
        OTransform: transformProp,
        transform: transformProp,
        msTransform: transformProp,
        WebkitTransitionDuration: transitionTime,
        MozTransitionDuration: transitionTime,
        MsTransitionDuration: transitionTime,
        OTransitionDuration: transitionTime,
        transitionDuration: transitionTime,
        msTransitionDuration: transitionTime,
    };

    const StudiesThumbnails = medias.map((media, index: number) => {
        const itemClass = klass.ITEM(false, index === selectedItem);
        const size = "60px";

        const thumbProps = {
            "key": index,
            "ref": (el: HTMLLIElement) => setThumbsRef(el),
            "className": itemClass,
            "aria-label": `${media.fileName} ${index + 1}`,
            "style": { width: size, height: size, cursor: "pointer" },
        };

        const renderThumbnail = (componentType: string) => {
            switch (componentType) {
                case "img":
                    return (
                        <>
                            <Avatar
                                aria-label="label"
                                sx={{
                                    backgroundColor: "grey",
                                    position: "absolute",
                                    marginTop: "4px",
                                    marginLeft: "4px",
                                    width: "20px",
                                    height: "20px",
                                }}
                                variant="rounded"
                            >
                                <Typography sx={{ fontWeight: 1000, fontSize: "10px" }}>{media.laterality}</Typography>
                            </Avatar>
                            <img alt={media.fileName} src={src as string} {...elProps} />
                        </>
                    );
                case "video":
                    return (
                        <>
                            {" "}
                            <Avatar
                                aria-label="label"
                                sx={{
                                    backgroundColor: "grey",
                                    position: "absolute",
                                    marginTop: "4px",
                                    marginLeft: "4px",
                                    width: "20px",
                                    height: "20px",
                                }}
                                variant="rounded"
                            >
                                <Typography sx={{ fontWeight: 1000, fontSize: "10px" }}>{media.laterality}</Typography>
                            </Avatar>
                            <video src={src as string} {...elProps} />{" "}
                        </>
                    );
                case "iframe":
                    return (
                        <Box
                            sx={{
                                display: "flex",
                                height: "100%",
                                justifyContent: "center",
                                alignItems: "center",
                                flexDirection: "column",
                            }}
                        >
                            <DescriptionIcon sx={{ width: "30px", height: "30px" }} />
                            <Typography sx={{ fontSize: "10px" }}>PDF</Typography>
                        </Box>
                    );
                case "attachment":
                    return (
                        <Box
                            sx={{
                                display: "flex",
                                height: "100%",
                                justifyContent: "center",
                                alignItems: "center",
                                flexDirection: "column",
                            }}
                        >
                            <AttachFileIcon />
                            <DisplayText type="bodyXSmall" text={media.fileName.split(".").at(-1) || ""} />
                        </Box>
                    );
            }
        };

        const src = media.mediaUrl;
        const component = figureComponent(media);

        const elProps = {
            style: { width: size, height: size },
        };
        const divProps = {
            style: { textAlign: "center" as "center" },
        };

        const handleClick = () => handleClickItem(media);

        if (!component || !src) return null;

        return (
            // @ts-ignore
            <li {...thumbProps} onClick={handleClick} tabIndex={0}>
                {renderThumbnail(component)}
            </li>
        );
    });

    return (
        <div className={klass.CAROUSEL(false)}>
            {/* @ts-ignore */}
            <div className={klass.WRAPPER(false)} ref={setItemsWrapperRef}>
                <button type="button" className={klass.ARROW_PREV(!hasPrev)} onClick={() => slideRight()} />
                <Swipe
                    tagName="ul"
                    className={klass.SLIDER(false, swiping)}
                    onSwipeLeft={slideLeft}
                    onSwipeRight={slideRight}
                    onSwipeMove={onSwipeMove}
                    onSwipeStart={onSwipeStart}
                    onSwipeEnd={onSwipeEnd}
                    style={itemListStyles}
                    innerRef={setItemsListRef}
                    allowMouseEvents={true}
                >
                    {StudiesThumbnails}
                </Swipe>
                <button type="button" className={klass.ARROW_NEXT(!hasNext)} onClick={() => slideLeft()} />
            </div>
        </div>
    );
};

export default memo(Carousel);
