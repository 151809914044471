import React from "react";
import { useGetTextFileQuery } from "../../features/media/dataAccess";
import DisplayText from "../DisplayText/DisplayText";
import { Skeleton } from "@mui/material";
import { Box } from "@mui/system";

interface ITextFromFileProps {
    url: string;
}

const TextFromFile: React.FC<ITextFromFileProps> = ({ url }) => {
    const { data: textFile } = useGetTextFileQuery(url);

    if (!textFile) {
        return (
            <Box sx={{ display: "flex", flexDirection: "column", gap: 5 }}>
                <Skeleton variant="text" width="40%" />
                <Skeleton variant="text" width="80%" height={20} />
                <Skeleton variant="text" width="80%" height={20} />
                <Skeleton variant="text" width="70%" height={20} />
                <Skeleton variant="text" width="60%" height={20} />
                <Skeleton variant="text" width="80%" height={20} />
            </Box>
        );
    }

    return <DisplayText style={{ whiteSpace: "pre-line" }} type="bodyMedium" text={textFile} />;
};

export default TextFromFile;
