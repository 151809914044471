import React, { useEffect, useState } from "react";
import ToolTip from "../../../components/Tooltip/Tooltip";
import { Box, CircularProgress, IconButton, LinearProgress, Typography } from "@mui/material";
import { Check as CheckIcon, HighlightOff as HighlightOffIcon, ReportGmailerrorredRounded } from "@mui/icons-material/";
import { useDispatch, useSelector } from "react-redux";
import { cancelFileEntriesUpload, FileEntryStatus, IFileEntry, selectFileEntryById } from "../uploadSlice";
import { RootState } from "../../../app/store";
import useTimerHook from "../../../hooks/useTimerHook/useTimerHook";

interface FileEntryProps {
    fileEntryId: string;
}

const FileEntry: React.FC<FileEntryProps> = ({ fileEntryId }) => {
    const fileEntry = useSelector((state: RootState) => selectFileEntryById(state, fileEntryId)) as IFileEntry;
    const { timer } = useTimerHook();
    const dispatch = useDispatch();

    const [progress, setProgress] = useState<number>(fileEntry.status === FileEntryStatus.PROCESSED ? 100 : 0);

    useEffect(() => {
        timer.updateExpiredTime();

        if (fileEntry.progress) {
            setProgress(fileEntry.progress);
        }
    }, [fileEntry.progress]);

    const handleRemoveFileEntry = () => {
        dispatch(cancelFileEntriesUpload([fileEntryId]));
    };

    const renderFileEntryStatus = () => {
        if (fileEntry.status === FileEntryStatus.UPLOADING || fileEntry.status === FileEntryStatus.QUEUED) {
            return (
                <IconButton onClick={handleRemoveFileEntry} aria-label="delete" size="small">
                    <HighlightOffIcon color="error" fontSize="inherit" />
                </IconButton>
            );
        } else if (fileEntry.status === FileEntryStatus.PROCESSING) {
            return (
                <Box sx={{ display: "flex", alignItems: "center" }}>
                    <CircularProgress size={15} />
                    <Typography sx={{ ml: 8 }}>Processing...</Typography>
                </Box>
            );
        } else if (fileEntry.status === FileEntryStatus.PROCESSED) {
            return (
                <IconButton disabled aria-label="success" size="small">
                    <CheckIcon color="success" fontSize="inherit" />
                </IconButton>
            );
        }
    };

    const renderErrorMessage = (fileEntry: IFileEntry) => {
        if (!fileEntry.errorMessage) return "";

        return (
            <>
                {fileEntry.errorMessage.map((error: string) => (
                    <p>{error}</p>
                ))}
            </>
        );
    };

    if (!fileEntry) return null;

    return (
        <Box
            data-test-id={`upload:fileEntry-${fileEntry.file.name}`}
            sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                height: 28,
                alignItems: "center",
            }}
        >
            <Box sx={{ flex: "0 0 300px", overflow: "hidden" }}>
                <ToolTip content={`${fileEntry.file.name} - ${fileEntry.size} bytes`}>
                    <Typography
                        sx={{
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                        }}
                    >
                        {fileEntry.file.name} - {fileEntry.size} bytes
                    </Typography>
                </ToolTip>
            </Box>
            {fileEntry.status === FileEntryStatus.FAILED ? (
                <Box sx={{ flex: "0 0 130px", display: "flex", justifyContent: "center", cursor: "pointer" }}>
                    <ToolTip content={renderErrorMessage(fileEntry)}>
                        <ReportGmailerrorredRounded color="error" fontSize="small" />
                    </ToolTip>
                </Box>
            ) : (
                <>
                    <Box sx={{ flex: 1, margin: "0 10px" }}>
                        <LinearProgress
                            variant="determinate"
                            color={fileEntry.status === FileEntryStatus.PROCESSED ? "success" : "primary"}
                            value={progress}
                        />
                    </Box>
                    <Box sx={{ flex: "0 0 130px", display: "flex", justifyContent: "center" }}>
                        {renderFileEntryStatus()}
                    </Box>
                </>
            )}
        </Box>
    );
};

export default FileEntry;
