// From a collection return an array of unique objects based on a key
export const getUniqueObjects = <T extends Record<string, any>>(collection: T[], key: keyof T) => {
    return collection.reduce((acc: T[], item: T) => {
        const found = acc.find((accItem: any) => accItem[key] === item[key]);
        if (!found) {
            acc.push(item);
        }
        return acc;
    }, []);
};

export const getNestedProperty = (obj: any, path: string): any => {
    return path.split(".").reduce((acc: any, key: string) => acc?.[key], obj);
};
