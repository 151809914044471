import { serviceSlice } from "../../app/serviceSlice";
import { IUser } from "./login/loginSlice";
import { authService } from "./nebula.port";

const extendedApiSlice = serviceSlice.enhanceEndpoints({ addTagTypes: ["USER_DATA"] }).injectEndpoints({
    endpoints: (builder) => ({
        getUserData: builder.query<IUser, void>({
            query: () => authService.getUserData,
            providesTags: ["USER_DATA"],
        }),
        recoverPassword: builder.mutation<void, string>({
            query: (email) => () => authService.recoverPassword(email),
        }),
        validateToken: builder.mutation<void, string>({
            query: (token) => () => authService.validateToken(token),
        }),
        resetPassword: builder.mutation<void, { token: string; newPassword: string }>({
            query:
                ({ token, newPassword }) =>
                () =>
                    authService.resetPassword(token, newPassword),
        }),
    }),
});

export const { useGetUserDataQuery, useValidateTokenMutation, useResetPasswordMutation, useRecoverPasswordMutation } =
    extendedApiSlice;
