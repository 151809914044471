import { Link } from "@mui/material";
import DisplayText from "../DisplayText/DisplayText";

const text = `Copyright © Voxeleron ${new Date().getFullYear()}`;

const Copyright = () => (
    <Link id="copyrightEl" color="inherit" href="https://voxeleron.com/">
        <DisplayText style={{ textAlign: "center" }} type="bodyXSmall" text={text} />
    </Link>
);

export default Copyright;
