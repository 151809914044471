import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { serviceSlice } from "../../app/serviceSlice";
import { getSelectedTrial } from "../auth/login/loginSlice";

export interface Tag {
    label: string;
    uuid: string;
    color: string;
}
export interface IPatient {
    isQc: boolean;
    earliestISODate: string;
    dateOfBirth: string;
    lastUploadedOnIso: string;
    gender: string;
    group: string;
    label: string;
    latestISODate: string;
    siteName: string;
    patientId: string;
    firstName?: string;
    lastName?: string;
    uuid: string;
    site: string;
    visits: number;
    tags: Tag[];
    workflowStates: {
        label: string;
        value: string;
        priority?: string;
        order?: number;
    }[];
}

export interface PatientsState {
    allPatientsList: IPatient[];
    patientsList: IPatient[];
    searchQry?: string;
    sort: { field: keyof IPatient; order: string };
    status: "idle" | "success" | "loading" | "failed";
    errorMessage?: string;
    currentPatient?: string;
}

const LOCAL_STORAGE_SORT_KEY = "sortKey";

const getSortOrderFromLocalStorage = () => {
    const sort = localStorage.getItem(LOCAL_STORAGE_SORT_KEY);

    if (!sort) return { field: "patientName", order: "desc" };

    return JSON.parse(sort);
};
export const getInitialState: () => PatientsState = () => ({
    allPatientsList: [],
    patientsList: [],
    sort: getSortOrderFromLocalStorage(),
    status: "loading",
});

export const patientsSlice = createSlice({
    name: "patients",
    initialState: getInitialState,
    reducers: {
        selectPatient: (state, action: PayloadAction<{ currentPatientId: string }>) => {
            state.currentPatient = action.payload.currentPatientId;
        },
        deselectPatient: (state) => {
            state.currentPatient = undefined;
        },
        sortPatients: (state, action: PayloadAction<any>) => {
            const newSortConfig = { field: action.payload.field, order: action.payload.order };
            localStorage.setItem(LOCAL_STORAGE_SORT_KEY, JSON.stringify(newSortConfig));
            state.sort = newSortConfig;
        },
    },
});

export const { selectPatient, sortPatients, deselectPatient } = patientsSlice.actions;

export const selectPatients = (state: RootState) => {
    return state.patients;
};

export const selectPatientSort = (state: RootState) => {
    return state.patients.sort;
};

export const selectCurrentPatient = createSelector(
    [
        (state: RootState) => state.patients.currentPatient,
        (state: RootState) => getSelectedTrial(state),
        (state: RootState) => state, // Pass the whole state as the third argument
    ],
    (currentPatient, trial, state) => {
        if (!currentPatient || !trial) return null;
        // @ts-ignore
        const currentPatients = serviceSlice.endpoints.getPatients.select(trial.uuid)(state);
        if (!currentPatients?.data) return null;
        return currentPatients.data.find((patient: IPatient) => patient.uuid === currentPatient);
    }
);

export default patientsSlice.reducer;
