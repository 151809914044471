import { createEntityAdapter, createSlice, EntityState, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { Laterality } from "../crf/nebula.service";
import { StudyDto } from "./nebula.port";

export interface IImagingProcedure {
    orderDate: string;
    encounterUuid: string;
    encounterIdentifier: string;
    visit?: string;
    visitUuid: string;
    procedureIdentifier: string;
    site: string;
    trialName: string;
    studyUid: string;
    identifierType: string;
    reason: string;
    uuid: string;
}
export interface MimeType {
    label: string;
    prettyLabel: string;
}

// //////////////////////////////////////////
//  ####   #####   ######
// #    #  #    #  #
// #       #    #  #####
// #       #####   #
// #    #  #   #   #
//  ####   #    #  #
// //////////////////////////////////////////

type ReaderId = string;
export type EyeLaterality = "os" | "od";

export interface FieldOption {
    label: string;
    value?: number | string | null | undefined;
    type?: "input";
    unit?: string;
    inputType?: "number" | "float" | "string";
}

export interface CRFRow {
    fieldName: string;
    fieldValue: number | string | null | undefined;
}

export interface CRFField {
    fieldName: string;
    description: string;
    fieldType: "List" | "String";
    isEditable?: boolean;
    fieldOptions?: FieldOption[];
}

type CRFReaderData = {
    os: CRFRow[];
    od: CRFRow[];
};

export type StudyCRF = Record<ReaderId, CRFReaderData>;

export type UserCRF = {
    formUri: string;
    encounterUuid: string;
    encounterIdentifier: string;
    uuid: string;
    studyUuid: string;
    isReadOnly: boolean;
    userName: string;
    updatedOn: string;
    identifierType: string;
    encounterDate: string;
    identifierUuid: string;
    os: CRFRow[];
    od: CRFRow[];
};

export type CRFStudyData = {
    fields: CRFField[];
    crfs: UserCRF[];
};

export interface ICRFData {
    crfs: UserCRF[];
    fields: CRFField[];
}
// /////////////////////////////////////////////////////////

export interface Analysis {
    uuid: string;
    updatedBy: {
        email: string;
        uuid: string;
    };
    mediaId: string;
    updatedOn: string;
}

export interface Attachment {
    date: string;
    fileName: string;
    fileSize: number;
    isActive: boolean;
    kind: string;
    mimeType: string;
    mediaUuid: string;
    userUuid: string;
    userEmail: string;
}
export interface IMediaOctFile {
    attachments: Attachment[];
    mediaDateAndTime: string;
    originalDob: string;
    originalPatientId: string;
    originalPatientFirstName: string;
    originalPatientLastName: string;
    trialName: string;
    fullUploadPath: string;
    uuid: string;
    mimeType: MimeType;
    ScanDateAndTime: string;
    ScanPattern: string;
    Laterality: string;
    Manufacturer: string;
    Device: string;
    FileName: string;
    ImageQuality: number;
    ImageQualityDisplayText: string;
    RasterAngleInDeg: number;
    FirstBscanStartXInMm: number;
    FirstBscanStartYInMm: number;
    LastBscanStartXInMm: number;
    LastBscanStartYInMm: number;
    BscanHeightDim: number;
    NumSlices: number;
    BscanWidthSizeInMicrons: number;
    BscanHeightSizeInMicrons: number;
    NumSlicesSizeInMicrons: number;
    UniqueId: string;
    SiteId: string;
    SeriesId: number;
    Comment: string;
    visit: string;
    ThumbNailFileName: string;
}

export interface IEncounter {
    uuid: string;
    studies: IStudy[];
    visitName: string;
    visitId: string;
    comments: IEncounterComment[];
    encounterDate: Date;
}

export interface IStudy {
    uuid: string;
    visitId: string;
    patientId: string;
    siteId: string;
    encounterId: string;
    series: ISerie[];
    createdOn: Date;
    updatedOn: Date;
}

export interface ISerie {
    uuid: string;
    medias: IMedia[];
    createdOn: Date;
    updatedOn: Date;
    hidden?: boolean;
}

export interface MediaComment {
    uuid: string;
    comment: string;
    userEmail: string;
    createdAt: Date;
    updatedAt: Date;
    version: number;
}

export interface IMedia {
    comments: MediaComment[];
    notes: string;
    enfaceUrl: string;
    heidelbergSeriesId: string | null;
    slicesUrl: string;
    fullUploadPath: any;
    fileName: string;
    uuid: string;
    category: MediaCategory;
    mimeType: string;
    mediaUrl: string;
    laterality: Laterality;
    hasBeenRead: boolean;
    metadata: object;
    updatedOn: Date;
    dicomData: Record<string, any>;
    hidden?: boolean;
}

export interface IEncounterComment {
    uuid: string;
    patientId: string;
    encounterId: string;
    userEmail: string;
    createdAt: Date;
    updatedAt: Date;
    comment: string;
    version: number;
}

export type MediaCategory = "original" | "raw" | "voxDicom" | "derived" | "attached" | "analysis";

export interface IStudy {
    imagingProcedure: IImagingProcedure;
    mediaOctFile: IMediaOctFile[];
}

const studiesAdapter = createEntityAdapter<IStudy>({
    selectId: (study) => study.imagingProcedure.uuid,
});

export interface StudyState {
    studyList: EntityState<IStudy>;
    showHidden: boolean;
    currentStudy: IStudy | null;
    errorMessage?: string;
    token?: string;
}

export const initialState: StudyState = {
    studyList: studiesAdapter.getInitialState(),
    currentStudy: null,
    showHidden: false,
};

export const studySlice = createSlice({
    name: "studies",
    initialState,
    reducers: {
        studySelected: (state, action: PayloadAction<IStudy>) => {
            state.currentStudy = action.payload;
        },
        toggleShowHidden: (state) => {
            state.showHidden = !state.showHidden;
        },
    },
});

export const selectShowHidden = (state: RootState) => state.studies.showHidden;

export const selectStudies = (state: RootState) => {
    return state.studies;
};

export const { selectById: selectStudyById, selectAll: selectAllStudies } = studiesAdapter.getSelectors<RootState>(
    (state) => state.studies.studyList
);

export const { studySelected, toggleShowHidden } = studySlice.actions;

export default studySlice.reducer;
