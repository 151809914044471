import React, { useMemo } from "react";
import DisplayText from "../../components/DisplayText/DisplayText";
import { Button, Dialog, DialogActions, DialogContent, CircularProgress, IconButton, Divider } from "@mui/material";
import { useGetSiteUsersQuery } from "./dataAccess";
import { Box } from "@mui/system";
import { IUser } from "../auth/login/loginSlice";
import { ContentCopy as ContentCopyIcon } from "@mui/icons-material";
import ToolTip from "../../components/Tooltip/Tooltip";
import { figureFlavor } from "../../utils/MediaUtils";
import { Stringifiable } from "query-string";

interface ICopyCaseInfoProps {
    isOpen: boolean;
    study: any;
    onClose: () => void;
    visitName: string;
    visitId: string;
    siteName: string;
    patientLocalId: string;
    patientId: string;
}

const CopyCaseInfo: React.FC<ICopyCaseInfoProps> = ({
    isOpen,
    study,
    visitName,
    visitId,
    siteName,
    onClose,
    patientId,
    patientLocalId,
}) => {
    const { data: siteUsers, isLoading: siteUsersIsLoading } = useGetSiteUsersQuery(study.uuid, {
        refetchOnMountOrArgChange: true,
        skip: !isOpen,
    });

    const queryType = useMemo(() => {
        const flavor = figureFlavor(study);

        const isOct = flavor === "oct-mp4" || flavor === "oct-photo";

        if (isOct) {
            return "oct";
        }

        return "other";
    }, [study]);

    const mailList = useMemo(() => {
        if (!siteUsers) return "";

        const usersMails = siteUsers.map((user: IUser) => user.email);

        return usersMails.join(", ");
    }, [siteUsers]);

    const parseDate = (date: string): string => {
        if (date.length !== 8) {
            throw new Error("Invalid date format");
        }

        const year = date.substring(0, 4);
        const month = date.substring(4, 6);
        const day = date.substring(6, 8);

        return `${year}-${month}-${day}`;
    };

    const dialogContent = useMemo(() => {
        if (!study || !mailList) {
            return {
                to: "",
                subject: "",
                body: "",
            };
        }

        return {
            to: mailList,
            subject: `iNebula Query Notification - ${
                study!.dicomData?.clinicalTrialProtocolName
            } - ${siteName} - ${patientLocalId} - ${parseDate(study!.dicomData?.studyDate)} - ${study.fileName} - ${
                study.laterality
            }`,
            body: `Dear team member,

This email is to notify you that the following case was queried and returned to your work state for correction:

Protocol: ${study?.dicomData?.clinicalTrialProtocolName || "-"}
Site #: ${siteName}
Visit: ${visitName}
Subject ID: ${patientLocalId}
Visit Date: ${study!.dicomData?.studyDate ? parseDate(study!.dicomData?.studyDate) : "-"}
Visit location in iNebula: ${window.location.origin}/patients?selectedPatient=${patientId}&selectedVisit=${visitId}

Reason:

Kindly respond to this email and return the corrected case in Nebula within 3 business days. Upon re-uploading the new case to Nebula, append “New” to the naming convention:

E.g. if responding to ${queryType === "oct" ? "an OCT image query" : "this query"}, the new folder will reflect:

${study!.fullUploadPath.split(".")?.[0] || ""}_New.${study!.fullUploadPath.split(".")?.[1] || ""}

After re-uploading has been completed, “reply all” to this email to notify all site personnel and the PM.

Sincerely,`,
        };
    }, [study, siteUsers]);

    const handleCopyEmailSubject = () => {
        navigator.clipboard.writeText(dialogContent.subject);
    };

    const handleCopyEmailTo = () => {
        navigator.clipboard.writeText(dialogContent.to);
    };

    const handleCopyEmailBody = () => {
        navigator.clipboard.writeText(dialogContent.body);
    };

    if (!isOpen) return null;

    return (
        <Dialog maxWidth="lg" fullWidth open={true} onClose={onClose}>
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", p: 24, pb: 0 }}>
                <DisplayText text="Email template" type="h5" />
            </Box>
            <DialogContent>
                {siteUsersIsLoading ? (
                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <CircularProgress />
                    </Box>
                ) : (
                    <>
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: 10,
                                justifyContent: "space-between",
                                my: 6,
                            }}
                        >
                            <p>
                                <span style={{ fontWeight: "bold" }}>To: </span>
                                <span>{dialogContent.to}</span>
                            </p>
                            <ToolTip content={`Copy email list`}>
                                <IconButton onClick={handleCopyEmailTo} size="small">
                                    <ContentCopyIcon />
                                </IconButton>
                            </ToolTip>
                        </Box>
                        <Divider />
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: 10,
                                justifyContent: "space-between",
                                my: 6,
                            }}
                        >
                            <p>
                                <span style={{ fontWeight: "bold" }}>Subject: </span>
                                <span>{dialogContent.subject}</span>
                            </p>
                            <ToolTip content={`Copy email subject`}>
                                <IconButton onClick={handleCopyEmailSubject} size="small">
                                    <ContentCopyIcon />
                                </IconButton>
                            </ToolTip>
                        </Box>
                        <Divider />
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "start",
                                gap: 10,
                                justifyContent: "space-between",
                                mt: 16,
                            }}
                        >
                            <p style={{ whiteSpace: "pre-line" }}>{dialogContent.body}</p>
                            <ToolTip content={`Copy email body`}>
                                <IconButton onClick={handleCopyEmailBody} size="small">
                                    <ContentCopyIcon />
                                </IconButton>
                            </ToolTip>
                        </Box>
                    </>
                )}
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={onClose}>
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default CopyCaseInfo;
