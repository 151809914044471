export class Formatter {
    public static deIdentify(s: string): string {
        if (!s) return "";
        const names = ["biogen", "darc", "octhealth", "oct health"];
        let result = s;
        names.forEach((n) => {
            const s2 = s.toLowerCase();
            const i = s2.indexOf(n);
            if (i >= 0) {
                const m = s.substr(i, n.length);
                result = s.replace(m, "");
            }
        });
        return result;
    }

    public static date(dt: string, asUtc: boolean = false): string {
        if (!dt) return "";
        if (asUtc) {
            const t = dt.indexOf("T");
            return dt.substr(0, t);
        } else {
            const zp00 = (v: number) => {
                v = v + 100;
                return v.toString(10).substring(1);
            };
            const d = Date.parse(dt);
            const d2 = new Date(d);
            return `${d2.getFullYear()}-${zp00(d2.getMonth() + 1)}-${zp00(d2.getDate())}`;
        }
    }

    public static dateTime(dt: string, asUtc: boolean = false): string {
        if (!dt) return "";
        if (asUtc) {
            let res = dt.replace("T", " ").replace("Z", "");
            let dot = res.indexOf(".");
            if (dot >= 0) {
                res = res.substr(0, dot);
            }
            return res;
        } else {
            const zp00 = (v: number) => {
                v = v + 100;
                return v.toString(10).substring(1);
            };
            const d = Date.parse(dt);
            const d2 = new Date(d);
            const localTime = d2.toLocaleTimeString();
            return `${d2.getFullYear()}-${zp00(d2.getMonth() + 1)}-${zp00(d2.getDate())} ${localTime}`;
        }
    }

    public static calculateAge(dt: string): number {
        if (!dt) return 0;
        var today = new Date();
        var birthDate = new Date(dt);
        var age = today.getFullYear() - birthDate.getFullYear();
        var m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }
        return age;
    }

    public static decode(s: string): string {
        if (!s) {
            return "";
        }
        return decodeURIComponent(s);
    }

    public static dicom2CommaBasedName(d: string): string {
        if (!d) return "";
        if (d === "^") return "";
        const s = d.split("^");
        if (s.length < 2) return d;
        let comma = s[0] && s[1] ? ", " : "";
        let res = "";
        let c = 0;
        s.forEach((p) => {
            res = res + p;
            if (c === 0) {
                res = res + comma;
            } else {
                res = res + " ";
            }
            c++;
        });
        return res;
    }

    public static numString(n?: number): string {
        if (!n) return "no";
        if (n === 1) return "one";
        if (n === 2) return "two";
        return n.toString(10);
    }

    public static octQualityNumber(qs: string, v: number | string, showRaw = false): string {
        let v2: number;
        let s;
        if (typeof v === "string") {
            v2 = Number.parseFloat(v as string);
        } else {
            v2 = v as number;
        }
        if (v2 <= 1) {
            v2 = v2 * 10;
            s = `${Math.ceil(v2)}`;
        } else {
            v2 = Math.ceil(v2);
            s = `${v2}`;
            s = s.substr(0, 2);
        }
        if (showRaw) {
            return `${qs} (${s})`;
        } else return qs;
    }

    public static preBlank = (s: string, w: number) => {
        const len = s.length;
        const diff = w - len;
        let pre = "";
        for (let i = 0; i < diff; i++) {
            pre = pre + "";
        }
        return pre + s;
    };

    public static lastPath(p: string): string {
        if (!p) return "";
        const sp = p.split("/");
        if (sp.length > 1) {
            let last = sp[sp.length - 1];
            // Sometimes the last is a null after the /name1/name2/ with the last /
            if (!last) last = sp[sp.length - 2];
            return Formatter.decode(last);
        } else {
            return Formatter.decode(p);
        }
    }

    public static pluralize = (n: number, singular: string, pluralized: string = ""): string => {
        const p2 = pluralized || singular + "s";
        if (n >= -1 && n < 2) {
            return singular;
        } else {
            return p2;
        }
    };

    public static stringList = (list: string[], delim: string): string => {
        let res = "";
        if (list.length === 0) return res;
        if (list.length === 1) return list[0];
        const last = list.length - 1;
        list.forEach((item, index) => {
            if (index === last) {
                res = res + item;
            } else {
                res = res + item + delim + " ";
            }
        });
        return res;
    };

    public static truncate = (n: number, decimalPlaces: number = 0): number => {
        const factor = decimalPlaces ? decimalPlaces * 10 : 1;
        return Math.ceil(n * factor) / factor;
    };
}
