import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { updateDerivedFile } from "../../uploadSlice";
import { Box } from "@mui/system";
import { ClickAwayListener, IconButton, TextField, Typography } from "@mui/material";
import { Edit as EditIcon } from "@mui/icons-material";

interface IEditableInputProps {
    value: string;
    uuid: string;
    attributeName: string;
    editable?: boolean;
}

const EditableInput: React.FC<IEditableInputProps> = ({ value, uuid, attributeName, editable = true }) => {
    const dispatch = useDispatch();
    const [isEditableVisible, setIsEditableVisible] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [editedValue, setEditedValue] = useState(value);

    const handleHover = () => setIsEditableVisible(true);

    const handleMouseLeave = () => setIsEditableVisible(false);

    const handleEdit = () => {
        setIsEditing(true);
    };

    const handleFinishEdit = () => setIsEditing(false);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEditedValue(e.target.value);
    };

    const submitNewValue = () => {
        setIsEditing(false);
        dispatch(updateDerivedFile({ id: uuid, changes: { [attributeName]: editedValue } }));
    };

    const handleOnBlur = () => {
        submitNewValue();
    };

    if (!editable) {
        return (
            <Box sx={{ flex: 1, display: "flex", alignItems: "center" }}>
                <Typography>{value}</Typography>
            </Box>
        );
    }

    function handleKeyPress(e: React.KeyboardEvent<HTMLInputElement>) {
        // If user press enter
        if (e.key === "Enter") {
            return submitNewValue();
        } else if (e.key === "Esc" || e.key === "Escape") {
            return handleFinishEdit();
        }
    }

    return (
        <Box
            onMouseOver={handleHover}
            onMouseLeave={handleMouseLeave}
            sx={{ flex: 1, display: "flex", alignItems: "center" }}
        >
            {isEditing ? (
                <ClickAwayListener onClickAway={handleFinishEdit}>
                    <TextField
                        autoFocus
                        hiddenLabel
                        value={editedValue}
                        onChange={handleInputChange}
                        onBlur={handleOnBlur}
                        onKeyDown={handleKeyPress}
                        id="filled-hidden-label-small"
                        variant="filled"
                        size="small"
                    />
                </ClickAwayListener>
            ) : (
                <>
                    <Typography sx={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
                        {value}
                    </Typography>
                    {isEditableVisible ? (
                        <IconButton
                            onClick={handleEdit}
                            size="small"
                            color="primary"
                            aria-label="edit"
                            component="span"
                        >
                            <EditIcon fontSize="inherit" />
                        </IconButton>
                    ) : null}
                </>
            )}
        </Box>
    );
};

export default EditableInput;
