import { useState } from "react";

export enum FilterType {
    AND = "AND",
    OR = "OR",
}

export type FilterOption = {
    value: string;
    label: string;
};

export type FilterFunction = (element: any, filterOption: FilterOption) => boolean;

export type FilterGroup = {
    key: string;
    label: string;
    type: FilterType;
    error?: boolean;
    filterOptions: FilterOption[] | null;
    activeFilterOptions?: FilterOption[];
    onFilter: FilterFunction;
};

const useFilters = () => {
    const [activeFilters, setActiveFilters] = useState<Map<string, FilterGroup>>(new Map());

    const setFilterGroups = (filters: FilterGroup[]) => {
        const filtersMap = new Map<string, FilterGroup>();

        filters.forEach((filter) => {
            filtersMap.set(filter.key, filter);
        });

        setActiveFilters(filtersMap);
    };

    const addFilter = (filter: FilterGroup) => {
        setActiveFilters((prevFilters) => {
            const newFilters = new Map(prevFilters);
            newFilters.set(filter.key, filter);
            return newFilters;
        });
    };

    const updateFilterGroup = (filterKey: string, filter: Partial<FilterGroup>) => {
        setActiveFilters((prevFilters) => {
            const newFilters = new Map(prevFilters);
            const existingFilter = newFilters.get(filterKey);
            if (existingFilter) {
                const updatedFilter = { ...existingFilter, ...filter };
                newFilters.set(filterKey, updatedFilter);
            }
            return newFilters;
        });
    };

    const removeFilterGroup = (filterKey: string) => {
        setActiveFilters((prevFilters) => {
            const newFilters = new Map(prevFilters);
            newFilters.delete(filterKey);
            return newFilters;
        });
    };

    const clearFilterGroups = () => {
        setActiveFilters(new Map());
    };

    const validateAndSetFilterOptions = (
        filterGroup: FilterGroup,
        selectedOptionValues: FilterOption[]
    ): FilterGroup => {
        if (filterGroup.filterOptions) {
            const validOptions: FilterOption[] = [];
            const selectedValues: Set<string> = new Set();
            selectedOptionValues.forEach((selectedOption) => {
                if (!selectedValues.has(selectedOption.value)) {
                    const option = filterGroup.filterOptions!.find((option) => option.value === selectedOption.value);
                    if (option) {
                        validOptions.push(option);
                        selectedValues.add(selectedOption.value);
                    } else {
                        // eslint-disable-next-line no-console
                        console.error(
                            `Option with value "${selectedOption.value}" does not exist in filter "${filterGroup.key}"`
                        );
                    }
                }
            });
            filterGroup.activeFilterOptions = validOptions;
        }
        return filterGroup;
    };

    const updateActiveFilterOptions = (filterKey: string, selectedOptionValues: FilterOption[]) => {
        setActiveFilters((prevFilters) => {
            const newFilters = new Map(prevFilters);
            const filterGroup = newFilters.get(filterKey);

            if (filterGroup) {
                newFilters.set(filterKey, validateAndSetFilterOptions(filterGroup, selectedOptionValues));
            }

            return newFilters;
        });
    };

    const updateMultipleActiveFilterOptions = (filters: { [key: string]: FilterOption[] }) => {
        setActiveFilters((prevFilters) => {
            const newFilters = new Map(prevFilters);

            for (const filterKey in filters) {
                const filterGroup = newFilters.get(filterKey);

                if (filterGroup) {
                    newFilters.set(filterKey, validateAndSetFilterOptions(filterGroup, filters[filterKey]));
                }
            }

            return newFilters;
        });
    };

    const applyFilters = (elements: any[]): any[] => {
        let filteredElements = elements;

        activeFilters.forEach((filterGroup) => {
            const { activeFilterOptions, type, onFilter } = filterGroup;

            if (!activeFilterOptions || activeFilterOptions.length === 0) {
                return;
            }

            if (type === FilterType.AND) {
                filteredElements = filteredElements.filter((element) =>
                    activeFilterOptions.every((option) => onFilter(element, option))
                );
            } else if (type === FilterType.OR) {
                filteredElements = filteredElements.filter((element) =>
                    activeFilterOptions.some((option) => onFilter(element, option))
                );
            }
        });

        return filteredElements;
    };

    return {
        setFilters: setFilterGroups,
        addFilter,
        updateFilter: updateFilterGroup,
        removeFilter: removeFilterGroup,
        activeFilters,
        clearFilters: clearFilterGroups,
        updateActiveFilterOptions,
        updateMultipleActiveFilterOptions,
        applyFilters,
    };
};

export default useFilters;
