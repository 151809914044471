import React, { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Button, Dialog, DialogTitle, IconButton, Divider, TextField } from "@mui/material";
import { useSnackbar } from "notistack";
import { useAppSelector } from "../../app/hooks";
import { selectCurrentPatient } from "../patients/patientsSlice";
import { useLazyGetVisitStateQuery } from "../workflow/dataAccess";

interface WorkflowTransitionProps {
    onClose: () => void;
    transition: string;
    visitId: string;
    onTransition: (newComment: string) => Promise<void>;
}

const WorkflowTransition: React.FC<WorkflowTransitionProps> = ({ onClose, transition, onTransition, visitId }) => {
    const currentPatient = useAppSelector(selectCurrentPatient);

    const { enqueueSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState(false);
    const [transitionMessage, setTransitionMessage] = useState("");
    const [refetch] = useLazyGetVisitStateQuery();

    const handleTransition = async (e: any) => {
        e.preventDefault();
        const currentMessage = transitionMessage.trim();
        try {
            setLoading(true);

            setTransitionMessage("");
            await onTransition(transitionMessage);
            await refetch({ patientId: currentPatient.uuid, visitId }).unwrap();
            onClose();
            setLoading(false);
        } catch (e) {
            setLoading(false);
            setTransitionMessage(currentMessage);
            enqueueSnackbar("Something went wrong.", { variant: "error" });
        }
    };

    return (
        <Dialog maxWidth="xl" open={true} onClose={onClose}>
            <DialogTitle>{`Transition to "${transition}"`}</DialogTitle>
            <IconButton
                aria-label="close"
                onClick={onClose}
                sx={{
                    position: "absolute",
                    right: 8,
                    top: 8,
                }}
            >
                <CloseIcon />
            </IconButton>
            <Divider />

            <Divider />
            <Box sx={{ minWidth: 500 }}>
                <form style={{ flex: 1, padding: "10px 24px 20px" }} onSubmit={handleTransition}>
                    <TextField
                        value={transitionMessage}
                        onChange={(e) => setTransitionMessage(e.target.value)}
                        variant="outlined"
                        placeholder="Add a message"
                        fullWidth
                        multiline
                        minRows={3}
                        margin="normal"
                    />
                    <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 12 }}>
                        <Button disabled={isLoading} type="submit" variant="outlined" color="primary" size="large">
                            {isLoading ? "Proccessing..." : "Make transition"}
                        </Button>
                    </Box>
                </form>
            </Box>
        </Dialog>
    );
};

export default React.memo(WorkflowTransition);
