import { CrfField } from "../nebula.service";
import CustomTooltip from "../../../components/Tooltip/Tooltip";
import DisplayText from "../../../components/DisplayText/DisplayText";
import { Box } from "@mui/system";
import React, { useMemo } from "react";

export const ColumnHeader = (props: { field: CrfField }) => {
    const tooltipLabel = useMemo(() => {
        const descriptionText = props.field.description || "";
        const unitsText = props.field.unit ? `(${props.field.unit})` : "";

        return `${descriptionText} ${unitsText}`.trim();
    }, [props.field.description, props.field.unit]);

    return (
        <CustomTooltip content={<DisplayText type="bodyXSmall" text={tooltipLabel} />} style={{ overflow: "hidden" }}>
            <Box sx={{ display: "flex", alignItems: "baseline", overflow: "hidden", gap: 5 }}>
                <DisplayText
                    style={{ flex: 1, overflow: "hidden", textOverflow: "ellipsis" }}
                    type="bodySmall"
                    text={props.field.fieldName}
                />
                {props.field.laterality ? <DisplayText type="bodyXSmall" text={`(${props.field.laterality})`} /> : null}
            </Box>
        </CustomTooltip>
    );
};
