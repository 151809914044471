import React from "react";
import { IconButton, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { HighlightOff as HighlightOffIcon } from "@mui/icons-material";
import useQueryParams from "../../hooks/useQueryParams/useQueryParams";

interface IDrawerHeaderProps {}

const DrawerHeader: React.FC<IDrawerHeaderProps> = () => {
    const { removeQueryParam } = useQueryParams();

    const closeDrawer = () => {
        removeQueryParam("selectedPatient", true);
    };

    return (
        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", px: 12, py: 8 }}>
            <Typography>PATIENT CARD</Typography>
            <IconButton size="small" onClick={closeDrawer}>
                <HighlightOffIcon fontSize="large" />
            </IconButton>
        </Box>
    );
};

export default React.memo(DrawerHeader);
