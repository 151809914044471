import OctMediaViewer from "./OctMediaViewer";
import ImageMediaViewer from "./ImageMediaViewer";
import PdfMediaViewer from "./PdfMediaViewer";
import { figureFlavor } from "../../utils/MediaUtils";
import { selectMediaById } from "./viewerSlice";
import React, { memo, useCallback, useEffect } from "react";
import { useGetMediaTokenMutation, useGetPatientStudiesByEncounterQuery } from "../studies/dataAccess";
import { CardMedia, CircularProgress } from "@mui/material";
import { useParams } from "react-router-dom";
import TextFromFile from "../../components/TextFromFile/TextFromFile";
import { Box } from "@mui/system";
import { API_MEDIA_BASE_PATH } from "../../lib/nebulaClient";
import CSVToTable from "../../components/CSVToTable/CSVToTable";

interface IMediaViewerFileProps {
    mediaViewerId: string;
    mediaId: string;
}
const MediaViewerFile: React.FC<IMediaViewerFileProps> = ({ mediaViewerId, mediaId }) => {
    const { patientId } = useParams<{ patientId: string }>();

    const { data: studiesByEncounter } = useGetPatientStudiesByEncounterQuery({ patientId });

    const media = selectMediaById(studiesByEncounter, mediaId);

    const token = "token";

    const Component = useCallback(() => {
        if (!media) return null;
        switch (figureFlavor(media)) {
            case "mp4":
                return (
                    <CardMedia
                        height="100%"
                        width="auto"
                        sx={{ height: "100%", width: "auto" }}
                        component="video"
                        src={media.mediaUrl}
                        controls={true}
                    />
                );
            case "oct-mp4":
            case "oct-photo":
                return (
                    <OctMediaViewer
                        uuid={mediaId as string}
                        enfaceUrl={media.enfaceUrl}
                        slicesUrl={media.slicesUrl}
                        visitId={mediaViewerId}
                    />
                );
            case "csv":
                return <CSVToTable url={media.mediaUrl} />;
            case "photo":
                return <ImageMediaViewer url={media.mediaUrl} />;
            case "pdf":
                return <PdfMediaViewer url={media.mediaUrl} />;
            case "text":
                return (
                    <Box sx={{ width: "100%", p: 16 }}>
                        <TextFromFile url={media.mediaUrl} />
                    </Box>
                );
            default:
                return <ImageMediaViewer url={media.mediaUrl} />;
        }
    }, [media, token]);

    return <Component />;
};

export default memo(MediaViewerFile);
