import React, { useMemo } from "react";
import {
    Box,
    Divider,
    Button,
    Dialog,
    DialogTitle,
    IconButton,
    List,
    ListItem,
    Chip,
    CircularProgress,
} from "@mui/material";
import DisplayText from "../../components/DisplayText/DisplayText";
import { IEncounterComment } from "./studiesSlice";
import useRoles from "../permissions/useRoles";
import CloseIcon from "@mui/icons-material/Close";
import { useGetSiteWorkflowHistoryQuery } from "../workflow/dataAccess";

interface WorkflowHistoryDialogProps {
    onClose: () => void;
    visitId: string;
    patientId: string;
    visitName: string;
}

// @ts-ignore
const WorkflowHistoryDialog: React.FC<WorkflowHistoryDialogProps> = ({ onClose, patientId, visitId, visitName }) => {
    const { data: siteWorkflowHistory, isFetching } = useGetSiteWorkflowHistoryQuery(
        { visitId, patientId },
        { refetchOnMountOrArgChange: true }
    );

    const historyItems = useMemo(() => {
        if (!siteWorkflowHistory) return [];

        return siteWorkflowHistory.map((history) => ({
            ...history,
            comment: history.message || "",
        }));
    }, [siteWorkflowHistory]);

    return (
        <Dialog maxWidth="xl" open={true} onClose={onClose}>
            <DialogTitle>{`Workflow history for ${visitName}`}</DialogTitle>
            <IconButton
                aria-label="close"
                onClick={onClose}
                sx={{
                    position: "absolute",
                    right: 8,
                    top: 8,
                }}
            >
                <CloseIcon />
            </IconButton>
            <Divider />
            <Box sx={{ display: "flex", flexDirection: "column", overflow: "hidden", minWidth: 700 }}>
                <Box sx={{ minHeight: 100, overflow: "auto" }}>
                    {!siteWorkflowHistory || isFetching ? (
                        <Box
                            sx={{ display: "flex", justifyContent: "center", alignItems: "center", minHeight: "100px" }}
                        >
                            <CircularProgress />
                        </Box>
                    ) : historyItems.length === 0 ? (
                        <Box sx={{ position: "relative", top: "25px" }}>
                            <DisplayText
                                type="bodyMedium"
                                text="No transitions made"
                                style={{ margin: "12px 0", textAlign: "center" }}
                            />
                        </Box>
                    ) : (
                        <List sx={{ overflow: "auto", flex: 0 }}>
                            {historyItems.map((item, index) => (
                                <React.Fragment key={index}>
                                    <ListItem
                                        sx={{ marginBottom: 2, borderRadius: "5px", padding: "5px", p: "8px 24px" }}
                                    >
                                        <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                                            <Box>
                                                <Box
                                                    sx={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        gap: "6px",
                                                        marginLeft: "4px",
                                                        marginBottom: "4px",
                                                    }}
                                                >
                                                    <DisplayText
                                                        type={"bodyXSmall"}
                                                        text={item.userEmail || "anonymous"}
                                                        style={{ color: "orange" }}
                                                    />
                                                    <DisplayText
                                                        type="bodyXSmall"
                                                        text={`transitioned the state`}
                                                        style={{ color: "lightgray" }}
                                                    />
                                                </Box>
                                                <Box sx={{ display: "flex", gap: 4, alignItems: "center" }}>
                                                    <Chip label={item.from} size="small" variant="outlined" />
                                                    <DisplayText text="->" type="bodyMedium" />
                                                    <Chip label={item.to} size="small" />
                                                </Box>
                                                <Box sx={{ p: 4 }}>
                                                    <DisplayText
                                                        style={{ marginTop: 4, marginLeft: 2 }}
                                                        text={`Message: ${item.message || "-"}`}
                                                        type="bodyXSmall"
                                                    />
                                                </Box>
                                            </Box>
                                            <Box sx={{ paddingTop: 3 }}>
                                                <DisplayText
                                                    text={new Date(item.createdAt).toLocaleString()}
                                                    type="bodyXSmall"
                                                    style={{ color: "lightgray", whiteSpace: "nowrap", top: 6 }}
                                                />
                                            </Box>
                                        </Box>
                                    </ListItem>
                                    {index !== historyItems.length - 1 && <Divider />}
                                </React.Fragment>
                            ))}
                        </List>
                    )}
                </Box>
            </Box>
        </Dialog>
    );
};

export default React.memo(WorkflowHistoryDialog);
