import { useEffect, useMemo, useState } from "react";

// eslint-disable-next-line no-unused-vars
export enum SelectionState {
    // eslint-disable-next-line no-unused-vars
    ALL = "ALL",
    // eslint-disable-next-line no-unused-vars
    NONE = "NONE",
    // eslint-disable-next-line no-unused-vars
    SOME = "SOME",
}

const getItemIdDefault = (item: any) => item.id;

export interface IUseMultipleSelect<T> {
    initialSelection?: string[];
    items: any[];
    getItemId?: (item: T) => string;
    onChange?: (ids: string[]) => void;
}

const useMultipleSelect = <T>({
    initialSelection = [],
    items,
    getItemId = getItemIdDefault,
    onChange,
}: IUseMultipleSelect<T>) => {
    const [selectedItems, setSelectedItems] = useState<Set<string>>(new Set(initialSelection));

    useEffect(() => {
        if (onChange) {
            onChange(Array.from(selectedItems));
        }
    }, [selectedItems]);

    const selectionState: SelectionState = useMemo(() => {
        if (selectedItems.size === 0) return SelectionState.NONE;
        if (selectedItems.size === items.length) return SelectionState.ALL;

        return SelectionState.SOME;
    }, [items, selectedItems]);

    const handleSelect = (id: string, isChecked: boolean) => {
        const newSelectedIds = new Set(selectedItems);

        if (isChecked) {
            newSelectedIds.add(id);
        } else {
            newSelectedIds.delete(id);
        }

        return setSelectedItems(newSelectedIds);
    };

    const handleSelectAll = (isChecked: boolean) => {
        if (!isChecked) return setSelectedItems(new Set());

        const ids = items.map(getItemId);

        const newSelectedIds = new Set(ids);

        setSelectedItems(newSelectedIds);
    };

    const clearSelect = () => setSelectedItems(new Set());

    return {
        selectionState,
        handleSelect,
        handleSelectAll,
        clearSelect,
        selectedItems,
        setSelectedItems,
    };
};

export default useMultipleSelect;
