import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";

export interface ITeams {
    current: Team;
    children: ITeams[];
}

export interface Team {
    depth: number;
    isActive: boolean;
    label: string;
    name: string;
    parent: string;
    selected: boolean;
    selectedInherited: boolean;
    uuid: string;
}

export interface TeamsState {
    teamsList: ITeams[];
    selectedTeam: string;
    status: "idle" | "success" | "loading" | "failed";
    errorMessage?: string;
}

export const initialState: TeamsState = {
    teamsList: [],
    selectedTeam: "",
    status: "idle",
};

export const teamsSlice = createSlice({
    name: "teams",
    initialState,
    reducers: {
        teamSelected: (state, action: PayloadAction<any>) => {
            state.selectedTeam = action.payload;
        },
    },
});

export const { teamSelected } = teamsSlice.actions;

export const selectTeams = (state: RootState) => {
    return state.teams;
};

export default teamsSlice.reducer;
