import { configureStore, combineReducers } from "@reduxjs/toolkit";
import loginReducer, { clearState } from "../features/auth/login/loginSlice";
import patientsReducer from "../features/patients/patientsSlice";
import teamsReducer from "../features/teams/teamsSlice";
import studiesReducer from "../features/studies/studiesSlice";
import uploadReducer from "../features/upload/uploadSlice";
import viewerReducer from "../features/media/viewerSlice";
import orionReducer from "../features/orion/orionSlice";
import { serviceSlice } from "./serviceSlice";

const combinedReducer = combineReducers({
    userInfo: loginReducer,
    patients: patientsReducer,
    teams: teamsReducer,
    studies: studiesReducer,
    viewer: viewerReducer,
    upload: uploadReducer,
    orion: orionReducer,
    [serviceSlice.reducerPath]: serviceSlice.reducer,
});

// ROOT REDUCER to treat global action handling such as logging out
const rootReducer = (state: any, action: any) => {
    // Clear the state
    if (action.type === clearState.type) {
        // Return initial state
        state = undefined;
    }
    return combinedReducer(state, action);
};

export const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => [
        ...getDefaultMiddleware({
            serializableCheck: false,
        }),
        serviceSlice.middleware,
    ],
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof combinedReducer>;
