import { useCallback, useRef, useState } from "react";
import { OctImage } from "../../models/OctImage";
import { API_MEDIA_BASE_PATH } from "../../lib/nebulaClient";
import { Boundary } from "../../models/Boundary";
import { useEffect } from "react";
import { Formatter } from "../../utils/Formtter";
import { IEnFaceInfo, IMediaInfo } from "./viewerSlice";
import { CircularProgress } from "@mui/material";

const normalizeWheel = (event: any) => {
    // Reasonable defaults
    const PIXEL_STEP = 10;
    const LINE_HEIGHT = 40;
    const PAGE_HEIGHT = 800;

    let sX = 0,
        sY = 0, // spinX, spinY
        pX = 0,
        pY = 0; // pixelX, pixelY

    // Legacy
    if ("detail" in event) {
        sY = event.detail;
    }
    if ("wheelDelta" in event) {
        sY = -event.wheelDelta / 120;
    }
    if ("wheelDeltaY" in event) {
        sY = -event.wheelDeltaY / 120;
    }
    if ("wheelDeltaX" in event) {
        sX = -event.wheelDeltaX / 120;
    }

    // side scrolling on FF with DOMMouseScroll
    if ("axis" in event && event.axis === event.HORIZONTAL_AXIS) {
        sX = sY;
        sY = 0;
    }

    pX = sX * PIXEL_STEP;
    pY = sY * PIXEL_STEP;

    if ("deltaY" in event) {
        pY = event.deltaY;
    }
    if ("deltaX" in event) {
        pX = event.deltaX;
    }

    if ((pX || pY) && event.deltaMode) {
        if (event.deltaMode == 1) {
            // delta in LINE units
            pX *= LINE_HEIGHT;
            pY *= LINE_HEIGHT;
        } else {
            // delta in PAGE units
            pX *= PAGE_HEIGHT;
            pY *= PAGE_HEIGHT;
        }
    }

    // Fall-back if spin cannot be determined
    if (pX && !sX) {
        sX = pX < 1 ? -1 : 1;
    }
    if (pY && !sY) {
        sY = pY < 1 ? -1 : 1;
    }

    return { spinX: sX, spinY: sY, pixelX: pX, pixelY: pY };
};

const EnFace = (props: {
    octFileId: string;
    visitId: string;
    mediaInfo: IMediaInfo;
    enfaceInfo: IEnFaceInfo;
    enfaceUrl: string;
}) => {
    const { octFileId, mediaInfo, enfaceInfo, visitId, enfaceUrl } = props;

    const container = document.getElementById(`${visitId}-enface-container`);
    const [canvasContext, setCanvasContext] = useState<null | CanvasRenderingContext2D>(null);
    const [isLoading, setIsLoading] = useState(true);

    const canvasRef = useRef<HTMLCanvasElement>(null);
    const sync = useRef<boolean>(true);
    const _imageEnFace = useRef<OctImage | null>();
    const _isMouseDown = useRef<boolean>(false);
    const _isMouseOver = useRef<boolean>(false);
    const _mouseY = useRef<number>(0);
    const _mouseX = useRef<number>(0);
    const _currentSlicePosDrawBounds = useRef<Boundary>(new Boundary(0, 0, 0, 0));
    const _prevSlicePosDrawBounds = useRef<Boundary | null>(null);
    const _width = useRef<number>(200);
    const _octArea = useRef<Boundary>();

    useEffect(() => {
        if (canvasRef.current) {
            setCanvasContext(canvasRef.current.getContext("2d"));
        }
    }, [canvasRef]);

    const _imageIndexSetFromMouse = useCallback(
        (y: number) => {
            if (!_imageEnFace.current) return 0;
            const maxIndex = mediaInfo.NumSlices - 1;
            let i = 0;
            let b = _imageEnFace.current.bounds;
            const cursorRelativeY = y - b.y;
            i = (mediaInfo.NumSlices / b.h) * cursorRelativeY;
            i = Math.round(i);
            if (i >= maxIndex) {
                i = maxIndex;
            } else if (i < 0) {
                i = 0;
            }
            return i;
        },
        [mediaInfo]
    );

    const _getNextSliceIndexMouseYPosition = (newSliceIndex: number) => {
        return (_imageEnFace.current!.bounds.h / mediaInfo.NumSlices) * newSliceIndex + _imageEnFace.current!.bounds.y;
    };

    const _drawPointer = useCallback(() => {
        if (!canvasContext) return;
        if (!_imageEnFace.current) return;
        const fontSize = 14;
        canvasContext.fillStyle = "pink";

        const b = _imageEnFace.current.bounds;
        const cb = _currentSlicePosDrawBounds;
        let pb = _prevSlicePosDrawBounds.current;

        cb.current.x = b.xRight;
        cb.current.y = (b.h / mediaInfo.NumSlices) * _imageIndexSetFromMouse(_mouseY.current) + b.y;
        cb.current.w = fontSize * 7;
        cb.current.h = fontSize * 2;

        if (!pb) {
            pb = new Boundary(0, 0, 0, 0);
            pb.copy(cb.current);
        }

        const pv = pb;
        _prevSlicePosDrawBounds.current = pb;
        canvasContext.font = `${fontSize}px Arial, Helvetica, sans-serif`;
        const slice = Formatter.preBlank(
            Formatter.truncate(_imageIndexSetFromMouse(_mouseY.current) + 1).toString(10),
            3
        );
        canvasContext.textBaseline = "middle";
        canvasContext.textAlign = "left";
        const text = `←${slice}`;
        const size = canvasContext.measureText(text);
        canvasContext.clearRect(pv.x, pv.y - fontSize / 2, size.width + 8, fontSize);
        canvasContext.fillText(text, cb.current.x, cb.current.y);
        pb.copy(cb.current);
    }, [canvasContext, _currentSlicePosDrawBounds, _imageIndexSetFromMouse, mediaInfo]);

    const _drawAll = useCallback(
        (x: number | null, y: number | null) => {
            _mouseX.current = x !== null ? x : _imageEnFace.current!.srcPixW / 2;
            _mouseY.current = y !== null ? y : _mouseY.current;

            if (_imageEnFace.current && canvasContext) {
                const calculatedSlicesIndex = _imageEnFace.current.xhairsRawY(y);
                const crosshairHeight =
                    calculatedSlicesIndex < mediaInfo.NumSlices ? calculatedSlicesIndex : mediaInfo.NumSlices - 1;
                _imageEnFace.current.xhairsSetRaw(_mouseX.current, crosshairHeight);
                _imageEnFace.current.drawScale(canvasContext, true, enfaceInfo.EnFaceHeightSizeInMicrons, 3);
                _imageEnFace.current.drawScale(canvasContext, false, enfaceInfo.EnFaceWidthSizeInMicrons, 3);
                _drawPointer();
                _imageEnFace.current.draw(canvasContext);
            }
        },
        [_drawPointer, canvasContext, enfaceInfo]
    );

    const _trackSlice = useCallback(
        (event: any) => {
            if (_imageEnFace.current) {
                if (event.detail.from === "ENFACE") return;

                const srcPixW = _imageEnFace.current.srcPixW;

                const x = (event.detail.x / 100) * srcPixW;
                _drawAll(x, null);
            }
        },
        [_drawAll]
    );

    const _syncSlice = useCallback(() => {
        if (!_imageEnFace.current) return;
        const srcPixW = _imageEnFace.current.srcPixW;
        const imageHeight = _imageEnFace.current.bounds.h;
        const x = (_mouseX.current / srcPixW) * 100;
        const y = ((_mouseY.current - _imageEnFace.current.bounds.y) / imageHeight) * 100;

        const eventInit = {
            detail: {
                from: "ENFACE",
                x,
                y,
            },
        };
        const mouseEvent = new CustomEvent(`build-${visitId}`, eventInit);
        const elem = document.getElementsByClassName("sync");
        Array.from(elem).map((el) => el.dispatchEvent(mouseEvent));
    }, []);

    const _onMouseUp = (event: MouseEvent) => {
        _isMouseDown.current = false;
    };

    const _onMouseLeave = (event: MouseEvent) => {
        _isMouseDown.current = false;
        _isMouseOver.current = false;
    };

    const _onMouseOver = (event: MouseEvent) => {
        _isMouseOver.current = true;
    };

    const _onMouseDown = useCallback(
        (event: MouseEvent) => {
            _isMouseDown.current = true;
            if (canvasRef.current && _imageEnFace.current) {
                const cRect = canvasRef.current.getBoundingClientRect();
                const x = event.clientX - cRect.left;
                const y = event.clientY - cRect.top;

                if (_imageEnFace.current.bounds.isInside(x, y, 3)) {
                    _drawAll(_imageEnFace.current.xhairsRawX(x), y);
                    _syncSlice();
                }
            }
        },
        [_drawAll, _syncSlice]
    );

    const onKeyDown = (event: KeyboardEvent) => {
        if (canvasRef.current && _imageEnFace.current && _isMouseOver.current) {
            const currentIndex = _imageIndexSetFromMouse(_mouseY.current);
            let newSliceIndex;

            if (event.key === "ArrowUp") {
                event.preventDefault();
                newSliceIndex = currentIndex - 1;
            } else if (event.key === "ArrowDown") {
                event.preventDefault();
                newSliceIndex = currentIndex + 1;
            } else {
                return;
            }

            let newYPosition = _getNextSliceIndexMouseYPosition(newSliceIndex);

            const topLimit = _imageEnFace.current.bounds.y;
            const bottomLimit = _imageEnFace.current.bounds.yBottom;

            // If scroll is out of image bounds, return the top/bottom limit
            switch (true) {
                case newYPosition > bottomLimit:
                    newYPosition = bottomLimit;
                    break;
                case newYPosition < topLimit:
                    newYPosition = topLimit;
                    break;
                default:
                    break;
            }

            const x = _mouseX.current;

            _drawAll(x, newYPosition);

            _syncSlice();
        }
    };

    const _onWheelMove = useCallback(
        (event: WheelEvent) => {
            // prevent the window to scroll down/up
            event.preventDefault();

            if (canvasRef.current && _imageEnFace.current) {
                // Use this to achieve consistency across browsers
                const { spinY } = normalizeWheel(event);
                const topLimit = _imageEnFace.current.bounds.y;
                const bottomLimit = _imageEnFace.current.bounds.yBottom;

                // Make scroll "faster" for studies with less slices, so it doesn't take
                // that much scrolling to move to the next slice
                const yOffset = _mouseY.current + spinY * (50 / mediaInfo.NumSlices);
                let newYPosition;

                // If scroll is out of image bounds, return the top/bottom limit
                switch (true) {
                    case yOffset > bottomLimit:
                        newYPosition = bottomLimit;
                        break;
                    case yOffset < topLimit:
                        newYPosition = topLimit;
                        break;
                    default:
                        newYPosition = yOffset;
                        break;
                }

                const x = _mouseX.current;

                _drawAll(x, newYPosition);

                _syncSlice();
            }
        },
        [_drawAll, _syncSlice]
    );

    const _onMouseMove = useCallback(
        (event: MouseEvent) => {
            if (_isMouseDown.current && canvasRef.current && _imageEnFace.current) {
                const cRect = canvasRef.current.getBoundingClientRect();
                const x = event.clientX - cRect.left;
                const y = event.clientY - cRect.top;

                if (_imageEnFace.current.bounds.isInside(x, y, 10)) {
                    _drawAll(_imageEnFace.current.xhairsRawX(x), y);
                    _syncSlice();
                }
            }
        },
        [_drawAll, _syncSlice]
    );

    const _onWindowResize = (e: any) => {
        const containerSize = document.getElementById(`${visitId}-enface-container`)!.getBoundingClientRect();
        canvasRef!.current!.width = containerSize.width;
        canvasRef!.current!.height = containerSize.height;

        // _imageEnFace!.current!.aspectSet(1, (containerSize.height + 50) / containerSize.width);
        _imageEnFace!.current!.rawFit(containerSize.width - 90, containerSize.height - 30);

        _drawAll(null, null);
    };

    useEffect(() => {
        const containerSize = document.getElementById(`${visitId}-enface-container`)!.getBoundingClientRect();
        if (container && container.clientWidth > 0) _width.current = containerSize.width;
        const height = 200;
        const paddingTop = 5;
        const scaleWidth = 100;
        const scaleHeight = 20 + paddingTop;
        const pointerwidth = 30;
        if (canvasRef.current && canvasContext) {
            canvasRef.current.width = containerSize.width;
            canvasRef.current.height = containerSize.height;
            canvasRef.current.addEventListener("mousemove", _onMouseMove, false);
            canvasRef.current.addEventListener("mouseup", _onMouseUp, false);
            canvasRef.current.addEventListener("mousedown", _onMouseDown, false);
            canvasRef.current.addEventListener("mouseleave", _onMouseLeave, false);
            canvasRef.current.addEventListener("mouseover", _onMouseOver, false);
            canvasRef.current.addEventListener(`build-${visitId}`, _trackSlice, false);
            canvasRef.current.addEventListener(`wheel`, _onWheelMove, false);
            document.addEventListener(`keydown`, onKeyDown, false);
            window.addEventListener(`resize`, _onWindowResize, false);

            return () => {
                document.removeEventListener(`keyup`, onKeyDown, false);
                window.removeEventListener(`resize`, _onWindowResize, false);
            };
        }
        const _efAreaWidth = _width.current - scaleWidth - pointerwidth;
        const _efAreaHeight = height - scaleHeight;

        _octArea.current = new Boundary(scaleWidth, paddingTop, _efAreaWidth, _efAreaHeight);
    }, [canvasContext]);

    useEffect(() => {
        const ef = new Image();
        ef.src = enfaceUrl;
        ef.onload = (ev) => {
            if (!_octArea.current) return;
            const img = ev.target as HTMLImageElement;
            // This is hardcoded to force the canvas to draw a square
            const containerSize = document.getElementById(`${visitId}-enface-container`)!.getBoundingClientRect();

            const actualWidth = enfaceInfo.EnFaceHeightSizeInMicrons;
            const actualHeight = enfaceInfo.EnFaceHeightSizeInMicrons;
            const a = _octArea.current;
            const ef = new OctImage(
                img,
                a,
                1,
                enfaceInfo.EnFaceWidth,
                enfaceInfo.EnFaceHeight,
                actualWidth,
                actualHeight,
                "μm",
                "μm"
            );
            ef.id = "Enface";
            if (enfaceInfo) {
                ef.rawFit(containerSize.width - 90, containerSize.height - 30);
                ef.centerHorizontal(a, _width.current);
                _imageEnFace.current = ef;
                setIsLoading(false);
                _mouseY.current = (Math.round(ef.srcPixH / 2) * ef.bounds.h) / ef.srcPixH + ef.bounds.y;
                ef!.xhairsSetRaw(ef!.srcPixW / 2, Math.round(ef!.srcPixH / 2));
                _drawAll(null, null);
            }
        };
    }, [_drawAll, enfaceInfo, octFileId]);

    return (
        <>
            {isLoading && (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignContent: "center",
                        position: "relative",
                        top: 50,
                    }}
                >
                    <CircularProgress />
                </div>
            )}

            <canvas
                id={`${visitId}-enface`}
                key={`${visitId}-enface`}
                className={sync.current ? "sync" : ""}
                ref={canvasRef}
            />
        </>
    );
};

export default EnFace;
