import { createEntityAdapter, createSelector, createSlice, EntityState, PayloadAction } from "@reduxjs/toolkit";
import groupBy from "lodash.groupby";
import { AppDispatch, RootState } from "../../app/store";
import { serviceSlice } from "../../app/serviceSlice";
import { uploadService } from "./nebula.port";

export interface IFileExtension {
    label: string;
    prettyLabel: string;
}

export interface IDerivedFile {
    fileName: string;
    patientId: string;
    laterality: string;
    encounterDate: string;
    mediaUuid: string;
    comment?: string;
    mimeType: string;
    category: string;
    sessionUuid: SessionUuid;
    status: string;
    isDeleted: boolean;
    statusCode: number;
}

export enum FileEntryStatus {
    // eslint-disable-next-line no-unused-vars
    IDLE = "IDLE",
    // eslint-disable-next-line no-unused-vars
    QUEUED = "QUEUED",
    // eslint-disable-next-line no-unused-vars
    UPLOADING = "UPLOADING",
    // eslint-disable-next-line no-unused-vars
    CANCELED = "CANCELED",
    // eslint-disable-next-line no-unused-vars
    FAILED = "FAILED",
    // eslint-disable-next-line no-unused-vars
    PROCESSING = "PROCESSING",
    // eslint-disable-next-line no-unused-vars
    PROCESSED = "PROCESSED",
}

export type SessionUuid = string;

export interface IFileEntry {
    file: File;
    uuid: string;
    sessionUuid: SessionUuid;
    groupId: string;
    visitId: string;
    name: string;
    size: number;
    progress?: number;
    abortController: AbortController;
    mediaUuid?: string;
    status: FileEntryStatus;
    errorMessage?: string[];
}

export interface IGroup {
    depth: number;
    isActive: boolean;
    label: string;
    name: string;
    parent: string;
    selected: boolean;
    kind: string;
    selectedInherited: boolean;
    uuid: string;
}

export interface ITrial {
    uuid: string;
    name: string;
    author: string;
    createdOn: string;
    updatedOn: string;
    workflowActive: boolean;
    showTags: boolean;
}

export type IField = { fieldName: string; laterality: string };
export interface ITrialVisits {
    uuid: string;
    visitName: string;
    screening: Boolean;
    active: Boolean;
    fields: IField[];
}

export interface IUploadConfig {
    folderConventionRegEx: string[];
    validExampleFolders: string[];
    validExtensions: string[];
    uploadSizeLimitMb: number;
}

export enum UploadStep {
    // eslint-disable-next-line no-unused-vars
    FILE_UPLOAD = "FILE_UPLOAD",
    // eslint-disable-next-line no-unused-vars
    FILE_VIEWER_READY_FOR_COMMIT = "FILE_VIEWER_READY_FOR_COMMIT",
}

export interface IRawFile {
    mediaUuid: string;
    results: string[];
    sessionUuid: string;
}

export interface UploadState {
    uploadStep: UploadStep;
    fileEntries: EntityState<IFileEntry>;
    derivedFiles: EntityState<IDerivedFile>;
}

const fileEntriesAdapter = createEntityAdapter<IFileEntry>({
    selectId: (file) => file.uuid,
});

const handleUploadFile =
    (
        file: File,
        fileEntryId: string,
        uploadSessionId: string,
        abortController: AbortController,
        folderName: string,
        selectedGroupId: string,
        selectedVisitId: string
    ) =>
    async (dispatch: AppDispatch) => {
        const formData = new FormData();

        formData.append("projectUuid", selectedGroupId);

        formData.append("visitUuid", selectedVisitId);

        formData.append("setId", uploadSessionId);

        formData.append("fileName", `${folderName}/${file.name}`);

        formData.append("fileData", file);

        formData.append("autoCommit", "true");

        formData.append("fileSetCount", `${1}`);

        const onUploadProgress = (progressEvent: any) => {
            const progress = (progressEvent.loaded / progressEvent.total) * 100;
            dispatch(updateFileEntry({ id: fileEntryId, changes: { progress } }));
        };

        const { error: folderNameNotValid } = await uploadService.checkFileValidity(`${folderName}/${file.name}`);

        if (folderNameNotValid) {
            const errorMessage =
                folderNameNotValid.kind === "ApiError" ? folderNameNotValid.message : "Failed to upload file";
            dispatch(
                updateFileEntry({
                    id: fileEntryId,
                    changes: { status: FileEntryStatus.FAILED, errorMessage: [errorMessage] },
                })
            );
        } else {
            const { error, data } = await uploadService.uploadFile(formData, abortController.signal, onUploadProgress);

            if (error) {
                // @ts-ignore
                const errorMessage = error.kind === "ApiError" ? error.message.message : "Failed to upload file";
                dispatch(
                    updateFileEntry({
                        id: fileEntryId,
                        changes: { status: FileEntryStatus.FAILED, errorMessage: [errorMessage] },
                    })
                );
            } else {
                dispatch(
                    updateFileEntry({
                        id: fileEntryId,
                        //NI-2328 green check on upload not on processed
                        changes: { status: FileEntryStatus.PROCESSED, mediaUuid: data.mediaUuid },
                    })
                );
            }
        }

        dispatch(handleUploadQueue());
    };

const MAX_CONCURRENT_UPLOADS = 6;

const handleUploadQueue = () => (dispatch: any, getState: any) => {
    const state: RootState = getState();

    const filesBeingUploaded = selectFileEntriesBeingUploaded(state);

    // There are already at least as many files uploading than should be
    if (filesBeingUploaded.length >= MAX_CONCURRENT_UPLOADS) {
        return;
    }

    const filesQueued = selectFileEntriesQueued(state);

    if (filesQueued.length === 0) {
        return;
    }

    const filesToUpload = filesQueued.slice(0, MAX_CONCURRENT_UPLOADS - filesBeingUploaded.length);

    for (const fileEntry of filesToUpload) {
        dispatch(updateFileEntry({ id: fileEntry.uuid, changes: { status: FileEntryStatus.UPLOADING } }));
        dispatch(
            handleUploadFile(
                fileEntry.file,
                fileEntry.uuid,
                fileEntry.sessionUuid,
                fileEntry.abortController,
                fileEntry.name,
                fileEntry.groupId,
                fileEntry.visitId
            )
        );
    }
};

export const addFiles = (fileEntries: IFileEntry[]) => (dispatch: AppDispatch) => {
    dispatch(addFileEntries(fileEntries));
    dispatch(handleUploadQueue());
};

const derivedFilesAdapter = createEntityAdapter<IDerivedFile>({
    selectId: (derivedFile) => derivedFile.mediaUuid,
});

export const initialState: UploadState = {
    uploadStep: UploadStep.FILE_UPLOAD,
    fileEntries: fileEntriesAdapter.getInitialState(),
    derivedFiles: derivedFilesAdapter.getInitialState(),
};

export const patientsSlice = createSlice({
    name: "upload",
    initialState,
    reducers: {
        addFileEntries: (state, action: PayloadAction<IFileEntry[]>) => {
            fileEntriesAdapter.addMany(state.fileEntries, action.payload);
        },
        cancelFileEntriesUpload: (state, action: PayloadAction<string[]>) => {
            action.payload.forEach((fileEntryId) => {
                const fileEntry = state.fileEntries.entities[fileEntryId] as IFileEntry;

                fileEntry.abortController.abort();
            });

            fileEntriesAdapter.removeMany(state.fileEntries, action.payload);
        },
        updateFileEntry: (
            state,
            action: PayloadAction<{
                id: string;
                changes: Partial<IFileEntry>;
            }>
        ) => {
            fileEntriesAdapter.updateOne(state.fileEntries, action.payload);
        },
        updateMultipleFileEntries: (
            state,
            action: PayloadAction<
                {
                    id: string;
                    changes: Partial<IFileEntry>;
                }[]
            >
        ) => {
            fileEntriesAdapter.updateMany(state.fileEntries, action.payload);
        },
        updateDerivedFile: (
            state,
            action: PayloadAction<{
                id: string;
                changes: Partial<IDerivedFile>;
            }>
        ) => {
            derivedFilesAdapter.updateOne(state.derivedFiles, action.payload);
        },
        addDerivedFiles: (state, action: PayloadAction<IDerivedFile[]>) => {
            derivedFilesAdapter.addMany(state.derivedFiles, action.payload);
        },
        updateUploadState: (state, action: PayloadAction<UploadStep>) => {
            state.uploadStep = action.payload;
        },
        resetUploadSession: () => initialState,
    },
    extraReducers: (builder) => {
        builder.addMatcher(
            // @ts-ignore
            serviceSlice.endpoints.getSessionStatus.matchFulfilled,
            (state, action: Record<string, PayloadAction<IDerivedFile[]>>) => {
                derivedFilesAdapter.addMany(state.derivedFiles, action.payload);
            }
        );
    },
});

export const { selectById: selectFileEntryById, selectAll: selectFileEntries } =
    fileEntriesAdapter.getSelectors<RootState>((state) => state.upload.fileEntries);

export const {
    selectAll: selectDerivedFiles,
    selectIds: selectDerivedFilesIds,
    selectById: selectDerivedFileById,
} = derivedFilesAdapter.getSelectors<RootState>((state) => state.upload.derivedFiles);

export const selectDerivedFilesById = (derivedFilesIds: string[]) =>
    createSelector([selectDerivedFiles], (derivedFiles) => {
        return derivedFilesIds.map((derivedFileId: string) =>
            derivedFiles.find((derivedFile) => derivedFile.mediaUuid === derivedFileId)
        );
    });

export const selectFileEntriesBeingUploaded = createSelector([selectFileEntries], (fileEntries) =>
    fileEntries.filter((fileEntry) => fileEntry.status === FileEntryStatus.UPLOADING)
);

export const selectFileEntriesQueued = createSelector([selectFileEntries], (fileEntries) =>
    fileEntries.filter((fileEntry) => fileEntry.status === FileEntryStatus.QUEUED)
);

export const selectFileEntriesInProgress = createSelector([selectFileEntries], (fileEntries) =>
    fileEntries.filter(
        (fileEntry) => fileEntry.status === FileEntryStatus.PROCESSING || fileEntry.status === FileEntryStatus.UPLOADING
    )
);

export const selectFileEntriesCancelled = createSelector([selectFileEntries], (fileEntries) =>
    fileEntries.filter((fileEntry) => fileEntry.status === FileEntryStatus.CANCELED)
);

export const selectFileEntriesUploaded = createSelector([selectFileEntries], (fileEntries) =>
    fileEntries.filter((fileEntry) => fileEntry.status === FileEntryStatus.PROCESSED)
);

export const areAllFilesReady = createSelector(
    [selectFileEntriesInProgress, selectFileEntriesUploaded],
    (fileEntriesInProgress, fileEntriesUploaded) => {
        if (fileEntriesInProgress.length > 0) return false;

        if (fileEntriesUploaded.length > 0) return true;

        return false;
    }
);

export const selectDerivedFilesGroupedByPatient = createSelector([selectDerivedFiles], (derivedFiles) => {
    return groupBy(derivedFiles, "patientId");
});

export const selectSessionUuids = createSelector([selectFileEntries], (fileEntries) =>
    Array.from(new Set(fileEntries.map((fileEntry) => fileEntry.sessionUuid)))
);

export const selectFileEntriesGroupedBySession = createSelector([selectFileEntries], (fileEntries) =>
    groupBy(fileEntries, "sessionUuid")
);

export const selectUploadState = (state: RootState) => state.upload.uploadStep;

export const {
    updateUploadState,
    addFileEntries,
    cancelFileEntriesUpload,
    updateFileEntry,
    updateDerivedFile,
    resetUploadSession,
    updateMultipleFileEntries,
} = patientsSlice.actions;

export default patientsSlice.reducer;
