import { API_MEDIA_BASE_PATH } from "../../lib/nebulaClient";
import { memo } from "react";

interface IImageMediaViewerProps {
    url: string;
}
const ImageMediaViewer: React.FC<IImageMediaViewerProps> = ({ url }) => {
    const handleMouseEvent = (e: React.MouseEvent<HTMLDivElement>) => {
        e.preventDefault();
    };

    return (
        <img
            onContextMenu={handleMouseEvent}
            alt="study"
            style={{ height: "100%", width: "100%", objectFit: "contain", pointerEvents: "none" }}
            src={url}
        />
    );
};

export default memo(ImageMediaViewer);
