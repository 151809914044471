import queryString from "query-string";
import { nanoid } from "@reduxjs/toolkit";

export const getMediaViewerIdsFromUri = (search: string) => {
    const queryParams = queryString.parse(search);

    let studiesIds = queryParams.st;

    if (!studiesIds) {
        return [];
    }

    if (!Array.isArray(studiesIds)) {
        studiesIds = [studiesIds];
    }

    return studiesIds;
};

export const getQueryParamFromStudyId = (studyId: string) => `${nanoid()}:${studyId}`;

export const getQueryParamsFromStudiesIds = (studiesIds: string[]) => queryString.stringify(studiesIds);
