import React, { useMemo } from "react";
import DisplayText from "../../components/DisplayText/DisplayText";
import { Button, Dialog, DialogActions, DialogContent, CircularProgress, IconButton, Divider } from "@mui/material";
import { useGetSiteUsersQuery } from "./dataAccess";
import { Box } from "@mui/system";
import { IUser } from "../auth/login/loginSlice";
import { ContentCopy as ContentCopyIcon } from "@mui/icons-material";
import ToolTip from "../../components/Tooltip/Tooltip";

interface IReportEligibilityProps {
    isOpen: boolean;
    study: any;
    onClose: () => void;
    visitName: string;
    siteName: string;
    patientId: string;
}

const ReportEligibility: React.FC<IReportEligibilityProps> = ({
    isOpen,
    study,
    visitName,
    siteName,
    onClose,
    patientId,
}) => {
    const { data: siteUsers, isLoading: siteUsersIsLoading } = useGetSiteUsersQuery(study.uuid, {
        refetchOnMountOrArgChange: true,
        skip: !isOpen,
    });

    const mailList = useMemo(() => {
        if (!siteUsers) return "";

        const usersMails = siteUsers.map((user: IUser) => user.email);

        return usersMails.join(", ");
    }, [siteUsers]);

    const parseDate = (date: string): string => {
        if (date.length !== 8) {
            throw new Error("Invalid date format");
        }

        const year = date.substring(0, 4);
        const month = date.substring(4, 6);
        const day = date.substring(6, 8);

        return `${year}-${month}-${day}`;
    };

    const dialogContent = useMemo(() => {
        if (!study || !mailList) {
            return {
                to: "",
                subject: "",
                body: "",
            };
        }

        return {
            to: mailList,
            subject: `Screening Eligibility Notification - ${
                study!.dicomData?.clinicalTrialProtocolName || study.trialName
            } - ${siteName} - ${patientId} - ${
                new Date(study!.updatedOn || study.ScanDateAndTime).toISOString().split("T")[0]
            }`,
            body: `Dear Lexitas team member,
            
            This email is to notify you of a subject’s eligibility based on Lexitas Reading Center criteria.
            
            Sponsor: [Sponsor]
            Protocol: ${study!.dicomData?.clinicalTrialProtocolName || study.trialName || "-"}
            Site #: ${siteName}
            Visit: ${visitName}
            Subject ID: ${patientId} 
            Visit Date: ${study!.dicomData?.studyDate ? parseDate(study!.dicomData?.studyDate) : "-"}
            
            OD: [Eligible/Ineligible]
            OD Comments:
            
            OS: [Eligibile/Ineleigible]
            OS Comments:
            
            Sincerely,
            
            Lexitas Reading Center`,
        };
    }, [study, siteUsers]);

    const handleCopyEmailSubject = () => {
        navigator.clipboard.writeText(dialogContent.subject);
    };

    const handleCopyEmailTo = () => {
        navigator.clipboard.writeText(dialogContent.to);
    };

    const handleCopyEmailBody = () => {
        navigator.clipboard.writeText(dialogContent.body);
    };

    if (!isOpen) return null;

    return (
        <Dialog maxWidth="lg" fullWidth open={true} onClose={onClose}>
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", p: 24, pb: 0 }}>
                <DisplayText text="Email template" type="h5" />
            </Box>
            <DialogContent>
                {siteUsersIsLoading ? (
                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <CircularProgress />
                    </Box>
                ) : (
                    <>
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: 10,
                                justifyContent: "space-between",
                                my: 6,
                            }}
                        >
                            <p>
                                <span style={{ fontWeight: "bold" }}>To: </span>
                                <span>{dialogContent.to}</span>
                            </p>
                            <ToolTip content={`Copy email list`}>
                                <IconButton onClick={handleCopyEmailTo} size="small">
                                    <ContentCopyIcon />
                                </IconButton>
                            </ToolTip>
                        </Box>
                        <Divider />
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: 10,
                                justifyContent: "space-between",
                                my: 6,
                            }}
                        >
                            <p>
                                <span style={{ fontWeight: "bold" }}>Subject: </span>
                                <span>{dialogContent.subject}</span>
                            </p>
                            <ToolTip content={`Copy email subject`}>
                                <IconButton onClick={handleCopyEmailSubject} size="small">
                                    <ContentCopyIcon />
                                </IconButton>
                            </ToolTip>
                        </Box>
                        <Divider />
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "start",
                                gap: 10,
                                justifyContent: "space-between",
                                mt: 16,
                            }}
                        >
                            <p style={{ whiteSpace: "pre-line" }}>{dialogContent.body}</p>
                            <ToolTip content={`Copy email body`}>
                                <IconButton onClick={handleCopyEmailBody} size="small">
                                    <ContentCopyIcon />
                                </IconButton>
                            </ToolTip>
                        </Box>
                    </>
                )}
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={onClose}>
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ReportEligibility;
