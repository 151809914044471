import { useLocation } from "react-router-dom";
import { getMediaViewerIdsFromUri } from "../../features/media/utils";

const useMediaViewer = () => {
    const location = useLocation();

    const selectedMediaViewerIds = getMediaViewerIdsFromUri(location.search) as string[];

    const getMediaViewerIdIndex = (mediaViewerId: string) =>
        selectedMediaViewerIds.findIndex((id) => id === mediaViewerId);

    const getStudyIdByMediaViewerId = (mediaViewerId: string) => {
        const mediaViewerIdIndex = getMediaViewerIdIndex(mediaViewerId);

        if (mediaViewerIdIndex === -1) return null;

        // eslint-disable-next-line no-unused-vars
        const [randomUuid, studyId] = selectedMediaViewerIds[mediaViewerIdIndex].split(":");

        return studyId;
    };

    return {
        getStudyIdByMediaViewerId,
        getMediaViewerIdIndex,
        selectedMediaViewerIds,
    };
};

export default useMediaViewer;
