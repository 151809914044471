import { gridVisibleSortedRowIdsSelector, MuiEvent } from "@mui/x-data-grid";
import React from "react";

export const moveToNextCell = (params: any, apiRef: any, event: MuiEvent<React.KeyboardEvent>) => {
    const rowIds = gridVisibleSortedRowIdsSelector(apiRef.current.state);
    const visibleColumns = apiRef.current.getVisibleColumns();

    const nextCell = {
        rowIndex: rowIds.findIndex((id) => id === params.id),
        colIndex: apiRef.current.getColumnIndex(params.field),
    };

    if (nextCell.colIndex === visibleColumns.length - 1 && nextCell.rowIndex === rowIds.length - 1 && !event.shiftKey) {
        // Do nothing if we are at the last cell of the last row
        return;
    }

    if (nextCell.colIndex === 0 && nextCell.rowIndex === 0 && event.shiftKey) {
        // Do nothing if we are at the first cell of the first row
        return;
    }

    event.preventDefault();
    event.defaultMuiPrevented = true;

    if (!event.shiftKey) {
        if (nextCell.colIndex < visibleColumns.length - 1) {
            nextCell.colIndex += 1;
        } else {
            nextCell.rowIndex += 1;
            nextCell.colIndex = 0;
        }
    } else if (nextCell.colIndex > 0) {
        nextCell.colIndex -= 1;
    } else {
        nextCell.rowIndex -= 1;
        nextCell.colIndex = visibleColumns.length - 1;
    }
    apiRef.current.scrollToIndexes(nextCell);

    const field = visibleColumns[nextCell.colIndex].field;
    const id = rowIds[nextCell.rowIndex];
    apiRef.current.setCellFocus(id, field);
};
