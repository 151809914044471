import { useState } from "react";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import { ReactComponent as VoxeleronLogo } from "../../../assets/VoxeleronLogo.svg";
import TextField from "@mui/material/TextField";
import Copyright from "../../../components/CopyRight/Copyright";
import Button from "@mui/material/Button";
import theme from "../../../app/theme";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import { Typography } from "@mui/material";
import { useRecoverPasswordMutation } from "../dataAccess";
import EmailIcon from "@mui/icons-material/Email";
import { useSnackbar } from "notistack";

const Recover = () => {
    const [recoverPassword] = useRecoverPasswordMutation();
    const [email, setEmail] = useState("");
    const [loading, setLoading] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const [emailSend, setEmailSend] = useState(false);

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        setLoading(true);
        try {
            await recoverPassword(email).unwrap();

            enqueueSnackbar("An email has been sent. Please check your inbox to continue.", {
                variant: "success",
            });
            setEmailSend(true);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
            <Container component="main" maxWidth="xs">
                <Box>
                    <VoxeleronLogo />
                </Box>
                {emailSend ? (
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            flexDirection: "column",
                        }}
                    >
                        <Box>
                            <EmailIcon sx={{ fontSize: "100px" }} />
                        </Box>
                        <Box>
                            <Typography>Please check your inbox for a link to reset your password.</Typography>
                        </Box>
                    </Box>
                ) : (
                    <Box sx={{ px: 16, display: "flex", flexDirection: "column", alignItems: "center" }}>
                        <Grid container>
                            <Typography variant="subtitle1" color="textSecondary" align="center">
                                Enter your email to recover your password.
                            </Typography>
                        </Grid>
                        <form style={{ width: "100%" }} noValidate onSubmit={handleSubmit}>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                label="Email Address"
                                name="email"
                                autoComplete="email"
                                autoFocus
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                disabled={loading}
                                color="primary"
                                sx={{
                                    margin: theme.spacing(3, 0, 2),
                                }}
                            >
                                {loading ? "Sending email..." : "Send email"}
                            </Button>
                        </form>
                        <Grid container>
                            <Grid item>
                                <Link href="/login" variant="body2">
                                    {"Do you have an account? Sign in"}
                                </Link>
                            </Grid>
                        </Grid>
                        <Box mt={8}>
                            <Copyright />
                        </Box>
                    </Box>
                )}
            </Container>
        </Box>
    );
};

export default Recover;
