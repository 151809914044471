import { createTheme } from "@mui/material/styles";
import { Theme } from "@mui/material/styles";
import "@mui/styles";

const theme = createTheme({
    palette: {
        mode: "dark",
        background: {
            default: "#1e2735",
            paper: "#1e2735",
        },
        text: {
            secondary: "#efeded",
        },
    },
    spacing: 1,
});

declare module "@mui/styles/defaultTheme" {
    interface DefaultTheme extends Theme {}
}

export default theme;
