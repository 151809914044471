import React, { useCallback } from "react";
import { FilterGroup as FilterGroupType, FilterOption } from "./useFilters";
import DisplayText from "../../components/DisplayText/DisplayText";
import { Switch, Box, CircularProgress, Checkbox, Divider } from "@mui/material";

interface FilterGroupProps {
    filterGroup: FilterGroupType;
    onChange: (filterKey: string, activeFilterOptions: FilterOption[]) => void;
}

const FilterGroup: React.FC<FilterGroupProps> = ({ filterGroup, onChange }) => {
    const renderFilterComponent = useCallback(
        (option: FilterOption) => {
            const activeFilterOptions = filterGroup.activeFilterOptions || [];

            const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
                const isChecked = e.target.checked;
                if (isChecked) {
                    onChange(filterGroup.key, [...activeFilterOptions, option]);
                } else {
                    onChange(
                        filterGroup.key,
                        activeFilterOptions.filter((filter) => filter.value !== option.value)
                    );
                }
            };

            if (filterGroup.type === "AND") {
                const isFilterSelected = Boolean(activeFilterOptions.find((filter) => filter.value === option.value));

                return (
                    <>
                        <Divider style={{ margin: "0" }} variant="inset" />
                        <Box
                            style={{
                                display: "flex",
                                alignItems: "center",
                                gap: 20,
                                padding: "4px 0",
                                justifyContent: "space-between",
                            }}
                        >
                            <DisplayText type="bodyMedium" text={option.label} />
                            <Switch onChange={handleChange} checked={isFilterSelected} size="small" />
                        </Box>
                    </>
                );
            } else if (filterGroup.type === "OR") {
                const isFilterSelected = Boolean(activeFilterOptions.find((filter) => filter.value === option.value));

                return (
                    <>
                        <Divider style={{ margin: "0" }} variant="inset" />
                        <Box
                            style={{
                                display: "flex",
                                alignItems: "center",
                                gap: 10,
                                justifyContent: "space-between",
                            }}
                        >
                            <DisplayText type="bodyMedium" text={option.label} />
                            <Checkbox
                                onChange={handleChange}
                                sx={{ height: 32 }}
                                checked={isFilterSelected}
                                size="small"
                            />
                        </Box>
                    </>
                );
            }
            return null;
        },
        [filterGroup]
    );

    const handleClearAll = useCallback(() => {
        onChange(filterGroup.key, []);
    }, [filterGroup.key, onChange]);

    return (
        <Box
            style={{
                display: "flex",
                flexDirection: "column",
                padding: "0 6px",
            }}
        >
            <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "baseline", gap: 50 }}>
                <DisplayText type="bodyMedium" variant="bold" text={filterGroup.label} style={{ marginBottom: 10 }} />
                <Box onClick={handleClearAll}>
                    <DisplayText
                        type="bodyXSmall"
                        text="Clear"
                        style={{ textDecoration: "underline", cursor: "pointer" }}
                    />
                </Box>
            </Box>
            {filterGroup.filterOptions ? (
                filterGroup.filterOptions.map(renderFilterComponent)
            ) : (
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        height: "100%",
                        alignItems: "center",
                        minWidth: "100px",
                    }}
                >
                    <CircularProgress />
                </Box>
            )}
        </Box>
    );
};

export default React.memo(FilterGroup);
