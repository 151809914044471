import { useLocation, useHistory } from "react-router-dom";

const useQueryParams = () => {
    const location = useLocation();
    const history = useHistory();

    const getQueryParam = (key: string) => {
        const params = new URLSearchParams(location.search);
        return params.get(key);
    };

    const setQueryParam = (key: string, value: string, pure: boolean = false) => {
        const params = new URLSearchParams(pure ? "" : location.search);
        if (value) {
            params.set(key, value);
        } else {
            params.delete(key);
        }
        history.push({ search: params.toString() });
    };

    const removeQueryParam = (key: string, pure: boolean = false) => {
        const params = new URLSearchParams(pure ? "" : location.search);
        params.delete(key);
        history.push({ search: params.toString() });
    };

    return {
        getQueryParam,
        setQueryParam,
        removeQueryParam,
    };
};

export default useQueryParams;
