import React, { useMemo } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import ConfirmationModalContext from "./ConfirmationModalContext";

export interface IConfirmationModalState {
    onAccept: (...args: any) => void;
    title?: string;
    content?: string;
    acceptLabel?: string;
    cancelLabel?: string;
}

const ConfirmationModalProvider: React.FC = ({ children }) => {
    const [state, setState] = React.useState<IConfirmationModalState | null>(null);

    const handleClickOpen = ({
        title = "",
        content = "",
        onAccept,
        cancelLabel = "cancel",
        acceptLabel = "close",
    }: IConfirmationModalState) => {
        setState({ title, content, onAccept, cancelLabel, acceptLabel });
    };

    const handleClose = () => {
        setState(null);
    };

    const handleAccept = () => {
        if (state) {
            state.onAccept();
            setState(null);
        }
    };

    const context = useMemo(
        () => ({
            isOpen: Boolean(state),
            openConfirmationModal: handleClickOpen,
        }),
        [state]
    );

    return (
        <ConfirmationModalContext.Provider value={context}>
            {state ? (
                <Dialog maxWidth="xl" open={true} onClose={handleClose}>
                    {state.title ? <DialogTitle>{state.title}</DialogTitle> : null}
                    {state.content ? (
                        <DialogContent>
                            <Typography>{state.content}</Typography>
                        </DialogContent>
                    ) : null}
                    <DialogActions>
                        <Button variant="outlined" onClick={handleClose}>
                            {state.cancelLabel}
                        </Button>
                        <Button variant="contained" onClick={handleAccept}>
                            {state.acceptLabel}
                        </Button>
                    </DialogActions>
                </Dialog>
            ) : null}
            {children}
        </ConfirmationModalContext.Provider>
    );
};

export default ConfirmationModalProvider;
