import React, { useState } from "react";
import { IPatient } from "../patients/patientsSlice";
import EditIcon from "@mui/icons-material/Edit";
import { Box } from "@mui/system";
import DisplayText from "../../components/DisplayText/DisplayText";
import { getCommaSeparatedPatientName } from "../patients/utils";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material";
import { useEditPatientMutation } from "../patients/dataAccess";
import { useSnackbar } from "notistack";
import useRoles from "../permissions/useRoles";

interface IEditablePatientNameProps {
    patient: IPatient;
}

const EditablePatientName: React.FC<IEditablePatientNameProps> = ({ patient }) => {
    const [showEdit, setShowEdit] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const [open, setOpen] = useState(false);
    const [firstName, setFirstName] = useState(patient.firstName);
    const [lastName, setLastName] = useState(patient.lastName);
    const { userHasRole } = useRoles();

    const [editPatient] = useEditPatientMutation();

    const handleApply = async () => {
        const updatedFirstName = firstName!.trim();
        const updatedLastName = lastName!.trim();

        try {
            handleClose();

            await editPatient({
                firstName: updatedFirstName,
                lastName: updatedLastName,
                uuid: patient.uuid,
            }).unwrap();

            handleClose();
        } catch (e) {
            enqueueSnackbar("Something went wrong.", { variant: "error" });
        }
    };

    const handleMouseEnter = () => {
        setShowEdit(true);
    };

    const handleMouseLeave = () => {
        setShowEdit(false);
    };

    const handleClickOpen = () => {
        setShowEdit(false);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const isApplyDisabled =
        (firstName === patient.firstName && lastName === patient.lastName) ||
        firstName?.trim() === "" ||
        lastName?.trim() === "";

    const completeName = getCommaSeparatedPatientName(patient.firstName, patient.lastName);

    if (!userHasRole("PROJECT_MANAGER")) {
        return (
            <Box sx={{ display: "flex", alignItems: "center", mt: 4 }}>
                <DisplayText type="h5" text={completeName || "-"} />
            </Box>
        );
    }
    return (
        <Box
            sx={{ cursor: "pointer" }}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            display="flex"
            alignItems="center"
        >
            <Box>
                <Box sx={{ display: "flex", alignItems: "center", mt: 4 }}>
                    <DisplayText
                        style={{
                            margin: 0,
                            fontStyle: completeName ? "normal" : "italic",
                            fontWeight: completeName ? "600" : "400",
                        }}
                        type="h5"
                        text={completeName || "Add patient name"}
                    />

                    {showEdit && <EditIcon style={{ marginLeft: 8, cursor: "pointer" }} onClick={handleClickOpen} />}
                </Box>
            </Box>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Edit Patient Name</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        label="First Name"
                        type="text"
                        fullWidth
                        variant="outlined"
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                    />
                    <TextField
                        margin="dense"
                        label="Last Name"
                        type="text"
                        fullWidth
                        variant="outlined"
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} variant="outlined">
                        Cancel
                    </Button>
                    <Button disabled={isApplyDisabled} onClick={handleApply} variant="contained">
                        Apply
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default EditablePatientName;
