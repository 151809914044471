import React, { BaseSyntheticEvent, useState, Fragment, useMemo, useCallback } from "react";
import { FilterList, Search as SearchIcon } from "@mui/icons-material";
import { Menu, IconButton, InputBase, Paper, Box, Divider, Badge, Button, Chip } from "@mui/material";
import { FilterGroup as FilterGroupType, FilterOption } from "./useFilters";
import FilterGroup from "./FilterGroup";

interface ISearchBarProps {
    activeFilters: Map<string, FilterGroupType>;
    onChange: (newValue: string) => void;
    onFilterChange: (filterKey: string, activeFilterOptions: FilterOption[]) => void;
    value: string;
}

const SearchBar: React.FC<ISearchBarProps> = ({ onChange, value, activeFilters, onFilterChange }) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleSearchOnChange = (e: BaseSyntheticEvent) => {
        const val = e.target.value;
        onChange(val);
    };

    const isAtLeastOneFilterSelected = useMemo(
        () => Array.from(activeFilters.values()).some((filter) => Boolean(filter?.activeFilterOptions?.length)),
        [activeFilters]
    );

    const handleClearAllFilters = useCallback(() => {
        activeFilters.forEach((filter) => {
            onFilterChange(filter.key, []);
        });
    }, [activeFilters]);

    return (
        <Paper sx={{ display: "flex", alignItems: "center", width: "100%" }}>
            <IconButton sx={{ p: "10px" }} aria-label="search">
                <SearchIcon />
            </IconButton>
            <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder="Search patients"
                inputProps={{ "aria-label": "Search patients" }}
                onChange={handleSearchOnChange}
                value={value}
            />

            <>
                <IconButton onClick={handleClick} color="primary" sx={{ p: "10px" }} aria-label="directions">
                    <Badge color="secondary" variant="dot" invisible={!isAtLeastOneFilterSelected}>
                        <FilterList />
                    </Badge>
                </IconButton>
                <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                    <Box sx={{ padding: "6px" }}>
                        <Box sx={{ display: "flex", justifyContent: "flex-end", mb: 12 }}>
                            <Button sx={{ whiteSpace: "nowrap" }} onClick={handleClearAllFilters} size="small">
                                Remove filters
                            </Button>
                        </Box>

                        <Box
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                gap: 10,
                                minHeight: 200,
                            }}
                        >
                            {Array.from(activeFilters.values()).map((filter, i: number) => {
                                const isLastElement = i + 1 === activeFilters.size;
                                return (
                                    <Fragment key={filter.key}>
                                        <FilterGroup filterGroup={filter} onChange={onFilterChange} />
                                        {!isLastElement ? (
                                            <Divider orientation="vertical" flexItem variant="middle" />
                                        ) : null}
                                    </Fragment>
                                );
                            })}
                        </Box>
                    </Box>
                </Menu>
            </>
        </Paper>
    );
};

export default SearchBar;
