import React, { ChangeEvent, useState } from "react";
import { Message as MessageIcon } from "@mui/icons-material";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Popover,
    TextField,
    Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { updateDerivedFile } from "../../uploadSlice";
import { useDispatch } from "react-redux";

interface IEditableCommentProps {
    comment?: string;
    uuid: string;
    fileName: string;
}

const MAX_CHARACTERS = 140;

interface ICommentDialogProps {
    initialCommentValue: string;
    fileName: string;
    onClose: () => void;
    onSave: (comment: string) => void;
}

const CommentDialog: React.FC<ICommentDialogProps> = ({ initialCommentValue, onClose, onSave, fileName }) => {
    const [commentValue, setCommentValue] = useState(initialCommentValue);

    const handleCommentChange = (e: ChangeEvent<HTMLInputElement>) => {
        const comment = e.target.value;

        if (comment.length > MAX_CHARACTERS) return;

        setCommentValue(comment);
    };

    const handleSaveComment = () => {
        onSave(commentValue);
        onClose();
    };

    return (
        <Dialog maxWidth="xl" open={true} onClose={onClose}>
            <DialogTitle>Add message</DialogTitle>
            <DialogContent>
                <Box sx={{ mb: 16 }}>
                    <Typography>{`Filename: ${fileName}`}</Typography>
                </Box>

                <Box sx={{ width: 400 }}>
                    <TextField
                        id="outlined-multiline-static"
                        label="Message"
                        value={commentValue}
                        onChange={handleCommentChange}
                        multiline
                        fullWidth
                        rows={4}
                    />
                </Box>

                <Typography variant="overline">{`${MAX_CHARACTERS - commentValue.length} characters left`}</Typography>
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={onClose}>
                    Cancel
                </Button>
                <Button variant="contained" onClick={handleSaveComment}>
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const EditableComment: React.FC<IEditableCommentProps> = ({ comment = "", uuid, fileName }) => {
    const dispatch = useDispatch();

    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

    const [open, setOpen] = useState(false);

    const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const handleSave = (comment: string) => {
        dispatch(updateDerivedFile({ id: uuid, changes: { comment } }));
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Box>
            <IconButton
                color={comment ? "warning" : "default"}
                onClick={handleClickOpen}
                aria-label="delete"
                size="small"
                onMouseEnter={handlePopoverOpen}
                onMouseLeave={handlePopoverClose}
            >
                <MessageIcon fontSize="inherit" />
            </IconButton>
            {comment ? (
                <Popover
                    id="mouse-over-popover"
                    sx={{
                        pointerEvents: "none",
                    }}
                    open={Boolean(anchorEl)}
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                    }}
                    onClose={handlePopoverClose}
                    disableRestoreFocus
                >
                    <Box sx={{ p: 8 }}>
                        <Typography sx={{ p: 1 }}>{comment}</Typography>
                    </Box>
                </Popover>
            ) : null}
            {open ? (
                <CommentDialog
                    onSave={handleSave}
                    fileName={fileName}
                    initialCommentValue={comment}
                    onClose={handleClose}
                />
            ) : null}
        </Box>
    );
};

export default EditableComment;
