import { GridRenderCellParams, useGridApiContext } from "@mui/x-data-grid";
import GridCellExpand from "../GridCellExpand";
import { useEditCRFMutation, useRemoveCrfFieldsMutation } from "../dataAccess";
import { useSnackbar } from "notistack";
import { CrfField } from "../nebula.service";

export const GridCell = (params: GridRenderCellParams<string> & { fieldDefinition?: CrfField }) => {
    const { enqueueSnackbar } = useSnackbar();
    const apiRef = useGridApiContext();
    const [removeCrfFields] = useRemoveCrfFieldsMutation();
    const [editCRF] = useEditCRFMutation();

    const handleRemove = () => {
        try {
            const rowValues = apiRef.current.getRow(params.id);
            const { visitId, patientId, laterality, userEmail } = rowValues;

            if (params.field === "notes") {
                const payload = { laterality: laterality, visitId: visitId, email: rowValues.userEmail, notes: "" };
                // @ts-ignore
                return editCRF({ patientId: patientId, crfUpdate: payload }).unwrap();
            }

            if (!params.fieldDefinition) return;

            return removeCrfFields({
                visitId,
                patientId,
                laterality,
                fieldsToRemove: [params.fieldDefinition.uuid],
                email: userEmail,
            }).unwrap();
        } catch (e) {
            enqueueSnackbar("Something went wrong.", { variant: "error" });
        }
    };
    return (
        <GridCellExpand
            isEditable={Boolean(params.isEditable)}
            formattedValue={params.formattedValue as string}
            value={params.value || ""}
            onRemove={handleRemove}
            width={params.colDef.computedWidth}
        />
    );
};
