import FilterPill from "./VisitPanel/FilterPill";
import { Box, Stack } from "@mui/system";
import React, { useCallback, useMemo } from "react";
import { IMedia } from "./studiesSlice";
import useSelectStudiesByType from "./useSelectStudiesByType";
import { getMimeTypePrettyName } from "../../utils/MediaUtils";
import { IconButton, Tooltip } from "@mui/material";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import useRoles from "../permissions/useRoles";

interface StudiesFiltersProps {
    medias: IMedia[];
    onSelect: (studiesIds: Set<string>) => void;
    onShowHiddenFiles: () => void;
    showHiddenFiles: boolean;
    selectedStudies: Set<string>;
    isLoading: boolean;
}
const StudiesFilters: React.FC<StudiesFiltersProps> = ({
    medias,
    selectedStudies,
    onSelect,
    onShowHiddenFiles,
    showHiddenFiles,
}) => {
    const { userHasRole } = useRoles();
    const uniqueStudyTypes = useMemo(() => {
        const studyTypes: Record<string, string> = {};

        medias.forEach((media) => {
            studyTypes[media.mimeType] = getMimeTypePrettyName(media.mimeType);
        });

        return studyTypes;
    }, []);

    const handleSelectAll = useCallback(() => {
        if (medias) {
            const allStudiesIds = medias.map((media) => media.uuid);

            const newSelectedStudies = new Set(allStudiesIds);
            onSelect(newSelectedStudies);
        }
    }, []);

    const handleDeselectAll = useCallback(() => {
        onSelect(new Set());
    }, []);

    const areAllSelected = useMemo(() => {
        if (medias.length === 0) return false;

        const activeMedias = medias.filter((media) => media.hidden === false);

        const studiesAmount = activeMedias.length;

        return studiesAmount === selectedStudies.size;
    }, [selectedStudies, medias]);

    const renderFilters = useCallback(() => {
        return Object.entries(uniqueStudyTypes).map(([studyType, studyLabel]) => {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            const { areAllStudyTypesSelected, selectAllStudiesType, deselectAllStudiesType } = useSelectStudiesByType(
                studyType,
                selectedStudies,
                onSelect
            );

            const handleActivate = () => selectAllStudiesType(medias);

            const handleDeactivate = () => deselectAllStudiesType(medias);

            const isDisabled = Boolean(medias.filter((media) => media.mimeType === studyType).length === 0);

            return (
                <FilterPill
                    key={studyType}
                    label={studyLabel}
                    disabled={isDisabled}
                    onActivate={handleActivate}
                    onDeactivate={handleDeactivate}
                    active={areAllStudyTypesSelected(medias, selectedStudies)}
                />
            );
        });
    }, [selectedStudies, medias]);

    return (
        <Box sx={{ display: "flex", alignItems: "center", flexWrap: "wrap", gap: 3 }}>
            {userHasRole("PROJECT_MANAGER") && (
                <Tooltip title={!showHiddenFiles ? "Show hidden files" : "Don't show hidden files"}>
                    <IconButton
                        color={showHiddenFiles ? "primary" : "default"}
                        onClick={onShowHiddenFiles}
                        size="small"
                    >
                        {!showHiddenFiles ? <VisibilityIcon /> : <VisibilityOffIcon />}
                    </IconButton>
                </Tooltip>
            )}
            <FilterPill
                label="All Studies"
                onActivate={handleSelectAll}
                onDeactivate={handleDeselectAll}
                active={areAllSelected}
            />
            {renderFilters()}
        </Box>
    );
};

export default React.memo(StudiesFilters);
