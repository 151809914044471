import React from "react";
import { styled, experimental_sx as sx } from "@mui/system";

const bodyStyle = {
    fontFamily: "Roboto",
    fontStyle: "normal",
};

const regular = {
    fontWeight: "normal",
};

const semiBold = {
    fontWeight: 600,
};

const bold = {
    fontWeight: "bold",
};

const bodyLargeStyle = {
    fontSize: 18,
    lineHeight: "22px",
};

const bodyMediumStyle = {
    fontSize: 16,
};

const bodySmallStyle = {
    fontSize: 14,
    lineHeight: "20px",
};

const bodyXSmallStyle = {
    fontSize: 12,
    lineHeight: "12px",
};

interface textVariantDescription {
    renderElement: keyof JSX.IntrinsicElements;
    variants: Partial<Record<TextVariants, Object>>;
}

const variantsDescription: Record<TextTypes, textVariantDescription> = {
    h1: {
        renderElement: "h1",
        variants: {
            regular: {
                fontWeight: 600,
                fontSize: 60,
                lineHeight: "72px",
            },
        },
    },
    h2: {
        renderElement: "h2",
        variants: {
            regular: {
                fontWeight: "normal",
                fontSize: 50,
                lineHeight: "60px",
            },
        },
    },
    h3: {
        renderElement: "h3",
        variants: {
            regular: {
                fontWeight: "normal",
                fontSize: 35,
                lineHeight: "42px",
            },
        },
    },
    h4: {
        renderElement: "h4",
        variants: {
            regular: {
                fontWeight: 600,
                fontSize: 30,
                lineHeight: "38px",
            },
        },
    },
    h5: {
        renderElement: "h5",
        variants: {
            regular: {
                fontWeight: 600,
                fontSize: 25,
                lineHeight: "30px",
            },
        },
    },
    h6: {
        renderElement: "h6",
        variants: {
            regular: {
                fontWeight: 600,
                fontSize: 20,
                lineHeight: "27px",
            },
        },
    },
    bodyLarge: {
        renderElement: "p",
        variants: {
            regular: {
                ...bodyStyle,
                ...bodyLargeStyle,
                ...regular,
            },
            semiBold: {
                ...bodyStyle,
                ...bodyLargeStyle,
                ...semiBold,
            },
            bold: {
                ...bodyStyle,
                ...bodyLargeStyle,
                ...bold,
            },
        },
    },
    bodyMedium: {
        renderElement: "p",
        variants: {
            regular: {
                ...bodyStyle,
                ...bodyMediumStyle,
                ...regular,
                lineHeight: "24px",
            },
            semiBold: {
                ...bodyStyle,
                ...bodyMediumStyle,
                ...semiBold,
                lineHeight: "24px",
            },
            bold: {
                ...bodyStyle,
                ...bodyMediumStyle,
                ...bold,
                lineHeight: "23px",
            },
        },
    },
    bodySmall: {
        renderElement: "p",
        variants: {
            regular: {
                ...bodyStyle,
                ...bodySmallStyle,
                ...regular,
            },
            semiBold: {
                ...bodyStyle,
                ...bodySmallStyle,
                ...semiBold,
            },
        },
    },
    bodyXSmall: {
        renderElement: "p",
        variants: {
            regular: {
                ...bodyStyle,
                ...bodyXSmallStyle,
                ...regular,
            },
            semiBold: {
                ...bodyStyle,
                ...bodyXSmallStyle,
                ...semiBold,
            },
        },
    },
};

type TextTypes = "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "bodyLarge" | "bodyMedium" | "bodySmall" | "bodyXSmall";

type TextVariants = "regular" | "semiBold" | "bold";

interface IDisplayTextProps {
    type: TextTypes;
    variant?: TextVariants;
    text: string;
    style?: Object;
}

const DisplayText: React.FC<IDisplayTextProps> = ({
    type = "h1" as TextTypes,
    variant = "regular" as TextVariants,
    text = "",
    style = {},
}) => {
    const mappedType = variantsDescription[type];

    const variantStyles = mappedType.variants[variant as TextVariants];

    if (!variantStyles) {
        // eslint-disable-next-line no-console
        console.error(`DisplayText Component: No combination found for: ${type} and ${variant}`);
        return null;
    }

    const DisplayComponent = styled(mappedType.renderElement)(sx(variantStyles));

    return <DisplayComponent style={style}>{text}</DisplayComponent>;
};

export default DisplayText;
