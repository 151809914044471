import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { updateDerivedFile } from "../../uploadSlice";
import { Box } from "@mui/system";
import { IconButton, Typography } from "@mui/material";
import { DesktopDatePicker } from "@mui/lab";
import { Edit as EditButton } from "@mui/icons-material";

interface IEditableDateProps {
    value: string;
    uuid: string;
    attributeName: string;
    editable?: boolean;
}

const EditableDate: React.FC<IEditableDateProps> = ({ value, uuid, attributeName, editable = true }) => {
    const dispatch = useDispatch();
    const [isEditableVisible, setIsEditableVisible] = useState(false);

    const handleHover = () => setIsEditableVisible(true);

    const handleMouseLeave = () => setIsEditableVisible(false);

    const submitNewValue = (newValue: any) => {
        dispatch(updateDerivedFile({ id: uuid, changes: { [attributeName]: newValue } }));
    };

    const handleChange = (newValue: any) => {
        setIsEditableVisible(false);
        submitNewValue(newValue);
    };

    if (!editable) {
        return (
            <Box sx={{ flex: 1, display: "flex", alignItems: "center" }}>
                <Typography>{value}</Typography>
            </Box>
        );
    }

    return (
        <Box
            onMouseOver={handleHover}
            onMouseLeave={handleMouseLeave}
            sx={{ flex: 1, display: "flex", alignItems: "center" }}
        >
            <DesktopDatePicker
                label=""
                inputFormat="MM/dd/yyyy"
                value={value}
                onChange={handleChange}
                renderInput={({ inputRef, inputProps, InputProps }: any) => {
                    return (
                        <Box sx={{ flex: 1, display: "flex", alignItems: "center", position: "relative" }}>
                            <Typography ref={inputRef}>{inputProps.value || "-"}</Typography>
                            {isEditableVisible ? (
                                <IconButton
                                    onClick={InputProps.endAdornment.props.children.props.onClick}
                                    size="small"
                                    color="primary"
                                    aria-label="edit"
                                    component="span"
                                >
                                    <EditButton fontSize="inherit" />
                                </IconButton>
                            ) : null}
                        </Box>
                    );
                }}
            />
        </Box>
    );
};

export default EditableDate;
