import { serviceSlice } from "../../app/serviceSlice";
import { ILaunchRequest } from "./nebula.service";
import { orionService } from "./nebula.port";
import { IOrionSession } from "./orionSlice";

export const extendedApiSlice = serviceSlice.injectEndpoints({
    endpoints: (builder) => ({
        launchOrion: builder.mutation<string, ILaunchRequest>({
            query:
                ({ sessionId, studiesIds, attachmentIds }) =>
                () =>
                    orionService.launchOrion({ sessionId, studiesIds, attachmentIds }),
        }),
        createOrionSession: builder.mutation<IOrionSession, string>({
            query: (orionSessionId) => () => orionService.createOrionSession(orionSessionId),
        }),
    }),
});

export const { useLaunchOrionMutation, useCreateOrionSessionMutation } = extendedApiSlice;
