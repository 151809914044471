import * as React from "react";

interface IUploadDrawingProps {
    size: number;
}

const UploadDrawing: React.FC<IUploadDrawingProps> = ({ size = 40 }) => {
    return (
        <svg width={size} height={size} fill="none" viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M25.068 1.333L3.018 7.875a2.5 2.5 0 00-1.685 3.108l7.11 23.967a2.5 2.5 0 003.109 1.686l22.05-6.543a2.5 2.5 0 001.685-3.107l-7.11-23.968a2.5 2.5 0 00-3.109-1.685z"
                fill="#fff"
                stroke="#B1ADAD"
            />
            <path
                d="M9.482 8.565L4.688 9.987l1.138 3.835 4.793-1.422-1.137-3.835zM12.326 18.152l-4.794 1.422 1.138 3.835 4.794-1.422-1.138-3.835zM15.17 27.739l-4.793 1.422 1.138 3.835 4.793-1.423-1.138-3.834zM10.904 13.358L6.11 14.78l1.138 3.835 4.793-1.422-1.137-3.835zM13.748 22.945l-4.793 1.422 1.137 3.835 4.794-1.422-1.138-3.835zM20.312 6.395l-9.587 2.844.284.959 9.587-2.845-.284-.958zM23.156 15.982l-9.587 2.844.284.959 9.588-2.845-.285-.958zM26 25.569l-9.587 2.844.285.959 9.587-2.845L26 25.57zM21.734 11.188l-9.587 2.845.284.958 9.587-2.844-.284-.959zM24.578 20.775l-9.587 2.845.285.958 9.587-2.844-.285-.959zM22.798 7.743l-11.504 3.413.284.96 11.504-3.414-.284-.959zM25.642 17.33l-11.504 3.413.284.96 11.505-3.414-.285-.959zM28.487 26.917L16.982 30.33l.285.96 11.504-3.414-.284-.959zM24.22 12.537L12.716 15.95l.284.959 11.505-3.414-.285-.958zM27.064 22.124L15.56 25.537l.285.959 11.504-3.414-.285-.958z"
                fill="#C4C4C4"
                fillOpacity={0.67}
            />
            <path
                d="M36 8.5H13a2.5 2.5 0 00-2.5 2.5v25a2.5 2.5 0 002.5 2.5h23a2.5 2.5 0 002.5-2.5V11A2.5 2.5 0 0036 8.5z"
                fill="#fff"
                stroke="#B1ADAD"
            />
            <path d="M23 13h-9v7h9v-7z" fill="#C4C4C4" />
            <path d="M19 23h-5v4h5v-4zM19 28h-5v4h5v-4z" fill="#C4C4C4" fillOpacity={0.67} />
            <path d="M36 16H24v1h12v-1z" fill="#C4C4C4" />
            <path
                d="M30 24H20v1h10v-1zM30 29H20v1h10v-1zM32 26H20v1h12v-1zM32 31H20v1h12v-1z"
                fill="#C4C4C4"
                fillOpacity={0.67}
            />
            <path d="M34 14H24v1h10v-1z" fill="#C4C4C4" />
            <path d="M36.5 40a3.5 3.5 0 100-7 3.5 3.5 0 000 7z" fill="#fff" />
            <path
                d="M36.5 33a3.501 3.501 0 00-3.5 3.5c0 1.932 1.568 3.5 3.5 3.5s3.5-1.568 3.5-3.5-1.568-3.5-3.5-3.5zm1.75 3.85h-1.4v1.4h-.7v-1.4h-1.4v-.7h1.4v-1.4h.7v1.4h1.4v.7z"
                fill="#5B8CAF"
            />
        </svg>
    );
};

export default UploadDrawing;
