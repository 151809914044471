import { useEffect, useState } from "react";
import { Alert, Fade, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useGetActiveAlertsQuery } from "./alertDataAccess";
import { Alert as IAlert } from "./alert.service";

export const AlertBanner = () => {
    const [open, setOpen] = useState(false);
    const { data: alertData, isLoading } = useGetActiveAlertsQuery() as { data: IAlert[]; isLoading: boolean };

    useEffect(() => {
        if (alertData && alertData.length > 0) {
            setOpen(true); // Open the alert when new alert data is available
        }
    }, [alertData]);

    const handleClose = () => {
        setOpen(false);
    };

    return alertData && alertData.length ? (
        <Fade in={open} mountOnEnter unmountOnExit>
            <Alert
                sx={{
                    flex: "0 0 48px",
                    margin: "10px 8px 10px 8px",
                }}
                action={
                    <IconButton aria-label="close" color="inherit" size="small" onClick={handleClose}>
                        <CloseIcon fontSize="inherit" />
                    </IconButton>
                }
                severity={alertData[0].type}
            >
                {alertData[0].label}
            </Alert>
        </Fade>
    ) : null;
};
