import { nebulaClient, nebulaClient2 } from "../../lib/nebulaClient";
import { PatientService } from "./nebula.service";
import { IPatient, Tag } from "./patientsSlice";

export interface PatientDTO {
    isQc: boolean;
    earliestISODate: string;
    lastUploadedOnIso: string;
    dateOfBirth: string;
    gender: string;
    group: string;
    label: string;
    latestISODate: string;
    siteName: string;
    patientId: string;
    patientName: string;
    uuid: string;
    site: string;
    visits: number;
    tags: {
        uuid: string;
        label: string;
        color: string;
    }[];
    workflowStates: {
        value: string;
        label: string;
        priority?: string;
        order?: number;
    }[];
}

interface TagDto {
    uuid: string;
    label: string;
    color: string;
    createdAt: Date;
    updatedAt: Date;
}

export const patientService: PatientService = {
    getPatients: async (trialId: string) => {
        const response = await nebulaClient2.get<PatientDTO[]>(`/trial/${trialId}/patient`);

        return response;
    },
    getPatientById: async (patientId: string) => {
        const response = await nebulaClient.get<PatientDTO>(`/patients/${patientId}`);

        return response;
    },
    editPatient: async (patient: Partial<IPatient>) => {
        const response = await nebulaClient2.put<PatientDTO>(`/patient/${patient.uuid}`, patient);

        return response;
    },
    getTags: async (trialId: string) => {
        const response = await nebulaClient2.get<TagDto[]>(`/trial/${trialId}/tag`);

        return response;
    },
    removeTagFromPatient: async (patientId: string, tagId: string) => {
        const response = await nebulaClient2.delete<PatientDTO>(`/patient/${patientId}/tag/${tagId}`);

        return response;
    },
    addTagToPatient: async (patientId: string, tag: Tag) => {
        const response = await nebulaClient2.post<PatientDTO>(`/patient/${patientId}/tag/${tag.uuid}`, {});

        return response;
    },
};
