import React, { useMemo, useState } from "react";
import { Upload as UploadIcon } from "@mui/icons-material";
import groupBy from "lodash.groupby";
import { v4 as uuidv4 } from "uuid";
import { Box, Button, CircularProgress, Typography, Alert, AlertTitle } from "@mui/material";
import UploadDrawing from "../UploadDrawing";

import {
    areAllFilesReady,
    FileEntryStatus,
    IFileEntry,
    selectFileEntries,
    selectFileEntriesGroupedBySession,
    selectFileEntriesInProgress,
    selectFileEntriesUploaded,
    resetUploadSession,
    addFiles,
} from "../../uploadSlice";
import { nanoid } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../../app/hooks";
import FileEntriesSession from "./FileEntrySession";
import { useHistory } from "react-router-dom";
import { getSelectedTrial } from "../../../auth/login/loginSlice";

interface IFileUploadProps {
    disabled: boolean;
    selectedVisitId: string;
    validFileExtensions?: string;
    folderConventionRegEx?: string[];
    uploadSizeLimitMb?: number;
    validFolderNames?: string[];
}

const FileUpload: React.FC<IFileUploadProps> = ({
    disabled,
    validFolderNames,
    selectedVisitId,
    validFileExtensions = "*",
    folderConventionRegEx = [".*"],
    uploadSizeLimitMb = 0,
}) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const selectedTrial = useAppSelector(getSelectedTrial);
    const areAllFilesFinishedUploaded = useAppSelector(areAllFilesReady);
    const filesInProgress = useAppSelector(selectFileEntriesInProgress);
    const filesUploaded = useAppSelector(selectFileEntriesUploaded);
    const allFileEntries = useAppSelector(selectFileEntries);
    const fileEntriesGroupedBySession = useAppSelector(selectFileEntriesGroupedBySession);

    const [fileTypeError, setFileTypeError] = useState<{ title: string; body?: string }[] | null>(null);
    const [isDragActive, setIsDragActive] = useState(false);

    const setNewError = (error: { title: string; body?: string }) => {
        setFileTypeError((errors: any) => {
            const currentErrors = errors ? [...errors] : [];
            currentErrors.push(error);

            return currentErrors;
        });
    };

    const getFileExtension = (file: File) => file.name.split(".").pop();

    const isFileExtensionAccepted = (file: File) => {
        // All file extensions are valid
        if (validFileExtensions === "*" || validFileExtensions.includes("*")) return true;

        const fileExtension = getFileExtension(file);
        // if the file has not any mimeType use the file extension
        if (fileExtension) {
            // @ts-ignore
            return validFileExtensions.split(",").includes(fileExtension.toLowerCase());
        }

        return false;
    };

    const dropZoneBaseStyle = useMemo(() => {
        return {
            display: "flex",
            minHeight: 600,
            maxHeight: 600,
            flexDirection: "column" as "column",
            alignItems: "center",
            padding: "20px",
            borderWidth: 2,
            borderRadius: 2,
            borderColor: isDragActive ? (disabled ? "#ab0101" : "#8dc5f4") : "#335462",
            borderStyle: "dashed",
            color: "#bdbdbd",
            justifyContent: "space-between",
            outline: "none",
            transition: "border .24s ease-in-out",
        };
    }, [isDragActive]);

    // This function is in charge of initiating the upload request and adding the file entries to the redux store
    const addFilesSession = (files: File[], folderName: string) => {
        let fileEntries: IFileEntry[] = [];

        files.forEach((file) => {
            const abortController = new AbortController();
            const fileEntryId = nanoid();
            const sessionUuid = uuidv4();

            fileEntries.push({
                file: file,
                name: folderName,
                sessionUuid,
                size: file.size,
                uuid: fileEntryId,
                abortController,
                status: FileEntryStatus.QUEUED,
                groupId: selectedTrial!.uuid,
                visitId: selectedVisitId,
            });
        });

        dispatch(addFiles(fileEntries));
    };

    const isFolderNameValid = (folderName: string) => {
        return folderConventionRegEx.some((regexPattern) => new RegExp(regexPattern).test(folderName));
    };

    const isFileSizeValid = (fileSize: number) => fileSize < uploadSizeLimitMb;

    const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
        // Clean any previous error
        setFileTypeError(null);

        const unsupportedExtensions: string[] = [];
        const unsupportedFileSize: string[] = [];

        // Filter unsupported extensions
        const supportedFiles = Array.from(e.target.files || []).filter((file: File) => {
            const fileSizeInMb = file.size / 1000000;
            if (!isFileSizeValid(fileSizeInMb)) {
                unsupportedFileSize.push(file.name);
                return false;
            }

            if (getFileExtension(file) === "DS_Store") return false;

            const isFileExtensionValid = isFileExtensionAccepted(file);

            // filter mac hidden folder system files
            if (!isFileExtensionValid) {
                unsupportedExtensions.push(`.${getFileExtension(file)}`);
            }
            return isFileExtensionValid;
        });

        const hasUnsupportedFileSize = unsupportedFileSize.length > 0;
        const hasUnsupportedFileExtensions = unsupportedExtensions.length > 0;

        if (hasUnsupportedFileSize || hasUnsupportedFileExtensions) {
            setFileTypeError([
                ...(hasUnsupportedFileSize
                    ? [
                          {
                              title: `${unsupportedFileSize.join(
                                  ", "
                              )} exceed the ${uploadSizeLimitMb}mb file size limit.`,
                          },
                      ]
                    : []),
                ...(hasUnsupportedFileExtensions
                    ? [
                          {
                              title: `Folder contained some files with invalid extension: ${unsupportedExtensions.join(
                                  ", "
                              )}`,
                          },
                      ]
                    : []),
            ]);
        }

        // Group files by folder, in order to upload files from same folder with the same session ID
        const groupedFilesByFolder = groupBy(supportedFiles, (file) => {
            if (file.name.includes("_test_")) {
                if (file.name.includes("OD")) {
                    return `P2-73305-001_100_001_2022-02-02_OD/`;
                }

                if (file.name.includes("OS")) {
                    return `P2-73305-001_100_001_2022-02-02_OS/`;
                }

                return `P2-73305-001_100_001_2022-02-02/`;
            }

            return file.webkitRelativePath.replace(file.name, "");
        });

        const filterNotValidNamedFolders = Object.entries(groupedFilesByFolder).reduce((acc, [folderName, files]) => {
            const completeFolderPath = folderName.split("/");
            const tailEndFolderName = completeFolderPath[completeFolderPath.length - 2];

            if (!isFolderNameValid(tailEndFolderName)) {
                setNewError({
                    title: `Folder contained an invalid name: ${tailEndFolderName}`,
                    ...(validFolderNames && { body: `Valid examples: ${validFolderNames.join(", ")}` }),
                });

                return acc;
            }

            // filter out files without size
            const filesWithSize = files.filter((file) => file.size > 0);

            return {
                ...acc,
                [tailEndFolderName]: filesWithSize,
            };

            return acc;
        }, []);

        Object.entries(filterNotValidNamedFolders).forEach(([folderName, filesInFolder]) =>
            addFilesSession(filesInFolder, folderName)
        );

        // Clear value in order to be able to upload same file/value more than once.
        e.target.value = "";
    };

    const getFileFromEntry = (entry: any): Promise<File> => {
        return new Promise((resolve) => {
            entry.file((file: any) => resolve(file));
        });
    };

    const addFolder = async (folder: any, folderName: string) => {
        let files: File[] = [];
        return new Promise<void>(async (resolve, reject) => {
            const unsupportedExtensions: string[] = [];
            const unsupportedFolderName: string[] = [];
            const unsupportedFileSize: string[] = [];

            // Read folder entries
            folder.readEntries(async (entries: any) => {
                await Promise.all(
                    entries.map(async (entry: any) => {
                        // If entry is a file, push it to the file array
                        if (entry.isFile) {
                            const file = await getFileFromEntry(entry);

                            // filter mac hidden folder system files
                            if (getFileExtension(file) === "DS_Store") return;

                            const fileSizeInMb = file.size / 1000000;

                            if (!isFolderNameValid(folderName)) {
                                unsupportedFolderName.push(entry);
                                // setNewError({
                                //     title: `Folder contained an invalid name: ${folderName}`,
                                //     body: "Valid examples: Trial-Name_001_101_2022-04-01_OD_New, Trial-Name_001_101_2022-04-01_OD, Trial-Name_001_101_2022-04-01",
                                // });
                                return;
                            }

                            // filter out files without size
                            if (file.size === 0) return;

                            if (isFileExtensionAccepted(file)) {
                                files.push(file);
                            } else {
                                unsupportedExtensions.push(`.${getFileExtension(file)}`);
                                return;
                            }

                            if (!isFileSizeValid(fileSizeInMb)) {
                                unsupportedFileSize.push(file.name);
                                return;
                            }
                            // If entry is a folder, read the files in it to upload them under a new upload session
                        } else if (entry.isDirectory) {
                            const newDirectoryReader = entry.createReader();
                            await addFolder(newDirectoryReader, entry.name);
                        }
                    })
                );

                if (unsupportedFileSize.length > 0) {
                    setNewError({
                        title: `${unsupportedFileSize.join(", ")} exceed the ${uploadSizeLimitMb}mb file size limit.`,
                    });
                }

                if (unsupportedFolderName.length > 0 && !isFolderNameValid(folderName)) {
                    setNewError({
                        title: `Folder contained an invalid name: ${folderName}`,
                        ...(validFolderNames && { body: `Valid examples: ${validFolderNames.join(", ")}` }),
                    });
                }

                if (unsupportedExtensions.length > 0) {
                    setNewError({
                        title: `Folder contained some files with invalid extension: ${unsupportedExtensions.join(
                            ", "
                        )}`,
                    });
                }

                addFilesSession(files, folderName);
                resolve();
            });
        });
    };

    const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
        setFileTypeError(null);

        try {
            if (disabled) return;

            // Getting all the files/folders dropped
            const length = e.dataTransfer.files.length;
            // let files: File[] = [];

            // looping on each of the files/folders
            for (let i = 0; i < length; i++) {
                const entry = e.dataTransfer.items[i].webkitGetAsEntry();

                if (!entry) return;

                // If entry is file add it to the file array for posterior upload in the same upload session
                if (entry && entry.isFile) {
                    return;
                    // const file = e.dataTransfer.items[i].getAsFile() as File;
                    //
                    // if (isFileExtensionAccepted(file)) {
                    //     files.push(file);
                    // } else {
                    //     setFileTypeError("File type not valid.");
                    // }
                    // If entry is folder, add the files contained in the folder as a separate upload session
                } else if (entry.isDirectory) {
                    // @ts-ignore
                    const reader = entry.createReader();

                    addFolder(reader, entry.name);
                }
            }

            // addFilesSession(files, "sss");
        } finally {
            setIsDragActive(false);
        }
    };

    const handleDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        setIsDragActive(true);
    };

    const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        setIsDragActive(false);
    };

    const filesToBeUploaded = Object.entries(fileEntriesGroupedBySession).map(([sessionUuid, fileEntries]) => (
        <FileEntriesSession key={sessionUuid} sessionUuid={sessionUuid} fileEntries={fileEntries} />
    ));

    // const handleNextClick = () => dispatch(updateUploadState(UploadStep.FILE_VIEWER_READY_FOR_COMMIT));

    const handleNextClick = () => {
        dispatch(resetUploadSession());
        history.push("/patients");
    };

    const filesPercentageCompleted = useMemo(() => {
        const totalFiles = filesUploaded.length + filesInProgress.length;
        const filesLeftPercentage = filesInProgress.length / totalFiles;
        const filesUploadedPercentage = 1 - filesLeftPercentage;

        // This is just a hack to show at least something on the circular progress
        if (filesUploadedPercentage === 0) return 1;

        return filesUploadedPercentage * 100;
    }, [filesInProgress, filesUploaded]);

    return (
        <>
            {fileTypeError
                ? fileTypeError.map((error, i) => (
                      <Box key={i} sx={{ my: 12 }}>
                          <Alert
                              onClose={() =>
                                  setFileTypeError((errors) => {
                                      const currentErrors = [...(errors as [])];
                                      currentErrors.splice(i, 1);

                                      return currentErrors;
                                  })
                              }
                              severity="error"
                          >
                              <AlertTitle>{error.title}</AlertTitle>
                              {error.body}
                              {/*{fileTypeError.body && <DisplayText  type={fileTypeError.body} type="bodyMedium" /> }*/}
                          </Alert>
                      </Box>
                  ))
                : null}
            <Box
                sx={dropZoneBaseStyle}
                className="container"
                onDrop={handleDrop}
                onDragOver={(e) => e.preventDefault()}
                onDragEnter={handleDragEnter}
                onDragLeave={handleDragLeave}
            >
                {allFileEntries.length === 0 ? (
                    <Box
                        sx={{
                            pointerEvents: "none",
                            flex: 1,
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                        }}
                    >
                        <Box sx={{ position: "relative", right: 20, display: "flex", justifyContent: "center" }}>
                            <UploadDrawing size={200} />
                        </Box>
                        <Typography sx={{ my: 32, textAlign: "center" }} variant="subtitle1">
                            Drag 'n' drop some files here, or click to select files/folders.
                        </Typography>
                    </Box>
                ) : null}
                <Box sx={{ width: "100%", my: -4, overflow: "auto" }}>{filesToBeUploaded}</Box>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                        mx: -4,
                        mt: 16,
                    }}
                >
                    {/*<Box*/}
                    {/*    sx={{*/}
                    {/*        mx: 4,*/}
                    {/*    }}*/}
                    {/*>*/}
                    {/*    <Button disabled={disabled} component="label" variant="outlined" startIcon={<UploadIcon />}>*/}
                    {/*        Add File(s)*/}
                    {/*        <input*/}
                    {/*            hidden*/}
                    {/*            accept={validFileExtensions.join(',')}*/}
                    {/*            onChange={handleFileUpload}*/}
                    {/*            type="file"*/}
                    {/*            multiple*/}
                    {/*        />*/}
                    {/*    </Button>*/}
                    {/*</Box>*/}
                    <Box
                        sx={{
                            mx: 4,
                        }}
                    >
                        <Button disabled={disabled} component="label" variant="outlined" startIcon={<UploadIcon />}>
                            Add Folder(s)
                            <input
                                hidden
                                onChange={handleFileUpload}
                                type="file"
                                /* @ts-ignore */
                                directory=""
                                webkitdirectory=""
                                multiple
                            />
                        </Button>
                    </Box>
                    <Box sx={{ marginLeft: "auto", display: "flex" }}>
                        {filesPercentageCompleted < 100 ? (
                            <Box sx={{ mr: 16 }}>
                                <CircularProgress size={32} variant="determinate" value={filesPercentageCompleted} />
                            </Box>
                        ) : null}
                        <Button
                            disabled={!areAllFilesFinishedUploaded}
                            onClick={handleNextClick}
                            component="button"
                            variant="contained"
                        >
                            Done
                        </Button>
                    </Box>
                </Box>
            </Box>
        </>
    );
};

export default FileUpload;
