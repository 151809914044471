import React from "react";
import { Redirect, Route } from "react-router-dom";
import { isUserLoggedIn } from "../loginSlice";
import { useAppSelector } from "../../../../app/hooks";
import { Permission } from "../../../permissions/usePermissions";
import { useGetUserDataQuery } from "../../dataAccess";
import { CircularProgress } from "@mui/material";
import { Box } from "@mui/system";
import NavBar from "../../../navbar/NavBar";
import usePermissions from "../../../permissions/usePermissions";
import useRoles, { Role } from "../../../permissions/useRoles";
import { AlertBanner } from "../../../Alerts/AlertBanner";

interface IProtectedRouteProps {
    path: string;
    exact?: boolean;
    children: React.ReactElement;
    permissions?: Permission[];
    role?: Role;
}
const ProtectedRoute: React.FC<IProtectedRouteProps> = ({ path, exact, children, permissions, role }) => {
    const loggedIn = useAppSelector(isUserLoggedIn);
    const { hasPermissions } = usePermissions();
    const { isLoading: userDataIsLoading } = useGetUserDataQuery(undefined, { skip: !loggedIn });
    const { userHasRole } = useRoles();

    if (!loggedIn) return <Redirect to="/login" />;

    if (userDataIsLoading) {
        return (
            <Box
                sx={{
                    height: "100vh",
                    width: "100vw",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <CircularProgress />
            </Box>
        );
    }

    if (permissions) {
        const userHasPermissionsToAccessPage = hasPermissions(permissions);

        if (!userHasPermissionsToAccessPage) {
            return (
                <Box
                    sx={{
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    Don't have permissions to access this page.
                </Box>
            );
        }
    }

    if (role) {
        const userHasRoleToAccessPage = userHasRole(role);

        if (!userHasRoleToAccessPage) {
            return (
                <Box
                    sx={{
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    Don't have permissions to access this page.
                </Box>
            );
        }
    }

    return (
        <Route path={path} exact={exact}>
            <Box sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
                <NavBar />
                <AlertBanner />
                {children}
            </Box>
        </Route>
    );
};

export default ProtectedRoute;
