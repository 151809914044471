import React, { useCallback, useEffect, useMemo, useState } from "react";
import { IPatient, sortPatients, selectPatientSort, selectCurrentPatient } from "./patientsSlice";
import { useGetPatientsQuery, useGetTagsQuery } from "./dataAccess";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { TableContainer } from "@mui/material";
import theme from "../../app/theme";
import { Box } from "@mui/system";
import { useUpdateCurrentGroupMutation } from "../upload/dataAccess";
import DisplayText from "../../components/DisplayText/DisplayText";
import { getSelectedTrial } from "../auth/login/loginSlice";
import PatientTable from "./PatientTable";
import PatientSearch from "./PatientSearch";
import { getNestedProperty } from "../../utils/Array";
import useQueryParams from "../../hooks/useQueryParams/useQueryParams";
import useFilters, { FilterGroup, FilterOption, FilterType } from "./useFilters";
import usePermissions from "../permissions/usePermissions";
import { useGetWorkflowStatesQuery } from "../workflow/dataAccess";
import { getCommaSeparatedPatientName } from "./utils";

const PatientsDashboard = () => {
    const dispatch = useAppDispatch();
    const { hasPermissions } = usePermissions();
    const selectedTrial = useAppSelector(getSelectedTrial);

    const { data: workflowSates } = useGetWorkflowStatesQuery(selectedTrial?.uuid || "", {
        skip: !selectedTrial || !selectedTrial.workflowActive,
        refetchOnMountOrArgChange: true,
    });

    const { data: tagOptions } = useGetTagsQuery(selectedTrial?.uuid || "", {
        skip: !selectedTrial || !selectedTrial.showTags,
        refetchOnMountOrArgChange: true,
    });
    const { removeQueryParam, setQueryParam } = useQueryParams();

    const patientSort = useAppSelector(selectPatientSort);
    const currentPatient = useAppSelector(selectCurrentPatient);
    const { setFilters, activeFilters, updateFilter, updateActiveFilterOptions, applyFilters } = useFilters();

    useEffect(() => {
        if (!selectedTrial) return;

        const filters = [
            ...(hasPermissions(["QC"])
                ? [
                      {
                          key: "qc",
                          label: "Hide QC images?",
                          type: FilterType.AND,
                          filterOptions: [{ value: "true", label: "Yes" }],
                          onFilter: (patient: IPatient) => {
                              return !patient.isQc;
                          },
                      },
                  ]
                : []),
            ...(selectedTrial.showTags
                ? [
                      {
                          key: "tags",
                          label: "Trial Tags",
                          type: FilterType.AND,
                          filterOptions: null,
                          onFilter: (patient: IPatient, filterOption: FilterOption): boolean => {
                              return patient.tags.some((tag) => tag.uuid === filterOption.value);
                          },
                      },
                  ]
                : []),
            ...(selectedTrial.workflowActive
                ? [
                      {
                          key: "workflowState",
                          label: "Workflow States",
                          type: FilterType.OR,
                          filterOptions: null,
                          onFilter: (patient: IPatient, filterOption: FilterOption) => {
                              return patient?.workflowStates.some(
                                  (workflowState) => workflowState.value === filterOption.value
                              );
                          },
                      },
                  ]
                : []),
        ];

        const savedFilters = localStorage.getItem("activeFilters");

        if (savedFilters) {
            const parsedFilters = JSON.parse(savedFilters);
            const updatedFilters = filters.map((filter) => {
                const savedFilter = parsedFilters[filter.key];
                if (savedFilter) {
                    return {
                        ...filter,
                        activeFilterOptions: savedFilter,
                    };
                }
                return filter;
            });
            setFilters(updatedFilters);
        } else {
            setFilters(filters as FilterGroup[]);
        }
    }, [selectedTrial]);

    useEffect(() => {
        if (!workflowSates) return;

        const workflowStatesOptions = workflowSates?.map((state: any) => ({
            value: state.value,
            label: state.label,
        }));

        updateFilter("workflowState", { filterOptions: workflowStatesOptions });
    }, [workflowSates, selectedTrial]);

    useEffect(() => {
        if (!tagOptions) return;

        const tagOptionsList = tagOptions.map((tag: any) => ({
            value: tag.uuid,
            label: tag.label,
        }));

        updateFilter("tags", { filterOptions: tagOptionsList });
    }, [tagOptions, selectedTrial]);

    const handleFilterChange = (filterKey: string, selectedOptionValues: FilterOption[]) => {
        updateActiveFilterOptions(filterKey, selectedOptionValues);
        const savedFilters = localStorage.getItem("activeFilters");

        if (savedFilters) {
            const parsedFilters = JSON.parse(savedFilters);
            const updatedFilters = {
                ...parsedFilters,
                [filterKey]: selectedOptionValues,
            };

            return localStorage.setItem("activeFilters", JSON.stringify(updatedFilters));
        }

        return localStorage.setItem("activeFilters", JSON.stringify({ [filterKey]: selectedOptionValues }));
    };

    const [searchQuery, setSearchQuery] = useState<string>("");

    const {
        data: patients,
        currentData: isFetchingPatientsFromAnotherTrial,
        isLoading: patientsLoading,
        isUninitialized: patientsUninitialized,
    } = useGetPatientsQuery(selectedTrial?.uuid || "", {
        refetchOnFocus: true,
        refetchOnReconnect: true,
        refetchOnMountOrArgChange: true,
        pollingInterval: 60_000,
        skip: !selectedTrial,
    });
    const [, { isLoading: isChangingGroup }] = useUpdateCurrentGroupMutation({ fixedCacheKey: "groupChange" });

    const filteredPatients = useMemo(() => {
        if (!patients) return [];
        return applyFilters(patients);
    }, [patients, activeFilters]);

    const handlePatientClick = useCallback((event: React.MouseEvent<HTMLElement>, patient: IPatient) => {
        event.preventDefault();
        if (currentPatient?.uuid === patient["uuid"]) {
            removeQueryParam("selectedPatient", true);
        } else {
            setQueryParam("selectedPatient", patient.uuid, true);
        }
    }, []);

    const handleSort = useCallback(
        (event: React.MouseEvent<HTMLElement>, field: keyof IPatient) => {
            const newOrder = patientSort.order === "desc" ? "asc" : "desc";
            dispatch(sortPatients({ field, order: newOrder }));
        },
        [patientSort.order]
    );

    const handleSearchQueryChange = useCallback((value: string) => {
        setSearchQuery(value);
    }, []);

    const tableIsLoading = useMemo(() => {
        return patientsLoading || patientsUninitialized || !isFetchingPatientsFromAnotherTrial;
    }, [patientsLoading, isChangingGroup, isFetchingPatientsFromAnotherTrial]);

    const sortedPatients = useMemo(() => {
        return [...(filteredPatients || [])]
            .sort((patient1: IPatient, patient2: IPatient) => {
                const fieldValue1 = getNestedProperty(patient1, patientSort.field);
                const fieldValue2 = getNestedProperty(patient2, patientSort.field);

                // Determine the sort direction based on patientSort.order
                const multiplier = patientSort.order === "desc" ? -1 : 1;

                if (fieldValue1 > fieldValue2) return multiplier;
                if (fieldValue1 < fieldValue2) return -multiplier;

                return 0;
            })
            .filter((patient: IPatient) => {
                const searchQuerySplit = searchQuery.toLowerCase().split(":");
                if (searchQuerySplit.length > 1) {
                    const searchField = searchQuerySplit[0];
                    const searchValue = searchQuerySplit[1];
                    if (searchField === "subject") {
                        return patient.patientId.toLowerCase().includes(searchValue.toLowerCase());
                    }
                    if (searchField === "site") {
                        return patient.site.toLowerCase().includes(searchValue.toLowerCase());
                    }
                }
                const patientName = getCommaSeparatedPatientName(patient.firstName, patient.lastName);
                return (
                    patientName.toLowerCase().includes(searchQuery.toLowerCase()) ||
                    patient.patientId.toLowerCase().includes(searchQuery.toLowerCase()) ||
                    patient.site.toLowerCase().includes(searchQuery.toLowerCase())
                );
            });
    }, [filteredPatients, patientSort, searchQuery]);

    return (
        <React.Fragment>
            <TableContainer sx={{ padding: "0 4px" }}>
                <PatientSearch
                    activeFilters={activeFilters}
                    onFilterChange={handleFilterChange}
                    onQueryChange={handleSearchQueryChange}
                    searchQueryValue={searchQuery}
                />
                <PatientTable
                    onSort={handleSort}
                    isLoading={tableIsLoading}
                    onPatientClick={handlePatientClick}
                    patients={sortedPatients}
                />
            </TableContainer>
            {(filteredPatients?.length && filteredPatients?.length > 0) || tableIsLoading ? null : (
                <Box
                    sx={{
                        flex: 1,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                    }}
                >
                    <DisplayText text="No patients found" type="bodyLarge" />
                    {Array.from(activeFilters.values()).some((filter) => filter?.activeFilterOptions?.length) && (
                        <DisplayText text="(Check active filters applied)" type="bodySmall" />
                    )}
                </Box>
            )}
        </React.Fragment>
    );
};

export default PatientsDashboard;
