import { useContext } from "react";
import ConfirmationModalContext from "./ConfirmationModalContext";

const useConfirmationModal = ({
    title,
    content,
    acceptLabel,
    cancelLabel,
}: {
    title?: string;
    content?: string;
    acceptLabel?: string;
    cancelLabel?: string;
}) => {
    const { openConfirmationModal, isOpen } = useContext(ConfirmationModalContext);

    return {
        openConfirmationModal: (onAccept: (...args: any) => void) =>
            openConfirmationModal({ title, content, onAccept, acceptLabel, cancelLabel }),
        isOpen,
    };
};

export default useConfirmationModal;
