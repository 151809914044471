import React from "react";
import CommentsDialog from "../../components/CommentsDialog/CommentsDialog";

interface StudyCommentsDialogProps {
    comments: any[];
    onClose: () => void;
    onAdd: (newComment: string) => Promise<void>;
    isOpen: boolean;
    disablePortal?: boolean;
}

const StudyCommentsDialog: React.FC<StudyCommentsDialogProps> = ({
    comments,
    onClose,
    onAdd,
    isOpen,
    disablePortal = false,
}) => {
    const handleClose = () => {
        onClose();
    };

    const handleAddComment = (newComment: string) => {
        return onAdd(newComment);
    };

    if (!isOpen) {
        return null;
    }

    return (
        <CommentsDialog
            onClose={handleClose}
            comments={comments}
            canAddComments={true}
            onAdd={handleAddComment}
            disablePortal={disablePortal}
        />
    );
};

export default StudyCommentsDialog;
