import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Box, Button } from "@mui/material";
import StudyCard from "./StudyCard/StudyCard";
import theme from "../../app/theme";
import { IEncounter, ISerie, IStudy } from "./studiesSlice";
import DisplayText from "../../components/DisplayText/DisplayText";
import Divider from "@mui/material/Divider";
import { prettyDate } from "../../utils/Dates";
import { figureFlavor } from "../../utils/MediaUtils";
import { common, grey } from "@mui/material/colors";
import { FixedSizeGrid as Grid } from "react-window";
import { Upload as UploadIcon } from "@mui/icons-material";
import { styled } from "@mui/styles";
import { useDebounce } from "use-debounce";

const GridCell = React.memo(
    ({
        // @ts-ignore
        columnIndex,
        // @ts-ignore
        rowIndex,
        // @ts-ignore
        style,
        // @ts-ignore
        data: { orderedMedias, onCheckboxSelect, selectedStudies, visit, columnCount },
    }) => {
        const gap = 10;
        const mediaIndex = rowIndex * columnCount + columnIndex;
        if (mediaIndex >= orderedMedias.length) return null; // Prevent rendering outside bounds

        const media = orderedMedias[mediaIndex];
        return (
            <div
                key={media.uuid}
                style={{
                    ...style,
                    left: style.left + gap,
                    top: style.top + gap,
                    width: style.width - gap,
                    height: style.height - gap,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <StudyCard
                    key={media.uuid}
                    isLoading={false}
                    error={false}
                    token={"131"}
                    selected={selectedStudies.has(media.uuid)}
                    actionsDisable={selectedStudies.size > 0}
                    onCheckboxSelect={onCheckboxSelect}
                    visit={visit}
                    media={media}
                />
            </div>
        );
    }
);

const HiddenInput = styled("input")({
    display: "none",
});

interface StudyCardProps {
    studies: any;
    visit: IEncounter;
    onAttach: (studyId: string, file: File) => Promise<void>;
    onCheckboxSelect: (id: string[], isChecked: boolean) => void;
    selectedStudies: Set<string>;
}

const VisitPanel: React.FC<StudyCardProps> = ({ studies, onCheckboxSelect, visit, selectedStudies, onAttach }) => {
    const containerRef = useRef();

    const handleAttachFile = (studyId: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
        // @ts-ignore
        const file = e.target.files[0];

        onAttach(studyId, file);
    };

    const [containerWidth, setContainerWidth] = useState(1500); // Default width
    const [debouncedContainerWidth] = useDebounce(containerWidth, 100);

    useEffect(() => {
        const resizeObserver = new ResizeObserver((entries) => {
            for (let entry of entries) {
                setContainerWidth(entry.contentRect.width);
            }
        });
        if (containerRef.current) {
            resizeObserver.observe(containerRef.current);
        }
        return () => {
            resizeObserver.disconnect();
        };
    }, []);

    const renderMedias = (study: IStudy) => {
        const medias = study.series.map((serie: ISerie) => serie.medias).flat();

        const orderedMedias = medias.sort((a: any, b: any) => {
            const aFlavor = figureFlavor(a);
            const bFlavor = figureFlavor(b);

            // Compare by flavor.
            const flavorComparison = aFlavor!.localeCompare(bFlavor!);

            // If flavors are the same, compare by laterality.
            if (flavorComparison === 0) {
                return a.laterality.localeCompare(b.laterality);
            }

            return flavorComparison;
        });

        const gap = 10;
        const columnCount = Math.max(1, Math.floor(debouncedContainerWidth / 300)); // Ensure at least one column
        const columnWidth = debouncedContainerWidth / columnCount - gap + gap / columnCount; // Column width based on container width
        const rowHeight = 350 + gap; // Adjust based on the card height

        return (
            <Box ref={containerRef} key={study.uuid}>
                <Box
                    sx={{
                        "display": "flex",
                        "justifyContent": "space-between",
                        "alignItems": "center",
                        "marginTop": 16,
                        "&::-webkit-scrollbar": {
                            display: "none",
                        },
                    }}
                >
                    <DisplayText
                        type="bodySmall"
                        variant="semiBold"
                        style={{ marginTop: 16 }}
                        text={`Study from ${prettyDate(new Date(study.createdOn).toString())}`}
                    />
                    <label>
                        <HiddenInput
                            onChange={handleAttachFile(study.uuid)}
                            type="file"
                            accept=".csv,.png,.tif,.tiff,.jpg,.jpeg,.txt,.pdf,.mp4"
                        />
                        <Button
                            size="small"
                            sx={{
                                "height": 28,
                                "position": "relative",
                                "top": 3,
                                "color": common.white,
                                "alignItems": "center",
                                "border": `1px solid ${common.white}`,
                                "&:hover": {
                                    borderColor: grey[300],
                                },
                            }}
                            variant="outlined"
                            component="span"
                            startIcon={<UploadIcon />}
                        >
                            attach file
                        </Button>
                    </label>
                </Box>
                <Divider sx={{ my: 6 }} />
                <Box
                    sx={{
                        "& .ReactWindowGrid": {
                            // Replace 'ReactWindowGrid' with the actual class name if different
                            "&::-webkit-scrollbar": {
                                display: "none",
                            },
                            "scrollbarWidth": "none", // For Firefox
                            "msOverflowStyle": "none", // For Internet Explorer and Edge
                        },
                    }}
                >
                    <Grid
                        className="ReactWindowGrid"
                        columnCount={columnCount}
                        columnWidth={columnWidth}
                        height={720} // Adjust based on your container's height
                        rowCount={Math.ceil(orderedMedias.length / columnCount)}
                        rowHeight={rowHeight}
                        width={debouncedContainerWidth + gap}
                        itemData={{
                            orderedMedias,
                            columnCount,
                            onCheckboxSelect: onCheckboxSelect,
                            selectedStudies: selectedStudies,
                            visit: visit,
                            // Include any other data GridCell needs
                        }}
                    >
                        {GridCell}
                    </Grid>
                </Box>
            </Box>
        );
    };

    return (
        <Box
            sx={{
                color: theme.palette.text.secondary,
                mt: -16,
            }}
        >
            {studies.length > 0 ? studies.map(renderMedias) : <DisplayText type="bodyMedium" text="No Files" />}
        </Box>
    );
};

export default React.memo(VisitPanel);
