import React, { ChangeEvent, useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { selectUploadState, UploadStep, resetUploadSession, selectFileEntries } from "./uploadSlice";
import { useGetTrialVisitsQuery, useGetUploadConfigQuery } from "./dataAccess";
import {
    MenuItem,
    Select,
    SelectChangeEvent,
    Typography,
    Container,
    FormControl,
    InputLabel,
    Checkbox,
    FormControlLabel,
} from "@mui/material";
import theme from "../../app/theme";
import FileUpload from "./components/FileUpload/FileUpload";
import { Box } from "@mui/system";
import FileViewer from "./components/FileViewer/FileViewer";
import { useConfirmationModal } from "../../components/ConfirmationDialog";
import { getSelectedTrialId } from "../auth/login/loginSlice";

const Upload = () => {
    const uploadStep = useAppSelector(selectUploadState);
    const selectedTrialId = useAppSelector(getSelectedTrialId);
    const [checkboxChecked, setCheckboxChecked] = useState(false);

    const dispatch = useAppDispatch();
    const [selectedVisitId, setSelectedVisitId] = useState<string>("");
    const fileEntries = useAppSelector(selectFileEntries);

    const { openConfirmationModal } = useConfirmationModal({
        title: "You upload will be cancelled",
        content: "Changing the visit will cancel this upload.",
        acceptLabel: "Change Visit",
        cancelLabel: "Go back",
    });

    const { data: uploadConfig, isFetching: uploadConfigLoading } = useGetUploadConfigQuery(selectedTrialId);
    const { data: trialVisits } = useGetTrialVisitsQuery(selectedTrialId);

    const handleVisitIdentifierChange = (e: SelectChangeEvent) => {
        const selectVisit = () => setSelectedVisitId(e.target.value);

        if (fileEntries.length > 0)
            return openConfirmationModal(() => {
                dispatch(resetUploadSession());
                selectVisit();
            });

        selectVisit();
    };

    useEffect(() => {
        setSelectedVisitId("");
    }, [trialVisits]);

    const renderUploadStep = () => {
        if (uploadStep === UploadStep.FILE_UPLOAD) {
            return (
                <div>
                    <FileUpload
                        validFileExtensions={uploadConfig?.validExtensions?.join(",")}
                        folderConventionRegEx={uploadConfig?.folderConventionRegEx}
                        uploadSizeLimitMb={uploadConfig?.uploadSizeLimitMb}
                        validFolderNames={uploadConfig?.validExampleFolders}
                        selectedVisitId={selectedVisitId}
                        disabled={!selectedVisitId}
                    />
                </div>
            );
        }

        return <FileViewer />;
    };

    const renderVisitIdentifiersItems = (uuid: string, label: string, active: Boolean) => (
        <MenuItem key={uuid} value={uuid} disabled={!active}>
            {label}
        </MenuItem>
    );

    const visitItems = useMemo(() => {
        if (!trialVisits) return;

        return trialVisits.map(({ uuid, visitName, active }) => renderVisitIdentifiersItems(uuid, visitName, active));
    }, [trialVisits]);

    const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (!checkboxChecked) {
            setCheckboxChecked(event.target.checked);
        }
    };

    return (
        <Container sx={{ padding: theme.spacing(20) }} component="main" maxWidth="lg">
            <Box sx={{ p: 20 }}>
                <Typography variant="h5" gutterBottom component="h5">
                    File Upload
                </Typography>
                <Typography variant="subtitle1" gutterBottom component="p">
                    Files will be assigned to visit:
                </Typography>
                <Box sx={{ display: "flex", gap: 10 }}>
                    <FormControl sx={{ m: 1, minWidth: 250 }}>
                        <InputLabel sx={{ color: !checkboxChecked || uploadConfigLoading ? "#626872" : "inherit" }}>
                            {uploadConfigLoading ? "Loading..." : "Select visit"}
                        </InputLabel>
                        <Select
                            autoWidth={false}
                            label="Select visit"
                            id="selectVisitDropdown"
                            data-test-id="upload:visitSelector"
                            value={selectedVisitId}
                            onChange={handleVisitIdentifierChange}
                            disabled={!checkboxChecked || uploadConfigLoading}
                        >
                            {visitItems}
                        </Select>
                    </FormControl>
                    <FormControlLabel
                        control={
                            <Checkbox
                                sx={{
                                    color: "#626872", // changes the color of the checkbox when it's checked
                                }}
                                checked={checkboxChecked}
                                onChange={handleCheckboxChange}
                            />
                        }
                        label="I confirm I am submitting the best available images captured at this visit."
                    />
                </Box>

                <Box sx={{ mt: 16 }}>{renderUploadStep()}</Box>
            </Box>
        </Container>
    );
};

export default Upload;
