import { styled } from "@mui/styles";
import {
    Accordion,
    AccordionDetails,
    AccordionProps,
    AccordionSummary,
    AccordionSummaryProps,
    Badge,
    Checkbox,
    Chip,
    IconButton,
} from "@mui/material";
import { ArrowForwardIosSharp, ExpandMore } from "@mui/icons-material";
import { IEncounter, IStudy } from "../studiesSlice";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useSnackbar } from "notistack";
import { useAppSelector } from "../../../app/hooks";
import { selectCurrentPatient } from "../../patients/patientsSlice";
import useRoles from "../../permissions/useRoles";
import { useAddEncounterCommentMutation, useAttachFileMutation, useDeleteVisitMutation } from "../dataAccess";
import { getSelectedTrial } from "../../auth/login/loginSlice";
import {
    useGetVisitPossibleTransitionsQuery,
    useGetVisitStateQuery,
    useTransitionVisitMutation,
} from "../../workflow/dataAccess";
import { figureFlavor } from "../../../utils/MediaUtils";
import { Box } from "@mui/system";
import WorkflowTransition from "../WorkflowTransitionDialog";
import EncounterCommentsDialog from "../EncounterCommentsDialog";
import WorkflowhistoryDialog from "../WorkflowHistoryDialog";
import DisplayText from "../../../components/DisplayText/DisplayText";
import WorkflowDropdown from "../WorkflowDropdown";
import ToolTip from "../../../components/Tooltip/Tooltip";
import ListIcon from "@mui/icons-material/List";
import DeleteIcon from "@mui/icons-material/Delete";
import CommentIcon from "@mui/icons-material/Comment";
import AddCommentIcon from "@mui/icons-material/AddComment";

import VisitPanel from "../VisitPanel";
import AnimatedBorderBox from "../../../components/AnimatedBorderBox/AnimatedBorderBox";
import { useConfirmationModal } from "../../../components/ConfirmationDialog";

const CustomAccordion = styled((props: AccordionProps) => <Accordion disableGutters elevation={1} square {...props} />)(
    () => ({
        "backgroundColor": "#1f2735",
        "&::before": {
            content: '""',
            display: "none", // Hide any existing ::before pseudo-element
        },
    })
);

const CustomAccordionSummary = styled((props: AccordionSummaryProps) => (
    <AccordionSummary expandIcon={<ArrowForwardIosSharp sx={{ fontSize: "0.9rem" }} />} {...props} />
))(() => ({
    "flexDirection": "row-reverse",
    "& .MuiAccordionSummary-content": {
        margin: "0px",
    },
}));

interface IVisitAccordionProps {
    visit: IEncounter;
    title: string;
    isDefaultOpen?: boolean;
    isSelected?: boolean;
    onSelect: (ids: string[], checked: boolean) => void;
    selectedStudies: Set<string>;
}

const VisitAccordion: React.FC<IVisitAccordionProps> = ({
    visit,
    title,
    isDefaultOpen = false,
    isSelected = false,
    onSelect,
    selectedStudies,
}) => {
    const [expanded, setExpanded] = React.useState<boolean>(isDefaultOpen);
    const { closeSnackbar, enqueueSnackbar } = useSnackbar();
    const [commentsOpen, setCommentsOpen] = useState(false);
    const currentPatient = useAppSelector(selectCurrentPatient);
    const { userHasRole } = useRoles();
    const [attachFile] = useAttachFileMutation();
    const selectedTrial = useAppSelector(getSelectedTrial);
    const [addEncounterComment] = useAddEncounterCommentMutation();
    const [selectedTransition, setSelectedTransition] = useState("");
    const [siteWorkflowHistoryOpen, setSiteWorkflowHistoryOpen] = useState(false);
    const { data: visitState } = useGetVisitStateQuery(
        { visitId: visit.visitId, patientId: currentPatient.uuid },
        {
            pollingInterval: 60_000,
            skip: selectedTrial?.workflowActive === false,
        }
    );
    const { data: visitPossibleTransitions } = useGetVisitPossibleTransitionsQuery(
        {
            visitId: visit.visitId,
            patientId: currentPatient.uuid,
        },
        {
            pollingInterval: 60_000,
            skip: selectedTrial?.workflowActive === false,
        }
    );
    const [transitionVisit] = useTransitionVisitMutation();
    const [deleteVisit] = useDeleteVisitMutation();
    const { openConfirmationModal } = useConfirmationModal({
        title: `Are you sure you want to delete the visit`,
        content: "",
        acceptLabel: "Remove",
    });

    const medias = useMemo(() => {
        // unwrap studies, series and medias from encounter
        return visit.studies
            .map((study: any) => study.series.map((serie: any) => serie.medias.map((media: any) => media)).flat(2))
            .flat(2);
    }, [visit]);

    const studies = useMemo(() => {
        // return the studies of the visit but with their medias sorted by flavor and laterality
        return (
            visit.studies
                .map((study: any) => ({
                    ...study,
                    series: [...study.series].map((serie: any) => ({
                        ...serie,
                        medias: [...serie.medias].sort((a: any, b: any) => {
                            const aFlavor = figureFlavor(a);
                            const bFlavor = figureFlavor(b);

                            // Compare by flavor.
                            const flavorComparison = aFlavor!.localeCompare(bFlavor!);

                            // If flavors are the same, compare by laterality.
                            if (flavorComparison === 0) {
                                return a.laterality.localeCompare(b.laterality);
                            }

                            return flavorComparison;
                        }),
                    })),
                }))
                // sort studies by date
                .sort((a: IStudy, b: IStudy) => {
                    return new Date(b.createdOn).getTime() - new Date(a.createdOn).getTime();
                })
        );
    }, [visit]);

    const handleAccordionChange = useCallback((event: React.SyntheticEvent, expanded: boolean) => {
        setExpanded(expanded);
    }, []);

    const handleSelectAllStudies = useCallback((event: any) => {
        event.stopPropagation();
        const isChecked = event.target.checked;

        const studiesIds = medias.filter((media) => media.hidden === false).map((media) => media.uuid);

        onSelect(studiesIds, isChecked);
    }, []);

    const selectionState = useMemo(() => {
        const studiesIds = medias.filter((media) => media.hidden === false).map((media) => media.uuid);

        const selectedStudyInVisit = Array.from(selectedStudies).filter((selectedStudyId) =>
            studiesIds.includes(selectedStudyId)
        );

        if (selectedStudyInVisit.length === 0) return "NONE";

        if (selectedStudyInVisit.length === studiesIds.length) return "ALL";

        return "SOME";
    }, [selectedStudies]);

    const handleAttachFile = useCallback(async (studyId: string, file: File) => {
        enqueueSnackbar("Attaching file...", {
            variant: "info",
            persist: true,
        });

        try {
            const formData = new FormData();
            formData.append("key", studyId);
            formData.append("fileData", file);

            await attachFile(formData).unwrap();
            closeSnackbar();
        } catch (e) {
            closeSnackbar();
            enqueueSnackbar("Something went wrong.", {
                variant: "error",
            });
        }
    }, []);

    const handleOpenComments = useCallback((e: any) => {
        e.stopPropagation();
        setCommentsOpen(true);
    }, []);

    const handleCloseComments = useCallback(() => {
        setCommentsOpen(false);
    }, []);

    const handleAddComment = useCallback((newComment: string) => {
        return addEncounterComment({
            patientId: currentPatient.uuid,
            encounterId: visit.uuid,
            comment: newComment,
        }).unwrap();
    }, []);

    const hasComments = useMemo(() => {
        return visit.comments && visit.comments.length > 0;
    }, [visit]);

    const canAddComment = useMemo(() => {
        return (
            userHasRole("SITE_USER") ||
            userHasRole("PROJECT_MANAGER") ||
            userHasRole("READER") ||
            userHasRole("SUPER_READER")
        );
    }, [userHasRole]);

    const canReadComments = useMemo(() => {
        return (
            userHasRole("SITE_USER") ||
            userHasRole("PROJECT_MANAGER") ||
            userHasRole("READER") ||
            userHasRole("SUPER_READER") ||
            userHasRole("CRA")
        );
    }, [userHasRole]);

    const handleOpenTransitionDialog = useCallback((event: any) => {
        event.stopPropagation();
        setSelectedTransition(event.target.value as string);
    }, []);

    const handleCloseTransitionDialog = () => {
        setSelectedTransition("");
    };

    const handleTransitionState = (transitionMessage = "") => {
        const transitionToMake = visitPossibleTransitions?.find(
            (transition) => transition.value === selectedTransition
        ) as any;
        return transitionVisit({
            patientId: currentPatient.uuid,
            visitId: visit.visitId,
            transition: transitionToMake,
            message: transitionMessage,
        }).unwrap();
    };

    const handleDeleteVisit = async (e: any) => {
        e.stopPropagation();
        return openConfirmationModal(async () => {
            try {
                await deleteVisit({
                    patientId: currentPatient.uuid,
                    visitId: visit.visitId,
                }).unwrap();
            } catch (e) {
                enqueueSnackbar("Something went wrong.", {
                    variant: "error",
                });
            }
        });
    };

    const handleOpenWorkflowHistory = useCallback((e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation();
        setSiteWorkflowHistoryOpen(true);
    }, []);

    const handleCloseWorkflowHistory = () => {
        setSiteWorkflowHistoryOpen(false);
    };

    const [isBorderVisible, setIsBorderVisible] = useState(isSelected);
    const targetRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (targetRef.current && isSelected) {
            targetRef.current.scrollIntoView({ behavior: "smooth" });
        }
    }, [targetRef, isSelected]);

    useEffect(() => {
        let timer: any;
        if (isSelected) {
            setIsBorderVisible(true);
            timer = setTimeout(() => {
                setIsBorderVisible(false);
            }, 2000);
        }
        return () => clearTimeout(timer);
    }, [isSelected]);

    return (
        <AnimatedBorderBox showBorder={isBorderVisible}>
            <Box ref={targetRef} />
            {selectedTransition && (
                <WorkflowTransition
                    onClose={handleCloseTransitionDialog}
                    transition={selectedTransition}
                    onTransition={handleTransitionState}
                    visitId={visit.visitId}
                />
            )}
            {commentsOpen && (
                <EncounterCommentsDialog
                    onClose={handleCloseComments}
                    comments={visit.comments}
                    onAddComment={handleAddComment}
                />
            )}
            {siteWorkflowHistoryOpen && (
                <WorkflowhistoryDialog
                    visitName={visit.visitName}
                    onClose={handleCloseWorkflowHistory}
                    visitId={visit.visitId}
                    patientId={currentPatient.uuid}
                />
            )}
            <CustomAccordion
                TransitionProps={{ unmountOnExit: true }}
                expanded={expanded}
                onChange={handleAccordionChange}
                sx={{ margin: "0px", backgroundColor: "#1f2735", boxShadow: "none" }}
            >
                <CustomAccordionSummary
                    sx={{
                        backgroundColor: "#1f2735",
                        boxShadow: "none",
                        border: "1px solid #A6C5E229",
                        borderRadius: "4px",
                        borderBottomLeftRadius: 0,
                        borderBottomRightRadius: 0,
                    }}
                    expandIcon={<ExpandMore />}
                >
                    <Box sx={{ display: "flex", justifyContent: "space-between", flex: 1 }}>
                        <DisplayText text={title} type="bodyMedium" style={{ padding: "10px", flex: 1 }} />

                        {selectedTrial?.workflowActive && (
                            <Box sx={{ display: "flex", alignItems: "center", flex: 1 }}>
                                <WorkflowDropdown
                                    onChange={handleOpenTransitionDialog}
                                    visitState={visitState}
                                    visitPossibleTransitions={visitPossibleTransitions}
                                />

                                {!userHasRole("SPONSOR") && (
                                    <ToolTip content={`View workflow history`}>
                                        <IconButton
                                            onClick={handleOpenWorkflowHistory}
                                            aria-label="history"
                                            size="small"
                                        >
                                            <ListIcon fontSize="inherit" />
                                        </IconButton>
                                    </ToolTip>
                                )}
                            </Box>
                        )}

                        <Box
                            sx={{
                                gap: "20px",
                                display: "flex",
                                justifyContent: "flex-end",
                                alignItems: "center",
                                flex: 1,
                            }}
                        >
                            {canReadComments &&
                                (hasComments ? (
                                    <Badge badgeContent={visit.comments.length} color="primary">
                                        <Chip
                                            icon={<CommentIcon />}
                                            onClick={handleOpenComments}
                                            label="Visit notes"
                                            size="small"
                                        />
                                    </Badge>
                                ) : (
                                    <Chip
                                        icon={<AddCommentIcon />}
                                        disabled={!canAddComment}
                                        onClick={handleOpenComments}
                                        label={canAddComment ? "Add visit note" : "No visit notes"}
                                        size="small"
                                    />
                                ))}
                            {/* {userHasRole("PROJECT_MANAGER") && (
                                <IconButton onClick={handleDeleteVisit} aria-label="delete">
                                    <DeleteIcon />
                                </IconButton>
                            )} */}
                            <Checkbox
                                sx={{ zIndex: 0 }}
                                indeterminate={selectionState === "SOME"}
                                checked={selectionState === "ALL"}
                                onClick={handleSelectAllStudies}
                            />
                        </Box>
                    </Box>
                </CustomAccordionSummary>
                <AccordionDetails
                    sx={{
                        p: 16,
                        backgroundColor: "#1f2735",
                        boxShadow: "none",
                        border: "1px solid #A6C5E229",
                        borderTopLeftRadius: 0,
                        borderTopRightRadius: 0,
                        borderTop: 0,
                    }}
                >
                    <VisitPanel
                        onAttach={handleAttachFile}
                        selectedStudies={selectedStudies}
                        studies={studies}
                        visit={visit}
                        onCheckboxSelect={onSelect}
                    />
                </AccordionDetails>
            </CustomAccordion>
        </AnimatedBorderBox>
    );
};

export default React.memo(VisitAccordion);
