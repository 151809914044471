import { PayloadAction, createAction, createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import jwt_decode, { JwtPayload } from "jwt-decode";
import { AppDispatch, RootState } from "../../../app/store";
import Cookies from "js-cookie";
import { serviceSlice } from "../../../app/serviceSlice";
import { authService } from "../nebula.port";
import { ITrial } from "../../upload/uploadSlice";
import { extendedApiSlice } from "../../upload/dataAccess";

const LOCAL_STORAGE_USER_DATA_KEY = "userCredentials";
const LOCAL_STORAGE_SELECTED_TRIAL = "selectedTrial";

export interface ILoginInfo {
    providerID: string;
    providerKey: string;
}

export interface IUser {
    userID: string;
    loginInfo: ILoginInfo;
    firstName: string;
    lastName: string;
    fullName: string;
    email: string;
    avatarURL: string;
    _activeOrgUuid: string;
    activated: boolean;
    adminFeatures: string;
    accessDate: string;
    accessExpires: string;
    accessOK: boolean;
    orgUuid: string;
    topOrgUuid: string;
    activeOrgUuid: string;
    _projectOrgUuid: string;
    consentUuid: string;
}

export interface LoginState {
    isLoggedIn: boolean;
    email: string;
    status: "idle" | "success" | "loading" | "failed";
    errorMessage?: string;
}

const checkIfUserIsAlreadyLoggedIn = () => {
    const userCredentials = localStorage.getItem(LOCAL_STORAGE_USER_DATA_KEY);

    if (userCredentials) {
        const parsedData = JSON.parse(userCredentials);

        const decodedToken: JwtPayload = jwt_decode(parsedData.token);

        if (!decodedToken) return false;

        const expirationDate = (decodedToken.exp as number) * 1000;
        const currentDate = Date.now();

        return expirationDate > currentDate;
    }

    return false;
};

export const clearState = createAction<void>("store/clearState");

export const removeCookies = async () => {
    await Cookies.remove("PLAY_CSRF_TOKEN");
    await Cookies.remove("CloudFront-Signature", { path: "/", domain: ".inebula.com" });
    await Cookies.remove("CloudFront-Policy", { path: "/", domain: ".inebula.com" });
    await Cookies.remove("CloudFront-Key-Pair-Id", { path: "/", domain: ".inebula.com" });
};

export const logoutUser = () => async (dispatch: AppDispatch) => {
    await removeCookies();

    // remove user info from local storage
    await localStorage.removeItem(LOCAL_STORAGE_USER_DATA_KEY);

    // Clear all cached api requests
    serviceSlice.util.resetApiState();
    // reset state
    dispatch(clearState());
};

const setSelectedTrialInLocalStorage = (trialId: string) => {
    localStorage.setItem(LOCAL_STORAGE_SELECTED_TRIAL, trialId);
};

const getSelectedTrialFromLocalStorage = () => {
    const selectedTrial = localStorage.getItem(LOCAL_STORAGE_SELECTED_TRIAL);

    if (!selectedTrial) return "";

    return selectedTrial;
};

export const loginUser = createAsyncThunk(
    "auth/loginUser",
    async (credentials: { email: string; password: string }, { rejectWithValue }) => {
        // remove all cookie in case it exists
        await removeCookies();

        const response = await authService.login(credentials);

        if (response.error) {
            let errorMessage = "Unable to authenticate";

            if (response.error.kind === "ApiError") {
                errorMessage = response.error.message;
            } else if (response.error.kind === "UnexpectedError") {
                errorMessage = response.error.message.message;
            }

            return rejectWithValue(errorMessage);
        }

        const authToken = response.data;

        // save user data to future use in other requests that need authentication
        localStorage.setItem(
            LOCAL_STORAGE_USER_DATA_KEY,
            JSON.stringify({ userId: credentials.email, token: authToken })
        );

        return response.data;
    }
);

export const getInitialState = () => {
    const userIsLoggedIn = checkIfUserIsAlreadyLoggedIn();
    const selectedTrial = getSelectedTrialFromLocalStorage();

    return {
        isLoggedIn: userIsLoggedIn,
        email: "",
        status: "idle",
        errorMessage: "",
        selectedTrialId: selectedTrial,
    };
};

export const loginSlice = createSlice({
    name: "userInfo",
    initialState: getInitialState,
    reducers: {
        logout: (state) => {
            state.isLoggedIn = false;
            state.email = "";
            state.status = "idle";
            state.errorMessage = "";
        },
        selectTrial: (state, action: PayloadAction<string>) => {
            setSelectedTrialInLocalStorage(action.payload);

            state.selectedTrialId = action.payload;
        },
    },
    extraReducers(builder) {
        builder.addCase(loginUser.pending, (state) => {
            state.status = "loading";
            state.errorMessage = "";
        });
        builder.addCase(loginUser.fulfilled, (state) => {
            state.status = "success";
            state.isLoggedIn = true;
            state.errorMessage = "";
        });
        builder.addCase(loginUser.rejected, (state, action: PayloadAction<any>) => {
            state.status = "failed";
            state.isLoggedIn = false;
            state.errorMessage = state.errorMessage = action.payload || "Something went wrong. Please contact support.";
        });
        builder.addMatcher(extendedApiSlice.endpoints.getTrials.matchFulfilled, (state, { payload }) => {
            // If there is a selected trial stored in local storage, check if it exists in the response
            if (state.selectedTrialId) {
                const selectedTrialBelongsToUser = payload.some(
                    (trial: ITrial) => trial.uuid === state.selectedTrialId
                );

                if (selectedTrialBelongsToUser) return;

                // If the selected trial does not belong to the user, select the first trial in the response
                state.selectedTrialId = payload[0].uuid;
                setSelectedTrialInLocalStorage(state.selectedTrialId);
            }
            // Check if there are any trials in the response
            if (payload.length > 0) {
                // If trials exist, dispatch the "selectTrial" action with the first trial as a parameter
                state.selectedTrialId = payload[0].uuid;
                setSelectedTrialInLocalStorage(state.selectedTrialId);
            }
        });
    },
});

export const selectUserInfo = (state: RootState) => state.userInfo;

export const getSelectedTrialId = createSelector([selectUserInfo], (loginSlice) => loginSlice.selectedTrialId);

export const getAllTrials = (state: any) => extendedApiSlice.endpoints.getTrials.select()(state).data;

export const getSelectedTrial = createSelector([getSelectedTrialId, getAllTrials], (trialId, trials) => {
    if (!trials || !trialId) return null;

    return trials.find((trial: ITrial) => trial.uuid === trialId) || null;
});

export const { logout, selectTrial } = loginSlice.actions;

export const isUserLoggedIn = (state: RootState) => state.userInfo.isLoggedIn;

export default loginSlice.reducer;
