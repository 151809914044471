import { nebulaClient2 } from "../../lib/nebulaClient";
import { Crf, CRFService as CRFServiceInterface } from "./nebula.service";

export const CRFService: CRFServiceInterface = {
    getPatientCrf: async (patientId: string) => {
        const response = await nebulaClient2.get<Crf>(`patient/${patientId}/crf`);

        return response;
    },
    editCRF: async (patientId, CRFEdit) => {
        const response = await nebulaClient2.put<void>(`patient/${patientId}/crf`, CRFEdit);

        return response;
    },
    async removeCrfValues(patientId, visitId, laterality, fieldsToRemove) {
        const payload = { visitId, laterality, fields: fieldsToRemove };
        const response = await nebulaClient2.delete<void>(`patient/${patientId}/crf`, { data: payload });

        return response;
    },
};
