import { serviceSlice } from "../../app/serviceSlice";
import { uploadService } from "./nebula.port";
import { IDerivedFile, IFileExtension, IRawFile, ITrial, IUploadConfig, ITrialVisits } from "./uploadSlice";

export const extendedApiSlice = serviceSlice
    .enhanceEndpoints({
        addTagTypes: ["SESSION_STATUS", "TRIALS", "PATIENTS", "USER_DATA", "UPLOAD_CONFIG", "TRIAL_VISITS"],
    })
    .injectEndpoints({
        endpoints: (builder) => ({
            getTrials: builder.query<ITrial[], void>({
                query: () => uploadService.getTrials,
                providesTags: ["TRIALS"],
            }),
            getUploadConfig: builder.query<IUploadConfig, string>({
                query: (trialId: string) => () => uploadService.getUploadConfig(trialId),
                providesTags: ["UPLOAD_CONFIG"],
            }),
            getTrialVisits: builder.query<ITrialVisits[], string>({
                query: (trialId: string) => () => uploadService.getTrialVisits(trialId),
                providesTags: ["TRIAL_VISITS"],
            }),
            getUploadTrack: builder.query<IDerivedFile[], string>({
                query: (uploadSessionId) => () => uploadService.getUploadTrack(uploadSessionId),
            }),
            getValidFileExtensions: builder.query<IFileExtension[], void>({
                query: () => uploadService.getValidFileExtensions,
            }),
            getSessionStatus: builder.query<any, string[]>({
                query: (sessionIds) => () => uploadService.getSessionStatus(sessionIds),
                providesTags: ["SESSION_STATUS"],
            }),
            commitFiles: builder.mutation<IDerivedFile[], IDerivedFile[]>({
                query: (derivedFiles) => () => uploadService.commitFiles(derivedFiles),
                invalidatesTags: ["SESSION_STATUS"],
            }),
            updateCurrentGroup: builder.mutation<any, any>({
                query: (derivedFiles) => () => uploadService.updateCurrentGroup(derivedFiles),
                invalidatesTags: ["TRIALS", "PATIENTS", "USER_DATA", "UPLOAD_CONFIG", "TRIAL_VISITS"],
            }),
            uploadFile: builder.mutation<
                IRawFile,
                { file: FormData; signal: AbortSignal; onProgress: (progressEvent: any) => void }
            >({
                query:
                    ({ file, signal, onProgress }) =>
                    () =>
                        uploadService.uploadFile(file, signal, onProgress),
            }),
        }),
    });

export const {
    useGetTrialsQuery,
    useGetUploadConfigQuery,
    useGetTrialVisitsQuery,
    useGetSessionStatusQuery,
    useCommitFilesMutation,
    useUpdateCurrentGroupMutation,
    useGetUploadTrackQuery,
    useGetValidFileExtensionsQuery,
    useUploadFileMutation,
} = extendedApiSlice;
