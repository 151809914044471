import React, { useState } from "react";
import { GridRenderEditCellParams, useGridApiContext } from "@mui/x-data-grid";
import { useSnackbar } from "notistack";
import { useEditCRFMutation } from "../dataAccess";
import { TextareaAutosize } from "@mui/material";
import { moveToNextCell } from "../utils/moveToNextCell";

export const TextAreaEditCell: React.FC<GridRenderEditCellParams> = ({ id, value = "", field }) => {
    const apiRef = useGridApiContext();
    const { enqueueSnackbar } = useSnackbar();
    const [editCRF] = useEditCRFMutation();
    const [textValue, setTextValue] = useState(value);

    const handleUpdateValueOnServer = async () => {
        const rowValues = apiRef.current.getRow(id);

        const payload = {
            laterality: rowValues.laterality,
            visitId: rowValues.visitId,
            email: rowValues.userEmail,
            notes: textValue,
        };

        try {
            const isValid = await apiRef.current.setEditCellValue({ id, field, value: textValue });

            if (!isValid) return false;

            await apiRef.current.stopCellEditMode({
                id,
                field,
            });

            // @ts-ignore
            await editCRF({ patientId: rowValues.patientId, crfUpdate: payload, email: rowValues.userEmail }).unwrap();
        } catch (e) {
            enqueueSnackbar("Something went wrong.", { variant: "error" });
            await apiRef.current.startCellEditMode({ id, field });
            await apiRef.current.setEditCellValue({ id, field, value: textValue });
        }
    };

    const handleKeyDown = async (event: any) => {
        if (event.key === "Enter") {
            event.preventDefault();
            event.stopPropagation();

            const isValid = await apiRef.current.setEditCellValue({
                id,
                field,
                value: textValue,
            });

            if (isValid) {
                handleUpdateValueOnServer();

                // move to next cell, timeout is necessary to wait until cell update is finished
                setTimeout(() => moveToNextCell({ id, field }, apiRef, event), 0);
            }
        }
    };

    return (
        <TextareaAutosize
            style={{
                position: "absolute",
                height: "80px",
                overflow: "hidden",
                backgroundColor: "rgb(32, 39, 53)",
                top: "0px",
                resize: "none",
                color: "white",
                fontFamily: "inherit",
                fontSize: "inherit",
                zIndex: 2,
                outline: 0,
            }}
            autoFocus
            onFocus={(e: any) => e.currentTarget.setSelectionRange(textValue.length, textValue.length)}
            onKeyDown={handleKeyDown}
            value={textValue}
            onChange={(e) => setTextValue(e.target.value)}
            onBlur={handleUpdateValueOnServer}
        />
    );
};
