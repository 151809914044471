import { useCallback } from "react";
import { IMedia } from "./studiesSlice";

const useSelectStudiesByType = (
    studyType: string,
    selectedStudies: Set<string>,
    setSelectedStudies: (studiesIds: Set<string>) => void
) => {
    const areAllStudyTypesSelected = useCallback((medias: IMedia[], selectedStudies: Set<string>) => {
        if (!medias) return false;

        const activeMedias = medias.filter((media) => media.hidden === false);
        const studiesIds = activeMedias.filter((media) => media.mimeType === studyType).map((media) => media.uuid);

        if (studiesIds.length === 0) return false;

        return studiesIds.every((studyId) => selectedStudies.has(studyId));
    }, []);

    const selectAllStudiesType = (medias: IMedia[]) => {
        if (medias) {
            const activeMedias = medias.filter((media) => media.hidden === false);
            const studiesIds = activeMedias.filter((media) => media.mimeType === studyType).map((media) => media.uuid);

            const newSelectedStudies = new Set(Array.from(selectedStudies));

            studiesIds.forEach((studyId) => newSelectedStudies.add(studyId));

            setSelectedStudies(newSelectedStudies);
        }
    };

    const deselectAllStudiesType = (medias: IMedia[]) => {
        const selectedStudiesIds = Array.from(selectedStudies);

        const activeMedias = medias.filter((media) => media.hidden === false);

        const selectedStudiesIdsWithoutStudyType = selectedStudiesIds
            .map((studyId) => activeMedias.find((media) => media.uuid === studyId))
            .filter((study) => study!.mimeType !== studyType)
            .map((study) => study!.uuid);

        const newSelectedStudies = new Set(selectedStudiesIdsWithoutStudyType);

        setSelectedStudies(newSelectedStudies);
    };

    return { areAllStudyTypesSelected, selectAllStudiesType, deselectAllStudiesType };
};

export default useSelectStudiesByType;
