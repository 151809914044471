import React, { useEffect, useMemo, useState } from "react";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
// @ts-ignore
import { ReactComponent as VoxeleronLogo } from "../../../assets/VoxeleronLogo.svg";
import { useValidateTokenMutation, useResetPasswordMutation } from "../dataAccess";
import TextField from "@mui/material/TextField";
import Copyright from "../../../components/CopyRight/Copyright";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { CircularProgress, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { useParams, useHistory } from "react-router-dom";
import { removeCookies } from "../login/loginSlice";

const RecoverConfirm = () => {
    const { token } = useParams<{ token: string }>();
    const [password, setPassword] = useState("");
    const [passwordStrengthError, setPasswordStrengthError] = useState(false);
    const [confirmPassword, setConfirmPassword] = useState("");
    const [validateConfirmPassword, setValidateConfirmPassword] = useState("");
    const [invalidToken, setInvalidToken] = useState(false);
    const [tokenLoading, setTokenLoading] = useState(true);
    const [validateToken] = useValidateTokenMutation();
    const [resetPassword] = useResetPasswordMutation();
    const history = useHistory();

    const [loading, setLoading] = useState(false);
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        const runTokenValidation = async () => {
            try {
                await validateToken(token).unwrap();
                setInvalidToken(false);
            } catch (e: any) {
                const errorMessage = e?.message;
                setInvalidToken(true);
                enqueueSnackbar(errorMessage, {
                    variant: "error",
                });
            } finally {
                setTokenLoading(false);
            }
        };

        runTokenValidation();
    }, []);

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        setLoading(true);
        try {
            await removeCookies();

            await resetPassword({ token, newPassword: password }).unwrap();

            enqueueSnackbar("Password updated successfully.", {
                variant: "success",
            });
            history.push("/login");
        } catch (e: any) {
            const errorMessage = e?.message;
            enqueueSnackbar(errorMessage, {
                variant: "error",
            });
        } finally {
            setLoading(false);
        }
    };

    const passwordValidatorRegex = new RegExp("(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})");

    const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;

        if (passwordValidatorRegex.test(newValue)) {
            setPasswordStrengthError(false);
        }

        setPassword(e.target.value);
    };

    const handleValidatePassword = () => {
        if (!passwordValidatorRegex.test(password)) {
            setPasswordStrengthError(true);
        }
    };

    const passwordError = useMemo(() => {
        if (!password || !confirmPassword) return true;

        if (passwordStrengthError) return true;

        return password !== confirmPassword;
    }, [password, confirmPassword, passwordStrengthError]);

    return (
        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
            <Container component="main" maxWidth="xs">
                <Box>
                    <VoxeleronLogo />
                </Box>
                {tokenLoading ? (
                    <Box
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "column",
                            alignItems: "center",
                        }}
                    >
                        <CircularProgress thickness={0.6} />
                        <Box sx={{ mt: 35 }}>
                            <Typography>Validating token...</Typography>
                        </Box>
                    </Box>
                ) : (
                    <Box sx={{ px: 16, display: "flex", flexDirection: "column", alignItems: "center" }}>
                        <Grid container>
                            <Typography variant="subtitle1" color="textSecondary" align="center">
                                Enter your new password.
                            </Typography>
                        </Grid>
                        <form
                            style={{
                                width: "100%",
                            }}
                            noValidate
                            onSubmit={handleSubmit}
                        >
                            <TextField
                                variant="outlined"
                                margin="normal"
                                error={passwordStrengthError}
                                onBlur={handleValidatePassword}
                                helperText={
                                    passwordStrengthError &&
                                    "Password must contain at least 8 characters, a mix of uppercase and lowercase letters, both letters and numbers and one special character."
                                }
                                required
                                fullWidth
                                id="password"
                                label="New Password"
                                name="password"
                                autoComplete="password"
                                type="password"
                                value={password}
                                onChange={handlePasswordChange}
                            />
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                id="confirmPassword"
                                label="Confirm password"
                                name="confirmPassword"
                                autoComplete="confirmPassword"
                                error={!!validateConfirmPassword}
                                helperText={validateConfirmPassword}
                                color={validateConfirmPassword ? "error" : "primary"}
                                value={confirmPassword}
                                type="password"
                                onBlur={(e) => {
                                    if (password === confirmPassword) {
                                        setValidateConfirmPassword("");
                                    } else {
                                        setValidateConfirmPassword("The passwords must match.");
                                    }
                                }}
                                onChange={(e) => {
                                    setConfirmPassword(e.target.value);
                                }}
                            />
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                disabled={loading || passwordError || invalidToken}
                                color="primary"
                                sx={{
                                    margin: "3px 0px 2px 0px",
                                }}
                            >
                                {loading ? "Setting password..." : "Set password"}
                            </Button>
                        </form>
                        <Box mt={8}>
                            <Copyright />
                        </Box>
                    </Box>
                )}
            </Container>
        </Box>
    );
};

export default RecoverConfirm;
