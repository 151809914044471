import { serviceSlice } from "../../app/serviceSlice";
import { alertService } from "./alert.port";

const extendedApiSlice = serviceSlice.enhanceEndpoints({ addTagTypes: ["ALERT_DATA"] }).injectEndpoints({
    endpoints: (builder) => ({
        getActiveAlerts: builder.query<void, void>({
            query: () => alertService.findActiveAlertsForUser,
        }),
    }),
});

export const { useGetActiveAlertsQuery } = extendedApiSlice;
