import usePermissions, { Permission } from "./usePermissions";
import { useGetUserDataQuery } from "../auth/dataAccess";
import { useAppSelector } from "../../app/hooks";
import { isUserLoggedIn } from "../auth/login/loginSlice";

type RolesDefinition = Record<Role, Permission[]>;

// TODO implement rest of the roles
export type Role =
    | "READER"
    | "SITE_USER"
    | "SUPER_READER"
    | "PROJECT_MANAGER"
    | "CLIENT_ADMIN"
    | "SPONSOR"
    | "CRA"
    | "CRC"
    | "MASTER_ADMIN";

const rolesDefinition: RolesDefinition = {
    READER: ["Review", "Launch Orion", "Download", "Change Status", "Edit", "QC", "Report"],
    SUPER_READER: ["Review", "Launch Orion", "Download", "Change Status", "Edit", "QC", "Report", "Review All"],
    SITE_USER: ["Upload", "Review"],
    PROJECT_MANAGER: ["Review", "Launch Orion", "Download", "Change Status", "Delete", "QC", "Report"],
    CLIENT_ADMIN: ["Upload", "Review", "Launch Orion", "Download", "Change Status", "Delete", "QC"],
    SPONSOR: ["Review", "Launch Orion", "Download", "Report"],
    CRA: ["Review"],
    CRC: ["Review"],
    MASTER_ADMIN: ["Master Admin"],
};

const useRoles = () => {
    const { hasPermissions, parsePerMissions } = usePermissions();
    const loggedIn = useAppSelector(isUserLoggedIn);
    const { data: userData } = useGetUserDataQuery(undefined, { skip: !loggedIn });

    const userHasRole = (role: Role): boolean => {
        if (!userData) return false;

        const rolePermissions = rolesDefinition[role];

        const permission = parsePerMissions(userData.adminFeatures);

        // Check if user and role permissions are exactly the same
        return hasPermissions(rolePermissions) && permission.length === rolePermissions.length;
    };

    return { userHasRole };
};

export default useRoles;
